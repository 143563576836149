export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const CLIENT_LOGIN_SUBMIT = 'CLIENT_LOGIN_SUBMIT';
export const CLIENT_REQUEST_PASSWORD_RESET = 'CLIENT_REQUEST_PASSWORD_RESET';
export const CLIENT_UPDATE_PASSWORD = 'CLIENT_UPDATE_PASSWORD';
export const CLIENT_LOGOUT = 'CLIENT_LOGOUT';
export const FETCH_ORDERS_POLLING = 'FETCH_ORDERS_POLLING';
export const FETCH_ORDERS_RECEIVED = 'FETCH_ORDERS_RECEIVED';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS';
export const GET_SUPPORTED_COUNTRIES = 'GET_SUPPORTED_COUNTRIES';
export const SET_SUPPORTED_COUNTRIES = 'SET_SUPPORTED_COUNTRIES';
export const FETCH_ORDER_DETAILS = 'DISPATCHER_FETCH_ORDER_DETAILS';
export const GET_CLIENT_ALL_STORES_IDS = 'GET_CLIENT_ALL_STORES_IDS';

export const SET_HISTORY = 'HISTORY_REDUCER_SET_HISTORY';
export const INJECT_HISTORY = 'HISTORY_REDUCER_INJECT_HISTORY';
export const SET_LOADING = 'UI_REDUCER_SET_LOADING';
export const SET_SESSION = 'SESSION_REDUCER_SET_SESSION';
export const WIPE_SESSION = 'SESSION_REDUCER_WIPE_SESSION';
export const SET_ORDER_FILTER = 'SET_ORDER_FILTER';
export const FETCH_ORDERS_FILTERED = 'FETCH_ORDERS_FILTERED';
export const SET_ORDER_STATE = 'SET_ORDER_STATE';
export const SET_CLIENT_DETAILS = 'CLIENT_REDUCER_SET_PUBLIC_DETAILS';

export const START_PICKUP_RETRY = 'CUSTOMER_REDUCER_RETRY_PICKUP';
export const SET_CUSTOMER_ORDER = 'CUSTOMER_REDUCER_SET_ORDER';
export const FETCH_CUSTOMER_ORDER = 'CUSTOMER_SAGA_FETCH_ORDER';
export const CREATE_CUSTOMER_EVENT = 'CUSTOMER_SAGA_CREATE_EVENT';
// Event called when the customer clicks 'I Received My Order'
export const CUSTOMER_ORDER_RECEIVED = 'CUSTOMER_SAGA_ORDER_RECEIVED';

export const INIT_SAGA = 'SESSION_INIT_SAGA';
export const LOGIN_SAGA = 'SESSION_LOGIN_SAGA';
export const LOGOUT_SAGA = 'SESSION_LOGOUT_SAGA';
export const SUBMIT_CLIENT_ADMIN_LOGIN = 'SUBMIT_CLIENT_ADMIN_LOGIN';
export const REFRESH_CLIENT_ADMIN_TOKEN = 'REFRESH_CLIENT_ADMIN_TOKEN';
export const IMPORT_CLIENT_ADMIN_STORES_FILE =
  'IMPORT_CLIENT_ADMIN_STORES_FILE';
export const IMPORT_CLIENT_ADMIN_CUSTOMERS_FILE =
  'IMPORT_CLIENT_ADMIN_CUSTOMERS_FILE';

export const IMPORT_CUSTOMERS_PHONE_OPTED_OUT_FILE =
  'IMPORT_CUSTOMERS_PHONE_OPTED_OUT_FILE';
export const NOTIFY = 'NOTIFY';
export const UPDATE_CUSTOMER_READY_POP_UPS = 'UPDATE_CUSTOMER_READY_POP_UPS';
export const SET_CUSTOMER_READY_POP_UPS = 'SET_CUSTOMER_READY_POP_UPS';

//client api
export const FETCH_CLIENT = 'FETCH_CLIENT';
export const UPDATE_CLIENT_DETAILS = 'UPDATE_CLIENT_DETAILS';
export const FETCH_CLIENT_RECEIVED = 'FETCH_CLIENT_RECEIVED';
export const REFRESH_TOKEN = 'SESSION_REFRESH_TOKEN';
export const GET_STORE_DETAILS = 'GET_STORE_DETAILS';
export const FETCH_CLIENT_STORES = 'FETCH_CLIENT_STORES';
export const FETCH_STORE_DETAILS_BY_ID = 'FETCH_STORE_DETAILS_BY_ID';
export const CREATE_CLIENT_STORE = 'CREATE_CLIENT_STORE';
export const UPDATE_STORE_STATUS = 'UPDATE_STORE_STATUS';
export const UPDATE_CLIENT_STORE = 'UPDATE_CLIENT_STORE';

export const FETCH_ORIGINAL_URL = 'FETCH_ORIGINAL_URL';
export const SET_STORE_LANGUAGE = 'SET_STORE_LANGUAGE';
