import { CUSTOMER_LOGIN, LOGIN_API } from 'constants/endpoints';
import { request } from 'utils/restClient';
import { CUSTOMER_APP_ROLE } from 'constants/defaults';

export const appLogin = () => {
  return request(CUSTOMER_LOGIN, {
    body: {
      role: CUSTOMER_APP_ROLE,
      password: '<$@Cu5t0merApp@$>',
    },
  });
};

export const loginApi = (data) => {
  return request(LOGIN_API, {
    body: data,
  });
};
