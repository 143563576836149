import { call, select } from '@redux-saga/core/effects';
import { getToken } from 'selectors/session';
import { fetchStoreDetailsById } from '../apis/store';

export function* fetchStoreDetailsSaga(action) {
  const token = yield select(getToken);
  const response = yield call(fetchStoreDetailsById, {
    clientId: action.payload.clientId,
    storeId: action.payload.storeId,
    token,
  });
  return response;
}
