import React from 'react';
import { IonIcon } from '@ionic/react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Anchor from 'components/DesignSystem/Anchor';
import './OrderDetailsPickupLocation.scss';
import { getCurrentStore } from 'selectors/session';

const getMapsUrl = (location: string) =>
  `https://www.google.com/maps/dir/?api=1&destination=${location}`;

const OrderDetailsPickupLocation: React.FC<any> = () => {
  const storeInfo = useSelector(getCurrentStore) || {};
  const intl = useIntl();

  const hasStoreLink = !!storeInfo.storeLink;
  const externalLinksClass = hasStoreLink
    ? 'justify-space-between'
    : 'justify-center';

  return (
    <div className="pickup-location-wrapper center">
      <h2 className="pickup-location-title">
        {intl.formatMessage({
            id: 'order.pickup_location.title',
            defaultMessage: 'Pickup location'
          }).toUpperCase()}
      </h2>
      <IonIcon
        className="pickup-location-icon"
        src="/assets/icon/shop-location.svg"
      />
      <p className="pickup-store-id">
        <FormattedMessage
          id="order.pickup_location.my_store"
          defaultMessage="My Store"
        />
        {` #${storeInfo.id}`}
      </p>
      <p className="pickup-address">{storeInfo.address}</p>
      <p className={`pickup-external-links p-link ${externalLinksClass}`}>
        <Anchor
          className="default-link"
          href={getMapsUrl(storeInfo.address)}
          target="_blank"
        >
          <FormattedMessage
            id="order.pickup_location.map_link"
            defaultMessage="Directions"
          />
          <IonIcon
            src="/assets/icon/directions.svg"
            className="directions-icon"
          ></IonIcon>
        </Anchor>
        {hasStoreLink && (
          <Anchor
            className="default-link"
            href={storeInfo.storeLink}
            target="_blank"
          >
            <FormattedMessage
              id="order.pickup_location.store_link"
              defaultMessage="Store Info"
            />
            <IonIcon
              src="/assets/icon/launch-icon.svg"
              className="external-link-icon"
            ></IonIcon>
          </Anchor>
        )}
      </p>
    </div>
  );
};

export default OrderDetailsPickupLocation;
