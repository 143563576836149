import React from 'react';

import './OrderDetailsDeliveryInstructions.scss';

const OrderDetailsDeliveryInstructions: React.FC<{
  title: string;
  instructions: string;
}> = ({ title, instructions }) => {
  return (
    <div className="order-details-delivery-instructions-wrapper center">
      <h4 className="order-details-delivery-title">{title}</h4>
      <p
        className="default-p order-details-delivery-instructions"
        dangerouslySetInnerHTML={{ __html: instructions }}
      />
    </div>
  );
};

export default OrderDetailsDeliveryInstructions;
