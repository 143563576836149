import { LANGUAGE_SEPARATOR, LANGUAGE_INDICATOR_SEPARATOR } from '../constants/defaults';

function formatInstructions(text) {
  // removes unnecessary characters at the beginning and end
  const regexClean = /^(\r?\n|\r|\s)+|(\r?\n|\r|\s)+$/;
  const regexReplace = /\r?\n|\r/g;
  return text.replace(regexClean, '').replace(regexReplace, '<br />');
}

export function lineBreakToBR(rawString = '') {
  return (rawString || '').replace(/(?:\r\n|\r|\n|\u21b5)/g, '<br />');
}

export function getInstructions(lang, instructions = []) {
  // check previous values as simple string
  if (instructions) {
    if (instructions.indexOf(LANGUAGE_INDICATOR_SEPARATOR) > -1) {
      const arrInstructions = instructions.split(LANGUAGE_SEPARATOR);
      for (let i in arrInstructions) {
        if (arrInstructions[i].indexOf(`${lang}${LANGUAGE_INDICATOR_SEPARATOR}`) > -1) {
          const description = arrInstructions[i].split(LANGUAGE_INDICATOR_SEPARATOR);
          return formatInstructions(description[1]);
        }
      }
      return '';
    } else {
      // return the value itself for not PA clients
      return formatInstructions(instructions);
    }
  }
}
