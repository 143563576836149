import React from 'react';

import './OrderDetailsFormWrapper.scss';

interface ComponentProps {
  children: any;
}

const OrderDetailsFormWrapper: React.FC<ComponentProps> = ({ children }) => {
  return <div className="customer-order-form-wrapper">{children}</div>;
};

export default OrderDetailsFormWrapper;
