import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  spawn,
  take,
} from 'redux-saga/effects';

const noop = () => {};

export function* execute(saga, action) {
  const { meta: { resolve = noop, reject = noop } = {} } = action;
  let task;
  let payload;
  try {
    task = yield spawn(saga, action);
    payload = yield task.toPromise() || {};

    yield call(resolve, payload);
  } catch (payload) {
    yield call(reject, payload);
  } finally {
    if (yield cancelled() && task) {
      yield cancel(task);
    }
  }
}

export const takeEvery = (patternOrChannel, saga) =>
  fork(function* () {
    while (true) {
      const action = yield take(patternOrChannel);
      yield fork(execute, saga, action);
    }
  });

export const takeLatest = (patternOrChannel, saga) =>
  fork(function* () {
    let lastTask;
    while (true) {
      const action = yield take(patternOrChannel);
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(execute, saga, action);
    }
  });

export const takeLeading = (patternOrChannel, saga) =>
  fork(function* () {
    while (true) {
      const action = yield take(patternOrChannel);
      yield execute(saga, action);
    }
  });

export const takeOnce = (patternOrChannel, saga) =>
  fork(function* () {
    const action = yield take(patternOrChannel);
    yield execute(saga, action);
  });

export function* chain(action) {
  let reject, resolve;
  const actionPromise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  yield put({ ...action, meta: { ...action.meta, resolve, reject } });

  return yield call(() => actionPromise);
}
