import type { Action } from 'redux';

import { defaultReducer, unknownReducer } from './utils';

import { SET_LOADING } from 'actions/actionTypes';

const INITIAL_STATE = {
  loading: true
};

const reducerMap: any = {
  [SET_LOADING]: defaultReducer
};

export default (state = INITIAL_STATE, action: Action) => {
  const reducer = reducerMap[action.type] || unknownReducer;

  return reducer(state, action);
};
