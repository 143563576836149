import React from 'react';
import { useSelector } from 'react-redux';
import { BOPIS_DELIVERY , CURBSPOT_DELIVERY} from 'constants/defaults';
import { getInstructions } from 'utils/stringUtils';
import { getSessionLanguage } from 'selectors/session';
import { FormattedMessage, useIntl } from 'react-intl';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsInfo from './OrderDetailsInfo';
import OrderDetailsPickupLocation from './OrderDetailsPickupLocation';
import OrderDetailsReadyForPickup from './OrderDetailsReadyForPickup';
import OrderDetailsContentWrapper from './OrderDetailsContentWrapper';
import OrderDetailsBopisInstructions from './OrderDetailsBopisInstructions';
import OrderDetailsDeliveryInstructions from './OrderDetailsDeliveryInstructions';

interface ComponentProps {
  order: any;
  store: any;
}

const OrderDetailsS1BopisCurbside: React.FC<ComponentProps> = ({
  order,
  store,
}) => {
  const intl = useIntl();

  const bopis = store.deliveryMethods.find(
    (method: any) => method.deliveryMethod.id === BOPIS_DELIVERY
  );
  const bopisInstructions = bopis.instructions || bopis.deliveryMethod.instructions || '';

  const curbspot = store.deliveryMethods.find(
    (method: any) => method.deliveryMethod.id === CURBSPOT_DELIVERY
  );
  const curbspotInstructions = curbspot.instructions || curbspot.deliveryMethod.instructions || '';

  const sessionLanguage = useSelector(getSessionLanguage);
  const language = sessionLanguage || order.orderLanguage;
  const bopisInstructionsDecomposed = getInstructions(language, bopisInstructions);
  const curbspotInstructionsDecomposed = getInstructions(language, curbspotInstructions);

  return (
    <>
      <OrderDetailsHeader order={order} />
      <OrderDetailsContentWrapper>
        <OrderDetailsInfo
          title={
            <FormattedMessage
              id="order.s1.bopiscurbside.title"
              defaultMessage='Your order is ready{linebreak}for pickup'
              values={{
                linebreak: <br />
              }}
            />
          }
          subtitle={
            <FormattedMessage
              id="order.s1.bopiscurbside.subtitle"
              defaultMessage='You can receive your order conveniently{linebreak}via in-store or curbside pickup'
              values={{
                linebreak: <br />
              }}
            />
          }
        />
      </OrderDetailsContentWrapper>
      <OrderDetailsContentWrapper className="with-background">
        <OrderDetailsBopisInstructions store={store} order={order} instructions={bopisInstructionsDecomposed} />
      </OrderDetailsContentWrapper>
      <OrderDetailsContentWrapper>
        {curbspotInstructionsDecomposed ?
          <OrderDetailsDeliveryInstructions
            title={
              intl.formatMessage({
                id: 'order.s1.bopiscurbside.curbside_instructions.title',
                defaultMessage: 'Instructions for curbside pickup'
              })
            }
            instructions={curbspotInstructionsDecomposed}
          />
        : null}
        <br />
        <OrderDetailsReadyForPickup />
      </OrderDetailsContentWrapper>
      <OrderDetailsPickupLocation />
    </>
  );
};

export default OrderDetailsS1BopisCurbside;
