import { request } from 'utils/restClient';
import {
  PUBLIC_CLIENT_DETAILS,
  CLIENT_ADMIN_GET_STORES,
  CLIENT_CREATE_STORE,
  CLIENT_UPDATE_STORE_STATUS,
  CLIENT_UPDATE_STORES,
  GET_SUPPORTED_PHONE_AREAS,
  CLIENT_UPDATE_DETAILS,
  CLIENT_REQUEST_PASSWORD_RESET,
  CLIENT_UPDATE_PASSWORD,
  GET_CLIENT_ALL_STORES_IDS,
} from 'constants/endpoints';

export const fetchClientDetails = ({ clientCode, token }) => {
  return request(PUBLIC_CLIENT_DETAILS, {
    pathParams: { clientCode },
    headers: {
      Authorization: token,
    },
  });
};

export const fetchClientStores = ({ clientId }) => {
  return request(CLIENT_ADMIN_GET_STORES, {
    pathParams: { clientId },
  });
};

export const createClientStore = ({ clientId, data }) => {
  return request(CLIENT_CREATE_STORE, {
    pathParams: { clientId },
    body: data,
  });
};
export const updateClientStores = ({ clientId, data }) => {
  return request(CLIENT_UPDATE_STORES, {
    pathParams: { clientId, storeId: data.id },
    body: data,
  });
};

export const updateStoreStatus = ({ clientId, storeId, status }) => {
  return request(CLIENT_UPDATE_STORE_STATUS, {
    pathParams: { clientId, storeId, status },
  });
};

export const getSupportedCountries = (data) => {
  const { clientId, ...restData } = data;
  return request(GET_SUPPORTED_PHONE_AREAS, {
    pathParams: { clientId },
    queryParams: restData,
  });
};
export const updateClientDetails = ({ clientId, body }) => {
  return request(CLIENT_UPDATE_DETAILS, {
    pathParams: { clientId },
    body,
  });
};

export const clientPasswordResetRequest = ({ body }) => {
  return request(CLIENT_REQUEST_PASSWORD_RESET, { body });
};

export const clientPasswordReset = ({ clientId, body, token }) => {
  return request(CLIENT_UPDATE_PASSWORD, {
    pathParams: { clientId },
    body,
    headers: {
      Authorization: token,
    },
  });
};

export const getClientAllStoresIds = ({ clientId, token }) => {
  return request(GET_CLIENT_ALL_STORES_IDS, {
    pathParams: { clientId },
    headers: { Authorization: token },
  });
};
