import React from 'react';
import Anchor from 'components/DesignSystem/Anchor';

interface Props {
  target: string;
}

const DownloadSamplesLink: React.FC<Props> = ({ target }) => (
  <Anchor className="download-sample-link" href={target} target="_blank">
    Download examples
  </Anchor>
);

export default DownloadSamplesLink;
