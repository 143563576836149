import React, { useState, useContext, useEffect, useMemo } from 'react';
import './UnifiedStoreForm.scss';
import { useForm, Controller } from 'react-hook-form';
import { StoreContext } from 'contexts';
import {
  IonInput,
  IonLabel,
  IonButton,
  IonText,
  IonCol,
  IonRow,
  IonIcon,
  IonCheckbox,
  IonItem,
  IonTextarea,
} from '@ionic/react';
import PhoneAreaCodeDropdown from 'components/CP-PhoneAreaCodeDropdown';
import Dropdown from 'components/Dropdown';
import { eyeOutline, eyeOffOutline, add, remove } from 'ionicons/icons';
import Loader from 'components/DesignSystem/Loader';
import { UPDATE_CLIENT_STORE, CREATE_CLIENT_STORE } from 'actions/actionTypes';
import { useSelector } from 'react-redux';
import {
  CURBSPOT_DELIVERY,
  BOPIS_DELIVERY,
  DEFAULT_APP_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from 'constants/defaults';
import { getClientCode } from 'selectors/client';
import Anchor from 'components/DesignSystem/Anchor';
import { getSupportedCountries } from 'selectors/client';
import { LANGUAGE_INDICATOR_SEPARATOR } from '../../constants/defaults';

interface ContainerProps {
  closeModal: Function;
  storeInfo: any;
  isUpdateForm: boolean;
}
type Inputs = {
  id: string;
  address: string;
  noOfDaysToHoldOrder: number;
  phone: string;
  zipCode: string;
  defaultStoreSalesAssociatePassword: string;
  defaultStoreDispatcherPassword: string;
  phoneForAlerts: string;
};

const UnifiedStoreForm: React.FC<ContainerProps> = ({
  closeModal,
  storeInfo = {},
  isUpdateForm,
}) => {
  const { control, errors, handleSubmit, setValue } = useForm<Inputs>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const [associatePasswordMasking, setAssociatePasswordMasking] = useState<
    boolean
  >(true);
  const [dispatcherPasswordMasking, setDispatcherPasswordMasking] = useState<
    boolean
  >(true);
  const [associatePasswordDisabled, setAssociatePasswordDisabled] = useState<
    boolean
  >(true);
  const [dispatcherPasswordDisabled, setDispatcherPasswordDisabled] = useState<
    boolean
  >(true);
  const [curbsideDelivery, setCurbsideDelivery] = useState<any>({
    id: CURBSPOT_DELIVERY,
    instructions: '',
    active: false,
  });
  const [bopisDelivery, setBopisDelivery] = useState<any>({
    id: BOPIS_DELIVERY,
    instructions: '',
    active: false,
  });
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedDefaultLanguage, setSelectedDefaultLanguage] =
    useState<string>(DEFAULT_APP_LANGUAGE);
  const { action, notify } = useContext(StoreContext);
  const clientId = useSelector(getClientCode);
  const {
    id,
    address,
    noOfDaysToHoldOrder,
    phone,
    zipCode,
    deliveryMethods,
    phoneForAlerts,
  } = storeInfo;
  const [daysToHoldOrder, setDaysToHoldOrder] = useState<number>(
    noOfDaysToHoldOrder || 1
  );

  const supportedCountries = useSelector(getSupportedCountries);

  const createRequestBody = (data: any) => {
    if (isUpdateForm && associatePasswordDisabled) {
      delete data.defaultStoreSalesAssociatePassword;
    }
    if (isUpdateForm && dispatcherPasswordDisabled) {
      delete data.defaultStoreDispatcherPassword;
    }
    return data;
  };

  const getDeliveryMethod = () => {
    const deliveryMethod = [];
    if (curbsideDelivery && curbsideDelivery.active) {
      deliveryMethod.push({
        instructions: curbsideDelivery.instructions,
        deliveryMethod: {
          id: curbsideDelivery.id,
        },
      });
    }
    if (bopisDelivery && bopisDelivery.active) {
      deliveryMethod.push({
        instructions: bopisDelivery.instructions,
        deliveryMethod: {
          id: bopisDelivery.id,
        },
      });
    }
    return deliveryMethod;
  };

  const validateDeliveryMethods = function (deliveryMethods: any = []) {
    const emptyLanguages = `en${LANGUAGE_INDICATOR_SEPARATOR}||fr${LANGUAGE_INDICATOR_SEPARATOR}`;
    if (deliveryMethods.length > 0) {
      // keeping only bopis as required for now
      const bopisHasError = deliveryMethods.some((item: any) =>
        item.deliveryMethod.id === BOPIS_DELIVERY && (!item.instructions || item.instructions === emptyLanguages)
      );

      if (bopisHasError) {
        notify({
          message: 'Please inform instructions for BOPIS delivery method.',
          color: 'warning',
        });
        return false;
      }
      return true;
    } else {
      notify({
        message: 'Select at least one delivery method!',
        color: 'warning',
      });
      return false;
    }
  };

  const storeUpdateForm = (data: any) => {
    const enabledDeliveryeMethods = getDeliveryMethod();

    if (validateDeliveryMethods(enabledDeliveryeMethods)) {
      data.deliveryMethods = enabledDeliveryeMethods;

      const body = createRequestBody(data);

      body.countryCode = selectedCountry;
      body.defaultLanguage = selectedDefaultLanguage;

      setSubmitting(true);

      action(UPDATE_CLIENT_STORE, {
        clientId,
        body,
      }).then((res: any) => {
        if (res && res.status === 200) {
          setSubmitting(false);
          closeModal();
          notify({
            color: 'success',
            message: 'Store details updated',
          });
        }
      });
    }
  };

  const storeCreateForm = (data: any) => {
    const enabledDeliveryeMethods = getDeliveryMethod();

    if (validateDeliveryMethods(enabledDeliveryeMethods)) {
      setSubmitting(true);

      data.deliveryMethods = enabledDeliveryeMethods;
      data.countryCode = selectedCountry;
      data.defaultLanguage = selectedDefaultLanguage;

      action(CREATE_CLIENT_STORE, { body: data, clientId }).then((res: any) => {
        if (res && res.status === 200) {
          closeModal();
          notify({
            color: 'success',
            message: 'Store created successfully !',
          });
        } else {
          closeModal();
          notify({
            color: 'danger',
            message: 'Store id alreday exist !',
          });
        }
      });
    }
  };

  const setDeliveyMethod = (item: any) => {
    if (item.deliveryMethod.id === CURBSPOT_DELIVERY) {
      setCurbsideDelivery({
        id: item.deliveryMethod.id,
        active: item.deliveryMethod.active,
        instructions: item.instructions,
      });
    }
    if (item.deliveryMethod.id === BOPIS_DELIVERY) {
      setBopisDelivery({
        id: item.deliveryMethod.id,
        active: item.deliveryMethod.active,
        instructions: item.instructions,
      });
    }
  };

  useMemo(() => {
    deliveryMethods && deliveryMethods.map( (deliveryMethod: any) => setDeliveyMethod(deliveryMethod) );
  }, [deliveryMethods]);

  useEffect(() => {
    setSelectedCountry(
      storeInfo && storeInfo.countryCode
        ? storeInfo.countryCode
        : supportedCountries[0].countryCode
    );
  }, [storeInfo, supportedCountries, setSelectedCountry]);

  useEffect(() => {
    setSelectedDefaultLanguage(storeInfo.defaultLanguage || DEFAULT_APP_LANGUAGE);
  }, [storeInfo, setSelectedDefaultLanguage]);

  return (
    <div className="unified-form-wrapper">
      <IonText color="dark">
        <h1 className="unified-modal-header">
          {isUpdateForm ? 'Update Store' : 'Add Store'}
        </h1>
        <IonLabel className="unified-modal-label">Client Id</IonLabel>{' '}
        <p className="client-id">{clientId}</p>
      </IonText>
      <form
        onSubmit={handleSubmit((data) =>
          isUpdateForm ? storeUpdateForm(data) : storeCreateForm(data)
        )}
      >
        <IonRow>
          <IonCol sizeLg="12" className="item-wrapper">
            <legend className="store-sections">Store Address</legend>
          </IonCol>
          <IonCol sizeLg="12" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">Store Address</IonLabel>
            <Controller
              className="unified-modal-input"
              name="address"
              as={IonInput}
              type="text"
              control={control}
              rules={{ required: true }}
              defaultValue={isUpdateForm ? address : ''}
              onIonChange={(e: any) => setValue('address', e.detail.value)}
            />
            <span className="error-feedback">
              {errors.address &&
                errors.address.type === 'required' &&
                'Address is required'}
            </span>
          </IonCol>
          <IonCol sizeLg="12" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">Store Country</IonLabel>
            <PhoneAreaCodeDropdown
              showCountryOnly={true}
              supportedCountries={supportedCountries}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          </IonCol>
          <IonCol sizeLg="7" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">Phone Number</IonLabel>
            <Controller
              className="unified-modal-input"
              name="phone"
              as={IonInput}
              type="tel"
              rules={{ required: true, pattern: /^\d{10}$/ }}
              control={control}
              defaultValue={isUpdateForm ? phone : ''}
              onIonChange={(e: any) => setValue('phone', e.detail.value)}
            />
            <span className="error-feedback">
              {errors.phone &&
                errors.phone.type === 'pattern' &&
                'Phone number is not valid'}
              {errors.phone &&
                errors.phone.type === 'required' &&
                'Phone number is required'}
            </span>
          </IonCol>
          <IonCol sizeLg="5" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">Zip Code</IonLabel>
            <Controller
              className="unified-modal-input"
              name="zipCode"
              as={IonInput}
              type="text"
              rules={{ required: true }}
              control={control}
              defaultValue={isUpdateForm ? zipCode : ''}
              onIonChange={(e: any) => setValue('zipCode', e.detail.value)}
            />
            <span className="error-feedback">
              {errors.zipCode &&
                errors.zipCode.type === 'required' &&
                'Zipcode is required'}
            </span>
          </IonCol>
          <IonCol sizeLg="12" className="item-wrapper">
            <legend className="store-sections">Store Settings</legend>
          </IonCol>
          <IonCol sizeLg="7" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">Store ID</IonLabel>
            <Controller
              className="unified-modal-input"
              name="id"
              as={IonInput}
              type="text"
              rules={{ required: true }}
              control={control}
              defaultValue={isUpdateForm ? id : ''}
              disabled={isUpdateForm}
              onIonChange={(e: any) => setValue('id', e.detail.value)}
            />
            <span className="error-feedback">
              {errors.id && 'Store id is required'}
            </span>
          </IonCol>
          <IonCol sizeLg="5" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">
              Days for Holding Order
            </IonLabel>
            <Controller
              className="days-to-hold-order"
              name="noOfDaysToHoldOrder"
              as={IonInput}
              type="text"
              min="1"
              rules={{
                pattern: /^[0-9]*$/,
              }}
              control={control}
              defaultValue={daysToHoldOrder}
              onIonChange={(e: any) => {
                setDaysToHoldOrder(e.detail.value);
                setValue('noOfDaysToHoldOrder', e.detail.value);
              }}
            >
              <IonIcon
                icon={remove}
                onClick={(e: any) => {
                  daysToHoldOrder > 1 &&
                    setValue('noOfDaysToHoldOrder', daysToHoldOrder - 1);
                }}
              />
              <IonIcon
                icon={add}
                onClick={(e: any) => {
                  setValue('noOfDaysToHoldOrder', +daysToHoldOrder + 1);
                }}
              />
            </Controller>
          </IonCol>
          <IonCol sizeLg="12" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">Store Default Language</IonLabel>
            <Dropdown
              options={Object.values(SUPPORTED_LANGUAGES)}
              setSelectedValue={setSelectedDefaultLanguage}
              selectedValue={selectedDefaultLanguage}
            />
          </IonCol>
          <IonCol sizeLg="7" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">
              Phone Number for Alerts
            </IonLabel>
            <Controller
              className="unified-modal-input"
              name="phoneForAlerts"
              as={IonInput}
              type="tel"
              rules={{ required: true, pattern: /^\d{10}$/ }}
              control={control}
              defaultValue={isUpdateForm ? phoneForAlerts : ''}
              onIonChange={(e: any) =>
                setValue('phoneForAlerts', e.detail.value)
              }
            />
            <span className="error-feedback">
              {errors.phoneForAlerts &&
                errors.phoneForAlerts.type === 'pattern' &&
                'Phone number is not valid'}
              {errors.phoneForAlerts &&
                errors.phoneForAlerts.type === 'required' &&
                'Phone number is required'}
            </span>
          </IonCol>

          <IonCol sizeLg="5" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">Enablements</IonLabel>
            <div className="delivery-method-wrapper">
              <IonItem className="delivery-mode-wrapper" lines="none">
                <IonCheckbox
                  checked={curbsideDelivery.active}
                  className="delivery-mode-checkbox"
                  onIonChange={(e: any) => {
                    setCurbsideDelivery({
                      ...curbsideDelivery,
                      active: e.detail.checked,
                    });
                  }}
                />
                <IonLabel className="delivery-mode-text">Curbspot</IonLabel>
              </IonItem>
              <IonItem className="delivery-mode-wrapper" lines="none">
                <IonCheckbox
                  checked={bopisDelivery.active}
                  className="delivery-mode-checkbox"
                  onIonChange={(e: any) => {
                    setBopisDelivery({
                      ...bopisDelivery,
                      active: e.detail.checked,
                    });
                  }}
                />
                <IonLabel className="delivery-mode-text">Bopis</IonLabel>
              </IonItem>
            </div>
          </IonCol>

          {bopisDelivery.active && (
            <IonCol sizeLg="12" sizeXs="12" className="item-wrapper">
              <IonLabel className="unified-modal-label">
                Bopis Instructions
              </IonLabel>
              <IonTextarea
                className="unified-modal-input multiline"
                value={bopisDelivery.instructions}
                onIonChange={(e: any) => {
                  setBopisDelivery({
                    ...bopisDelivery,
                    instructions: e.detail.value,
                  });
                }}
              ></IonTextarea>
            </IonCol>
          )}

          {curbsideDelivery.active && (
            <IonCol sizeLg="12" sizeXs="12" className="item-wrapper">
              <IonLabel className="unified-modal-label">
                Curbside Instructions
              </IonLabel>
              <IonTextarea
                className="unified-modal-input multiline"
                value={curbsideDelivery.instructions}
                onIonChange={(e: any) => {
                  setCurbsideDelivery({
                    ...curbsideDelivery,
                    instructions: e.detail.value,
                  });
                }}
              ></IonTextarea>
            </IonCol>
          )}

          <IonCol sizeLg="6" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">
              Associate Password
              {isUpdateForm && (
                <Anchor
                  className="change-password-link"
                  href="#"
                  onClick={(event: Event) => {
                    event.preventDefault();
                    setAssociatePasswordDisabled(false);
                  }}
                >
                  Change
                </Anchor>
              )}
            </IonLabel>
            <Controller
              className={`passwords-fields ${
                isUpdateForm && associatePasswordDisabled ? 'disabled' : ''
              }`}
              name="defaultStoreSalesAssociatePassword"
              as={IonInput}
              type={associatePasswordMasking ? 'password' : 'text'}
              rules={{ required: !isUpdateForm }}
              control={control}
              defaultValue=""
              disabled={isUpdateForm && associatePasswordDisabled}
              onIonChange={(e: any) =>
                setValue('defaultStoreSalesAssociatePassword', e.detail.value)
              }
            >
              {associatePasswordMasking ? (
                <IonIcon
                  icon={eyeOffOutline}
                  className="pasword-masking"
                  onClick={() => setAssociatePasswordMasking(false)}
                />
              ) : (
                <IonIcon
                  icon={eyeOutline}
                  className="pasword-masking"
                  onClick={() => setAssociatePasswordMasking(true)}
                />
              )}
            </Controller>

            <span className="error-feedback">
              {errors.defaultStoreSalesAssociatePassword &&
                errors.defaultStoreSalesAssociatePassword.type === 'required' &&
                'Associate Password is required'}
            </span>
          </IonCol>
          <IonCol sizeLg="6" sizeXs="12" className="item-wrapper">
            <IonLabel className="unified-modal-label">
              Dispatcher Password
              {isUpdateForm && (
                <Anchor
                  className="change-password-link"
                  href="#"
                  onClick={(event: Event) => {
                    event.preventDefault();
                    setDispatcherPasswordDisabled(false);
                  }}
                >
                  Change
                </Anchor>
              )}
            </IonLabel>
            <Controller
              className={`passwords-fields ${
                isUpdateForm && dispatcherPasswordDisabled ? 'disabled' : ''
              }`}
              name="defaultStoreDispatcherPassword"
              as={IonInput}
              type={dispatcherPasswordMasking ? 'password' : 'text'}
              rules={{ required: !isUpdateForm }}
              control={control}
              defaultValue=""
              disabled={isUpdateForm && dispatcherPasswordDisabled}
              onIonChange={(e: any) =>
                setValue('defaultStoreDispatcherPassword', e.detail.value)
              }
            >
              {dispatcherPasswordMasking ? (
                <IonIcon
                  icon={eyeOffOutline}
                  className="pasword-masking"
                  onClick={() => setDispatcherPasswordMasking(false)}
                />
              ) : (
                <IonIcon
                  icon={eyeOutline}
                  className="pasword-masking"
                  onClick={() => setDispatcherPasswordMasking(true)}
                />
              )}
            </Controller>
            <span className="error-feedback">
              {errors.defaultStoreDispatcherPassword &&
                errors.defaultStoreDispatcherPassword.type === 'required' &&
                'Dispatcher Password is required'}
            </span>
          </IonCol>

          <IonCol sizeLg="12" className="d-flex cta-wrapper">
            <IonButton
              className="button-secondary"
              color="light"
              size="large"
              mode="md"
              shape={undefined}
              disabled={isSubmitting}
              onClick={() => {
                closeModal();
              }}
            >
              CANCEL
            </IonButton>
            <IonButton
              className="button-primary"
              color="dark"
              size="large"
              mode="md"
              shape={undefined}
              disabled={isSubmitting}
              type="submit"
              buttonType="button"
            >
              {isSubmitting ? (
                <Loader withMinHeight={false} />
              ) : isUpdateForm ? (
                'UPDATE STORE'
              ) : (
                'ADD STORE'
              )}
            </IonButton>
          </IonCol>
        </IonRow>
      </form>
    </div>
  );
};
export default UnifiedStoreForm;
