import React, { useState, useContext, useCallback, useEffect } from 'react';
import { ViewPortContext } from '../../contexts';
import './OrderList.scss';
import {
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon,
} from '@ionic/react';
import OrderCard from './OrderCard';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getFilteredOrders } from 'selectors/orders';
import { getViewPort } from '../../utils/getViewPort';
import {
  ORDER_STATE_PENDING_PICKUP,
  ORDER_STATE_CUSTOMER_READY,
  ORDER_STATE_UNDER_PICKUP,
} from 'constants/orderStates';

interface ContainerProps {
  title: string;
  status: string;
  searchText: string;
  expandColumn: boolean;
  setExpandColumn: any;
}

const OrderColumn: React.FC<ContainerProps> = ({
  title,
  status,
  searchText,
  expandColumn,
  setExpandColumn,
}) => {
  const { width } = useContext(ViewPortContext);
  const viewPort = getViewPort(width);
  let orders = useSelector((state: RootState) =>
    getFilteredOrders(state)(status, searchText)
  );

  // The first column must be reversed so that
  // orders recently created are presented at the top
  if (
    status === ORDER_STATE_PENDING_PICKUP ||
    status === ORDER_STATE_UNDER_PICKUP
  ) {
    orders = orders.sort((prevOrder, currentOrder) => {
      // @ts-ignore
      return prevOrder.lastUpdateDate > currentOrder.lastUpdateDate ? -1 : 1;
    });
  }

  const [isOpen, setIsOpen] = useState(false);
  const highlight = useCallback(() => {
    //if there is search text and there are matching results
    //and box is close, then hightlight

    if (searchText && orders.length && !isOpen && viewPort.isMobile) {
      return true;
    }
    return false;
  }, [searchText, orders, isOpen, viewPort]);

  useEffect(() => {
    if (status === ORDER_STATE_CUSTOMER_READY) {
      setIsOpen(expandColumn);
    }
  }, [expandColumn]);

  return (
    <div className={`order-list-card ${highlight() && 'hightlight'}`}>
      <IonCardHeader
        className={`card-header`}
        onClick={() => {
          setIsOpen(!isOpen);
          setExpandColumn(false);
        }}
      >
        <IonCardTitle>{title}</IonCardTitle>
        {viewPort.isMobile && (
          <IonIcon
            src={
              isOpen
                ? '/assets/icon/up-arrow.svg'
                : '/assets/icon/down-arrow.svg'
            }
            className="card-accordian"
          ></IonIcon>
        )}
      </IonCardHeader>
      <IonCardContent
        className={
          viewPort.isMobile && !isOpen
            ? 'ion-hide column-card-content'
            : 'column-card-content'
        }
      >
        {orders &&
          orders.map((item: any) => <OrderCard order={item} key={item.id} />)}
      </IonCardContent>
    </div>
  );
};
export default OrderColumn;
