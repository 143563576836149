import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, FormattedDate } from 'react-intl';
import OrderDetailsContentWrapper from './OrderDetailsContentWrapper';
import OrderDetailsInfo from './OrderDetailsInfo';
import { checkmarkCircle } from 'ionicons/icons';
import OrderDetailsRetryPickupCta from './OrderDetailsRetryPickupCta';
import Anchor from 'components/DesignSystem/Anchor';
import { ONE_DAY_TO_MILLIS } from 'constants/dates';
import { getCurrentStorePhone } from 'selectors/session';

interface ComponentProps {
  order: any;
}

interface AdditionalDescriptionProps {
  phoneNumber: any;
}

const AdditionalDescription: React.FC<AdditionalDescriptionProps> = ({
  phoneNumber,
}) => {
  return (
    <FormattedMessage
      id="order.s7.additional_desc"
      defaultMessage="To avoid cancelation, contact our store at {phoneAnchor} or click on the button below whenever you are back to the pickup location."
      values={{
        phoneAnchor: (
          <Anchor href={`tel:${phoneNumber}`} className="default-link d-inline-block">
            {phoneNumber}
          </Anchor>
        )
      }}
    />
  );
}

const OrderDetailsS7: React.FC<ComponentProps> = ({ order }) => {
  const { createDate } = order;
  // TODO: use store information to calculate the date.
  // Using hardcoded because store data is not available
  const storeNoOfDays = 5;
  const lastDate = new Date(createDate + ONE_DAY_TO_MILLIS * storeNoOfDays);
  const storePhone = useSelector(getCurrentStorePhone);
  const storeLastPickupDay =
    <FormattedDate
      value={lastDate}
      month="long"
      day="2-digit"
      weekday="long"
  />;

  return (
    <>
      <OrderDetailsContentWrapper className="customer-order-pickup-later-success">
        <OrderDetailsInfo
          icon={checkmarkCircle}
          iconColor="success"
          title={
            <FormattedMessage
              id="order.s7.title"
              defaultMessage="You can pickup your order at another time."
            />
          }
          subtitle={
            <FormattedMessage
              id="order.s7.subtitle"
              defaultMessage="We'll hold it for you until {storeLastPickupDay}. After that your order will be automatically canceled."
              values={{ storeLastPickupDay }}
            />
          }
          additionalDesc={
            <AdditionalDescription
              phoneNumber={storePhone}
            />
          }
        />
      </OrderDetailsContentWrapper>
      <OrderDetailsContentWrapper>
        <OrderDetailsRetryPickupCta order={order} />
      </OrderDetailsContentWrapper>
    </>
  );
};

export default OrderDetailsS7;
