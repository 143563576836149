import React from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import './Dropdown.scss';

export type OptionType = {
  value: string;
  placeholder: string;
}

interface ContainerProps {
  selectedValue: string;
  setSelectedValue: Function;
  options: Array<OptionType>;
}

const Dropdown: React.FC<ContainerProps> = ({
  selectedValue,
  setSelectedValue,
  options,
}) => {
  return (
    <IonSelect
      className="dropdown"
      value={selectedValue}
      onIonChange={(e: any) => {
        setSelectedValue(e.detail.value);
      }}
    >
      {options.map(({value, placeholder}: OptionType) => (
        <IonSelectOption value={value} key={value}>
          {placeholder}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};

export default Dropdown;
