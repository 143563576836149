import React from 'react';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import CPHeader from 'components/CPHeader';
import CPFooter from 'components/CPFooter';
import CP404 from 'components/CP404/component';

const Customer404Page = () => {
  return (
    <IonPage className="customer-404-page">
      <CPHeader suppressStore={true} suppressLogout={true} />
      <IonContent>
        <IonGrid className="reset-padding">
          <IonRow className="oe-container">
            <IonCol className="reset-padding" size="12">
              <CP404 />
              <CPFooter />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Customer404Page;
