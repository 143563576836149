import React from 'react';
import { FormattedMessage } from 'react-intl';
import { checkmarkCircle } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsInfo from './OrderDetailsInfo';
import OrderDetailsContentWrapper from './OrderDetailsContentWrapper';
import Anchor from 'components/DesignSystem/Anchor';
import { getCurrentStore } from 'selectors/session';

interface ComponentProps {
  order: any;
}

interface SubtitleProps {
  phoneNumber: any;
}

const ComponentSubtitle: React.FC<SubtitleProps> = ({ phoneNumber }) => {
  return (
    <>
      <FormattedMessage
        id="order.s3.subtitle"
        defaultMessage="If you have any trouble, please contact our store at"
      />
      {' '}
      <Anchor href={`tel:${phoneNumber}`} className="default-link d-inline-block">
        {phoneNumber}
      </Anchor>
      .
    </>
  );
}

const OrderDetailsS3: React.FC<ComponentProps> = ({ order }) => {
  const storeInfo = useSelector(getCurrentStore) || {};
  return (
    <>
      <OrderDetailsHeader order={order} />
      <OrderDetailsContentWrapper>
        <OrderDetailsInfo
          icon={checkmarkCircle}
          iconColor="success"
          title={
            <FormattedMessage
              id="order.s3.title"
              defaultMessage="We’re on our way!"
            />
          }
          subtitle={<ComponentSubtitle phoneNumber={storeInfo.phone} />}
        />
      </OrderDetailsContentWrapper>
    </>
  );
};

export default OrderDetailsS3;
