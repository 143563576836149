import { DEFAULT_APP_LANGUAGE } from '../constants/defaults';

export const getSession = (state: any) => state.session || {};
export const getRole = (state: any) => getSession(state).role;
export const getToken = (state: any) => getSession(state).token;
export const getAppToken = (state: any) => getSession(state).appToken;
export const getStoreId = (state: any) => getSession(state).storeId;
export const getClientId = (state: any) => getSession(state).clientId;
export const getCurrentStore = (state: any) => getSession(state).currentStore;
export const getStoreDefaultLanguage = (state: any) => (
    getCurrentStore(state)
      ? getCurrentStore(state).defaultLanguage
    : DEFAULT_APP_LANGUAGE
  );
export const getShortLiveToken = (state: any) =>
  getSession(state).shortLiveToken;
export const getCurrentStorePhone = (state: any) =>
  getCurrentStore(state).phone;
export const getSessionLanguage = (state: any) => (getSession(state).currentLanguage);
