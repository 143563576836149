export const logger = (store: { getState: () => any }) => (
  next: (arg0: any) => any
) => (action: { type: string; payload?: any }) => {
  console.group(action.type);
  console.info('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
};

export const promisfiedAction = (store: any) => (next: (arg0: any) => void) => (
  action: any
) => {
  return new Promise((resolve, reject) => {
    next({ ...action, meta: { resolve, reject } });
  });
};
