import React, { ReactFragment } from 'react';

import { IonIcon } from '@ionic/react';

import './OrderDetailsInfo.scss';

interface NotificationMessage {
  title: ReactFragment | string;
  subtitle: ReactFragment | string;
  additionalDesc?: ReactFragment | string;
  icon?: string;
  iconColor?: string;
}

const OrderDetailsInfo: React.FC<NotificationMessage> = ({
  title,
  subtitle,
  additionalDesc,
  icon,
  iconColor,
}: NotificationMessage) => {
  return (
    <div className="order-message-wrapper center">
      {icon && (
        <IonIcon color={iconColor} className="order-message-icon" icon={icon} />
      )}
      <h1 className="order-message-title">{title}</h1>
      <p className="default-p order-message-subtitle">{subtitle}</p>
      {additionalDesc && (
        <p className="default-p order-message-description">{additionalDesc}</p>
      )}
    </div>
  );
};

export default OrderDetailsInfo;
