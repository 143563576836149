import { createContext } from 'react';

interface NotificationI {
  message: string;
  duration?: number;
  color?: string;
}
export interface StoreContextI {
  action(type: any, payload: any, meta: any): any;
  notify(config: NotificationI): void;
}

export const StoreContext = createContext<StoreContextI | any>({});
export interface ViewPortContextI {
  size: any;
}
export const ViewPortContext = createContext<ViewPortContextI | any>({});
