import React, { useContext, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { StoreContext } from 'contexts';
import { getLoading } from 'selectors/ui';
import { getToken, getClientId } from 'selectors/session';
import {
  NOTIFY,
  SET_LOADING,
  IMPORT_CLIENT_ADMIN_STORES_FILE,
  IMPORT_CLIENT_ADMIN_CUSTOMERS_FILE,
  IMPORT_CUSTOMERS_PHONE_OPTED_OUT_FILE,
  LOGOUT_SAGA,
  REFRESH_TOKEN,
} from 'actions/actionTypes';
import Loader from 'components/DesignSystem/Loader';
import InputFile from 'components/DesignSystem/InputFile';
import UploadFileSection from './subcomponent/UploadFileSection';
import './CPClientAdminBoard.scss';
import {
  IonButton,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';

import { CLIENT_ADM_ROLE } from 'constants/defaults';
import { PHONE_OPTED_OUT_FILE, CUSTOMER_FILE, STORE_FILE } from './constants';

interface ContainerProps {}

/**
 * Validates the file (current only size is validated
 * because the extension is defined in the field)
 *
 * @param file Selected File
 */
const isFileValid = (file: File) => {
  return {
    name: file.name,
    error:
      file.size > 102400
        ? 'Maximum file size is 100KB'
        : file.name.endsWith('.csv')
        ? null
        : 'Please select .csv file',
  };
};

interface FileControl {
  file?: File;
  name: string;
  error: string;
  loading: boolean;
  selected: boolean;
}
interface FileState {
  [key: string]: FileControl;
}

const BASE_STATE = {
  name: '',
  error: '',
  loading: false,
  selected: false,
};

const INITIAL_STATE: FileState = {
  [PHONE_OPTED_OUT_FILE]: BASE_STATE,
  [CUSTOMER_FILE]: BASE_STATE,
  [STORE_FILE]: BASE_STATE,
};

interface SagaMap {
  [key: string]: string;
}

const SAGA_MAP: SagaMap = {
  [PHONE_OPTED_OUT_FILE]: IMPORT_CUSTOMERS_PHONE_OPTED_OUT_FILE,
  [CUSTOMER_FILE]: IMPORT_CLIENT_ADMIN_CUSTOMERS_FILE,
  [STORE_FILE]: IMPORT_CLIENT_ADMIN_STORES_FILE,
};

const MAX_NUMBER_OF_STORES_EXCEEDED =
  'Csv store count is greater than allowed for client';

const CPClientAdminBoard: React.FC<ContainerProps> = () => {
  const { action } = useContext(StoreContext);
  const history = useHistory();
  const clientId = useSelector(getClientId);
  const authToken = useSelector(getToken);
  const isLoading = useSelector(getLoading);
  const [fileState, setFileState] = useState(INITIAL_STATE);

  /**
   * Manages the change that happens when a customer picks
   * a file
   * @param target ID of the target element
   * @param callback Callback function that will handle file selection
   */
  const onFileChange = (target: string, callback: Function) => {
    const element = document.getElementById(target) as HTMLInputElement;
    const files = (element && element.files) || [];

    if (files.length) {
      callback(target, files[0]);
    }
  };

  /**
   * Validates and submits the file
   */
  const onFileSelected = useCallback(
    (target: string, file: File) => {
      const validationResult = isFileValid(file);
      const updatedState: any = { ...validationResult };

      if (!validationResult.error) {
        updatedState.file = file;
        updatedState.loading = false;
        updatedState.selected = true;
      } else {
        // Present the error message
        action(NOTIFY, {
          message: validationResult.error,
          duration: 4000,
          color: 'danger',
        });
      }

      setFileState({ ...fileState, [target]: { ...updatedState } });
    },
    [fileState, setFileState]
  );

  /**
   * Callback to handle customer file remove
   */
  const onFileRemove = useCallback(
    (target: string) => {
      // First, remove the file from the input
      const element = document.getElementById(target) as HTMLInputElement;

      if (element) {
        element.value = '';
      }

      setFileState({ ...fileState, [target]: INITIAL_STATE[target] });
    },
    [fileState, setFileState]
  );

  /**
   * Manages file upload to the API
   *
   * @param String The file target
   */
  const onFileUpload = useCallback(
    (target: string) => {
      const updatedState: any = { loading: true };
      const { file } = fileState[target];

      // Set loading to true
      setFileState({ ...fileState, [target]: { ...updatedState } });

      action(SAGA_MAP[target], { file }).then((response: any) => {
        // TODO: handle error

        onFileRemove(target);

        if (!response) {
          action(NOTIFY, {
            message: 'Unable to process the file',
            duration: 4000,
            color: 'danger',
          });
          return;
        }

        if (response.status === 200) {
          action(NOTIFY, {
            message: 'File uploaded with success',
            duration: 4000,
            color: 'success',
          });
        } else if (
          response.status === 400 &&
          response.data.message === MAX_NUMBER_OF_STORES_EXCEEDED
        ) {
          action(NOTIFY, {
            message:
              'You have reached the maximum number of store licenses.  Please purchase more licenses in your subscription management portal.',
            duration: 4000,
            color: 'danger',
          });
        } else {
          action(NOTIFY, {
            message: 'Unable to process the file',
            duration: 4000,
            color: 'danger',
          });
        }
      });
    },
    [fileState, setFileState]
  );

  const returnToAdmin = useCallback(() => {
    action(SET_LOADING, { loading: false });
    action(LOGOUT_SAGA, { role: CLIENT_ADM_ROLE });
  }, [history]);

  const renderCard = ({ target = '', inputProps = {} }) => (
    <>
      <InputFile
        state={fileState[target]}
        target={target}
        onChange={onFileChange}
        onFileRemove={onFileRemove}
        onFileSelected={onFileSelected}
        {...inputProps}
      />
      <IonButton
        className="upload-submit button-secondary w-100"
        color="light"
        size="large"
        mode="md"
        shape={undefined}
        disabled={fileState[target].loading || !fileState[target].selected}
        onClick={() => onFileUpload(target)}
      >
        UPLOAD FILE
      </IonButton>
    </>
  );

  useIonViewWillEnter(() => {
    // Make sure we reset to initial state
    // when the page is loaded for the first time
    setFileState({ ...INITIAL_STATE });

    // Add loading flag to ensure we present loader during
    // refresh call
    action(SET_LOADING, { loading: true });

    if (authToken) {
      action(REFRESH_TOKEN, {
        clientId,
        role: CLIENT_ADM_ROLE,
      }).then((res: any) => {
        if (!res.data.token) {
          returnToAdmin();
        }
        action(SET_LOADING, { loading: false });
      });
    }
  }, [authToken]);

  useIonViewDidEnter(() => {
    if (!authToken) {
      // Remove loading
      action(SET_LOADING, { loading: false });

      // Notify the customer about his expiration
      action(NOTIFY, {
        message: 'Your session is expired. Please, sign in again.',
        duration: 4000,
        color: 'danger',
      });

      returnToAdmin();
    }
  }, [authToken]);

  if (isLoading) {
    return <Loader />;
  }

  return <UploadFileSection renderCard={renderCard}></UploadFileSection>;
};
export default CPClientAdminBoard;
