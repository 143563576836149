import { loginApi } from 'actions/apis/login';
import { call, put, select } from 'redux-saga/effects';
import { SET_SESSION } from 'actions/actionTypes';
import { CURBY_LS_AUTH_TOKEN, CLIENT_ADM_ROLE } from 'constants/defaults';
import { getClientCode } from 'selectors/client';

export function* submitLogin(action) {
  const clientCode = yield select(getClientCode);

  const response = yield call(loginApi, {
    clientCode,
    ...action.payload,
  });

  if (response && response.status === 200 && response.data.token) {
    const { role, storeId, clientId = clientCode } = action.payload;
    const state = {
      role,
      storeId,
      clientId,
      ...response.data,
    };

    localStorage.setItem(CURBY_LS_AUTH_TOKEN, JSON.stringify(state));

    // Update state
    yield put({
      type: SET_SESSION,
      payload: {
        ...state,
      },
    });
  }

  return response;
}
