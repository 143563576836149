import React from 'react';

import Loader from 'components/DesignSystem/Loader';
import {
  cloudUploadOutline,
  attachOutline,
  closeOutline,
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import './InputFile.scss';

interface InputFileProps {
  target: string;
  onChange: Function;
  onFileRemove: Function;
  onFileSelected: Function;
  state: any;
  disabled?: boolean;
}

const Untouched = () => (
  <div className="upload-content">
    <span className="upload-content-text">SELECT FILE</span>
    <IonIcon className="upload-icon" icon={cloudUploadOutline} />
  </div>
);
const Loading = () => <Loader color="light" withMinHeight={false} />;

const Selected: React.FC<any> = (props) => (
  <div className="upload-content">
    <IonIcon className="upload-icon upload-icon-attach" icon={attachOutline} />
    <span className="upload-content-text">{props.name}</span>
    <span className="upload-remove-link">
      <IonIcon className="upload-icon upload-remove-icon" icon={closeOutline} />
    </span>
  </div>
);

const InputFile = ({
  target,
  onChange,
  onFileRemove,
  onFileSelected,
  state,
  disabled = false,
}: InputFileProps) => {
  const { name, loading, selected } = state;
  const classNames = ['upload-label'];

  if (loading) {
    classNames.push('is-loading');
  }

  if (selected) {
    classNames.push('is-selected');
  }

  // When it's selected, we no longer have a input/label
  if (selected) {
    return (
      <div className="file-upload-wrapper">
        <button
          type="button"
          className={classNames.join(' ')}
          onClick={() => onFileRemove(target)}
        >
          <Selected name={name} />
        </button>
      </div>
    );
  }

  return (
    <div className="file-upload-wrapper">
      <input
        className={`upload-field ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
        id={target}
        name={target}
        type="file"
        accept=".csv"
        onChange={() => onChange(target, onFileSelected)}
      />
      <label className={classNames.join(' ')} htmlFor={target}>
        {loading ? <Loading /> : <Untouched />}
      </label>
    </div>
  );
};

export default InputFile;
