import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { IonButton } from '@ionic/react';
import Anchor from 'components/DesignSystem/Anchor';
import OrderDetailsS6 from './OrderDetailsS6';

import './OrderDetailsContactStore.scss';
import { ORDER_STATE_FAILED } from 'constants/orderStates';
import { CUSTOMER_APP_ROLE } from 'constants/defaults';

interface ComponentProps {
  order: any;
}

const OrderDetailsContactStore: React.FC<ComponentProps> = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const openModal = useCallback(
    (event) => {
      event.preventDefault();
      setOpen(true);
    },
    [setOpen]
  );

  return (
    <div className="order-contact-store center">
      <IonButton
        className="order-contact-store-contact button-secondary w-100"
        onClick={openModal}
        color="light"
        size="large"
        mode="md"
        shape={undefined}
      >
        <FormattedMessage
          id="order.s4.pickup_later.button"
          defaultMessage="Pickup Later"
        />
      </IonButton>
      <OrderDetailsS6 isOpen={isOpen} setOpen={setOpen} />
    </div>
  );
};

export default OrderDetailsContactStore;
