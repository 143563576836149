import React from 'react';
import { useIntl } from 'react-intl';

import './OrderDetailsHeader.scss';

interface ContainerProps {
  order: any;
}

const OrderDetailsHeader: React.FC<ContainerProps> = ({ order }) => {
  const intl = useIntl();

  return (
    <div className="order-id-wrapper center">
      <div className="d-block">
        <span className="order-label">
          {intl.formatMessage({
              id: 'order.order',
              defaultMessage: 'Order'
            }).toUpperCase()}
          #:
        </span>
        <span className="order-number">{order.externalId}</span>
      </div>
    </div>
  );
};

export default OrderDetailsHeader;
