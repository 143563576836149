import {
  FETCH_ORDERS_RECEIVED,
  SET_CUSTOMER_ORDER,
  START_PICKUP_RETRY,
  SET_CUSTOMER_READY_POP_UPS,
  WIPE_SESSION,
  SET_ORDER_FILTER,
  SET_ORDER_STATE,
} from 'actions/actionTypes';
import { defaultReducer, unknownReducer } from './utils';
import type { Action } from 'redux';

export type OrderState = {
  currentOrder?: {}; // Customer Order will be here
  isRetryingPickup: false;
  orders: {};
  status: {};
  lastupdate: null; //timestamp to fetch recent orders;
  customerReadyPopUps?: Array<any>;
  filters: {
    isFirstRequest: true;
    fromDate: undefined;
    toDate: undefined;
  };
  pauseCurbPolling: false;
};

const setCustomerOrder = (state: any, action: any) => {
  return { ...state, currentOrder: action.payload, isRetryingPickup: false };
};

const setCustomerReadyPopUps = (state: any = {}, action: any = {}) => ({
  ...state,
  customerReadyPopUps: action.payload.customerReadyPopUps,
});

const reducerMap: any = {
  [SET_CUSTOMER_ORDER]: setCustomerOrder,
  [START_PICKUP_RETRY]: defaultReducer,
  [FETCH_ORDERS_RECEIVED]: defaultReducer,
  [SET_ORDER_FILTER]: defaultReducer,
  [SET_ORDER_STATE]: defaultReducer,
  [SET_CUSTOMER_READY_POP_UPS]: setCustomerReadyPopUps,
  [WIPE_SESSION]: () => ({}),
};

export default (state = {}, action: Action) => {
  const reducer = reducerMap[action.type] || unknownReducer;

  return reducer(state, action);
};
