import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { IonHeader, IonImg } from '@ionic/react';
import './CPHeader.scss';
import { useSelector } from 'react-redux';
import { getLogo, getName, getAvailableLanguages } from 'selectors/client';
import Anchor from 'components/DesignSystem/Anchor';
import { StoreContext } from 'contexts';
import { LOGOUT_SAGA, GET_STORE_DETAILS, SET_STORE_LANGUAGE } from 'actions/actionTypes';
import { getStoreId, getCurrentStore, getSessionLanguage, getStoreDefaultLanguage } from 'selectors/session';
import LanguageDrowpdown from '../CPLanguageDropdown';
import { getCustomerOrder } from 'selectors/orders';
import { useIntl } from 'react-intl';

interface ICPHeaderProps {
  suppressLogout?: boolean;
  suppressStore?: boolean;
}

const CPHeader: React.FC<ICPHeaderProps> = ({
  suppressLogout = false,
  suppressStore = false,
}) => {
  const intl = useIntl();
  const { action } = useContext(StoreContext);
  const logo = useSelector(getLogo);
  const name = useSelector(getName);
  const storeId = useSelector(getStoreId);
  const storeDetails = useSelector(getCurrentStore);
  const currentOrder = useSelector(getCustomerOrder);
  const orderLanguage = currentOrder ? currentOrder.orderLanguage : null;
  const sessionLanguage = useSelector(getSessionLanguage);
  const storeDefaultLanguage = useSelector(getStoreDefaultLanguage);

  /**
   * Updates the session each time the language is updated via header selector.
   * @param lang
   */
  const updateStoreLanguage = (lang: string) => {
    action(SET_STORE_LANGUAGE, lang);
    return lang;
  }

  const currentStoreLanguage = useMemo(() => {
    return updateStoreLanguage(sessionLanguage || storeDefaultLanguage);
  }, [sessionLanguage, storeDefaultLanguage]);

  let deliveryMethods = [];

  if (storeDetails && storeDetails.deliveryMethods) {
    deliveryMethods = storeDetails.deliveryMethods
      .filter((method: any) => method.deliveryMethod.active)
      .map((item: any) => item.deliveryMethod.id.toUpperCase());
  }

  const getDeliveryMethodsMessage = (deliveryMethods: any) => {
    const thisStoreDesc = intl.formatMessage({ id: 'header.thisStoreDescription', defaultMessage: 'This store is' });
    const storeEnabledDesc = intl.formatMessage({ id: 'header.enabledDescription', defaultMessage: 'enabled.' });
    const andDescription = intl.formatMessage({ id: 'header.andDescription', defaultMessage: 'and' });
    if (deliveryMethods.length === 1) {
      return `${thisStoreDesc} ${deliveryMethods[0]} ${storeEnabledDesc}`;
    } else {
      return `${thisStoreDesc} ${deliveryMethods[0]} ${andDescription} ${deliveryMethods[1]} ${storeEnabledDesc}`;
    }
  };

  const onLogoutClient = useCallback(
    (e) => {
      e.preventDefault();
      action(LOGOUT_SAGA);
      return false;
    },
    [action]
  );

  useEffect(() => {
    updateStoreLanguage(orderLanguage);
  }, [orderLanguage]);

  useEffect(() => {
    if (!suppressStore && storeId) {
      action(GET_STORE_DETAILS);
    }
  }, [action, storeId]);

  return (
    <IonHeader className="cp-header">
      <div className="content-wrapper">
        <div className="logo-wrapper d-flex">
          <IonImg src={logo} title={name} className="client-logo" />
          {!suppressStore && storeId && (
            <div className="store-id-wrapper">
              <p className="store-id-label">{intl.formatMessage({ id: 'header.storeDescription', defaultMessage: 'STORE#' })} {storeId}</p>
              {deliveryMethods.length ? (
                <p className="store-id-label delivery-method-text">
                  {getDeliveryMethodsMessage(deliveryMethods)}
                </p>
              ) : null}
            </div>
          )}
        </div>
        <div className="d-flex">
          <LanguageDrowpdown
            supportedLanguages={getAvailableLanguages(currentStoreLanguage)}
            setSelectedLanguage={updateStoreLanguage}
            selectedLanguage={currentStoreLanguage}
            customClassName="header-languages-dropdown"
          />
          {!suppressLogout && (
            <Anchor
              className="manager-logout"
              onClick={(event: Event) => onLogoutClient(event)}
            >
              {intl.formatMessage({ id: 'header.logout', defaultMessage: 'logout' })}
            </Anchor>
          )}
        </div>
      </div>
    </IonHeader>
  );
};

export default CPHeader;
