import { getSession } from '../../selectors/session';
import { put, select } from 'redux-saga/effects';
import { SET_SESSION } from 'actions/actionTypes';

export function* setStoreLanguage(lang) {
  try {
    const currentLanguage = lang.payload;
    const currentSession = yield select(getSession);

    yield put({
      type: SET_SESSION,
      payload: { ...currentSession, currentLanguage }
    });


  } catch (e) {}
}
