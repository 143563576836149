import { request } from 'utils/restClient';

import { CUSTOMER_GET_STORE_DETAILS, CUSTOMER_GET_STORE_DETAILS_AUTHENTICATED } from 'constants/endpoints';

export const getStoreDetailsForCustomer = ({ clientId, storeId, token }) => {
  return request(CUSTOMER_GET_STORE_DETAILS, {
    pathParams: { clientId, storeId },
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const fetchStoreDetailsById = ({ clientId, storeId, token }) => {
  return request(CUSTOMER_GET_STORE_DETAILS_AUTHENTICATED, {
    pathParams: { clientId, storeId },
    headers: {
      Authorization: `${token}`,
    },
  });
};
