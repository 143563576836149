import React from 'react';
import { FormattedMessage } from 'react-intl';
import { checkmarkCircle } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsInfo from './OrderDetailsInfo';
import OrderDetailsContentWrapper from './OrderDetailsContentWrapper';
import Anchor from 'components/DesignSystem/Anchor';
import { getCurrentStore } from 'selectors/session';
import { useIntl } from 'react-intl';

interface ComponentProps {
  order: any;
}

interface SubtitleProps {
  phoneNumber: any;
}

const ComponentSubtitle: React.FC<SubtitleProps> = ({ phoneNumber }) => {
  const intl = useIntl();

  return ( 
    <>
      <FormattedMessage
        id="order.s1a.subtitle1"
        defaultMessage={intl.formatMessage({ id: 'notifications.wellBeWithYouIn', defaultMessage: 'We’ll be with you in around 5-15 minutes.' })}
      />
      <br />
      <FormattedMessage
        id="order.s1a.subtitle2"
        defaultMessage={intl.formatMessage({ id: 'notifications.ifYouAreInThrouble', defaultMessage: 'If you have any trouble, please contact our store at' })}
      />
      {' '}
      <Anchor href={`tel:${phoneNumber}`} className="default-link d-inline-block">
        {phoneNumber}
      </Anchor>
      .
    </>
  );
};

const OrderDetailsS1A: React.FC<ComponentProps> = ({ order }) => {
  const storeInfo = useSelector(getCurrentStore) || {};
  const intl = useIntl();

  return (
    <>
      <OrderDetailsHeader order={order} />
      <OrderDetailsContentWrapper>
        <OrderDetailsInfo
          icon={checkmarkCircle}
          iconColor="success"
          title={
            <FormattedMessage
              id="order.s1a.title"
              defaultMessage={intl.formatMessage({ id: 'notifications.gladTooSeeYou', defaultMessage: 'Glad to see you are here' })}
            />
          }
          subtitle={<ComponentSubtitle phoneNumber={storeInfo.phone} />}
        />
      </OrderDetailsContentWrapper>
    </>
  );
};

export default OrderDetailsS1A;
