import { combineReducers } from 'redux';
import client from './client';
import session from './session';
import orders from './orders';
import ui from './ui';
import user from './user';
import navigation from './navigation';
import notification from './notification';

export interface RootState {
  client: any;
  session: any;
  orders: any;
  ui: any;
  user: any;
  navigation: any;
  notification: any;
}

export default combineReducers<RootState>({
  client,
  session,
  orders,
  ui,
  user,
  navigation,
  notification,
});
