import { Method } from 'axios';

/**
 * @interface EndpointStructure
 *
 * @property path The API endpoint
 * @property auth Defines whether an Authorization using Token header must be added
 * @property intercept Intercepts unauthorized calls and redirect to login
 */
interface EndpointStructure {
  method: Method;
  path: string;
  auth: boolean;
  redirectOn401: boolean;
}

/**
 * @interface EndpointMap
 *
 * @description Key/Object mapping for Endpoint Structure
 */
interface EndpointMap {
  [key: string]: EndpointStructure;
}

/**
 * Endpoint Constants
 */

// App Admin API
export const CUSTOMER_LOGIN = 'customer-login';
export const PUBLIC_CLIENT_DETAILS = 'public-client-details';
// General Use APIs
export const LOGIN_API = 'login-api';
export const REFRESH_TOKEN_API = 'refresh-token-api';

//store API
export const CLIENT_STORE_DETAILS = 'client-store-details';
export const GET_CLIENT_ALL_STORES_IDS = 'clien-stores-id-on-login';

// Customers
export const CUSTOMER_GET_ORDER_DETAILS = 'customer-get-order-details';
export const CUSTOMER_GET_STORE_DETAILS = 'customer-get-store-details';
export const CUSTOMER_GET_STORE_DETAILS_AUTHENTICATED = 'customer-get-store-details';
export const CUSTOMER_CREATE_EVENT = 'customer-create-event';
export const CUSTOMER_GET_ORIGINAL_URL = 'customer-get-original-url';
export const CUSTOMER_GET_REDIRECT_URL = 'customer-get-redirect-url';

// Client Admin
export const CLIENT_ADMIN_LOGIN = 'client-admin-login';
export const CLIENT_ADMIN_REFRESH = 'client-admin-refresh';
export const CLIENT_ADMIN_IMPORT_STORES = 'client-admin-import-stores';
export const CLIENT_ADMIN_IMPORT_OPTED_OUT_PHONES =
  'client-admin-import-optedout-phones';
export const CLIENT_ADMIN_IMPORT_CUSTOMERS = 'client-admin-import-customers';
export const CLIENT_ADMIN_GET_STORES = 'client-admin-store';
export const CLIENT_CREATE_STORE = 'client-admin-create-store';
export const CLIENT_UPDATE_STORE_STATUS = 'client-admin-update-store-status';
export const CLIENT_UPDATE_STORES = 'client-admin-update-store';
export const CLIENT_UPDATE_DETAILS = 'client-admin-update-client';
export const CLIENT_REQUEST_PASSWORD_RESET = 'client-reset-password-request';
export const CLIENT_UPDATE_PASSWORD = 'client-reset-password';

export const DISPATCHER_LOGIN = 'dispatcher-login';
export const DISPATCHER_GET_ORDERS = 'dispatcher-get-orders';
export const DISPATCHER_GET_STORE_DETAILS = 'dispatcher-get-store-details';
export const DISPATCHER_CREATE_ORDER = 'dispatcher-create-order';
export const DISPATCHER_CREATE_EVENT = 'dispatcher-create-event';
export const DISPATCHER_GET_ORDER_DETAILS = 'dispatcher-get-order-details';
export const GET_SUPPORTED_PHONE_AREAS = 'get-supported-phone-areas';
export const DISPATCHER_UPDATE_ORDER_DETAILS =
  'dispatcher-update-order-details';

// Endpoint Map
export const endpoints: EndpointMap = {
  [CUSTOMER_LOGIN]: {
    method: 'POST',
    path: '/login',
    auth: false,
    redirectOn401: false,
  },
  [GET_CLIENT_ALL_STORES_IDS]: {
    method: 'GET',
    path: '/clients/{clientId}/cstores',
    auth: false,
    redirectOn401: false,
  },
  [PUBLIC_CLIENT_DETAILS]: {
    method: 'GET',
    path: '/clients/{clientCode}',
    auth: false,
    redirectOn401: false,
  },
  [CLIENT_UPDATE_DETAILS]: {
    method: 'PUT',
    path: '/clients/{clientId}',
    auth: true,
    redirectOn401: true,
  },
  [CLIENT_ADMIN_GET_STORES]: {
    method: 'GET',
    path: '/clients/{clientId}/stores',
    auth: true,
    redirectOn401: true,
  },
  [CLIENT_CREATE_STORE]: {
    method: 'POST',
    path: '/clients/{clientId}/stores',
    auth: true,
    redirectOn401: true,
  },
  [CLIENT_UPDATE_STORE_STATUS]: {
    method: 'POST',
    path: '/clients/{clientId}/stores/{storeId}/{status}',
    auth: true,
    redirectOn401: true,
  },
  [CLIENT_UPDATE_STORES]: {
    method: 'PUT',
    path: '/clients/{clientId}/stores/{storeId}',
    auth: true,
    redirectOn401: true,
  },
  [CLIENT_ADMIN_LOGIN]: {
    method: 'POST',
    path: '/login',
    auth: false,
    redirectOn401: false,
  },
  [CLIENT_REQUEST_PASSWORD_RESET]: {
    method: 'POST',
    path: '/password/reset',
    auth: false,
    redirectOn401: false,
  },
  [CLIENT_UPDATE_PASSWORD]: {
    method: 'PUT',
    path: '/clients/{clientId}',
    auth: false,
    redirectOn401: false,
  },
  [CLIENT_ADMIN_REFRESH]: {
    method: 'POST',
    path: '/api/client-admin/refresh',
    auth: true,
    redirectOn401: true,
  },
  [CLIENT_ADMIN_IMPORT_STORES]: {
    method: 'POST',
    path: '/clients/{clientId}/stores',
    auth: true,
    redirectOn401: true,
  },
  [CLIENT_ADMIN_IMPORT_OPTED_OUT_PHONES]: {
    method: 'POST',
    path: '/notifications/phones/blocked/{clientId}',
    auth: true,
    redirectOn401: true,
  },
  [CLIENT_ADMIN_IMPORT_CUSTOMERS]: {
    method: 'POST',
    path: '/clients/{clientId}/customers',
    auth: true,
    redirectOn401: true,
  },
  [CUSTOMER_GET_ORIGINAL_URL]: {
    method: 'GET',
    path: '/shorten-url/{shortenKey}',
    auth: false,
    redirectOn401: false,
  },
  [CUSTOMER_GET_REDIRECT_URL]: {
    method: 'GET',
    path: '/redirect/shorten-url/{shortenKey}',
    auth: false,
    redirectOn401: false,
  },
  [CUSTOMER_GET_ORDER_DETAILS]: {
    method: 'GET',
    path: '/clients/{clientId}/stores/{storeId}/orders/{orderId}',
    auth: false,
    redirectOn401: false,
  },
  [CUSTOMER_GET_STORE_DETAILS]: {
    method: 'GET',
    path: '/clients/{clientId}/stores/{storeId}',
    auth: false,
    redirectOn401: false,
  },
  [CUSTOMER_GET_STORE_DETAILS_AUTHENTICATED]: {
    method: 'GET',
    path: '/clients/{clientId}/stores/{storeId}',
    auth: false,
    redirectOn401: true,
  },
  [CUSTOMER_CREATE_EVENT]: {
    method: 'POST',
    path: '/clients/{clientId}/stores/{storeId}/orders/{orderId}/events',
    auth: false,
    redirectOn401: false,
  },
  [DISPATCHER_LOGIN]: {
    method: 'POST',
    path: '/login',
    auth: false,
    redirectOn401: false,
  },
  [DISPATCHER_GET_ORDERS]: {
    method: 'GET',
    path: '/clients/{clientId}/stores/{storeId}/orders',
    auth: true,
    redirectOn401: true,
  },
  [DISPATCHER_GET_STORE_DETAILS]: {
    method: 'GET',
    path: '/clients/{clientId}/stores/{storeId}',
    auth: true,
    redirectOn401: true,
  },
  [DISPATCHER_GET_ORDER_DETAILS]: {
    method: 'GET',
    path: '/clients/{clientId}/stores/{storeId}/orders/{orderId}',
    auth: true,
    redirectOn401: true,
  },
  [DISPATCHER_UPDATE_ORDER_DETAILS]: {
    method: 'PATCH',
    path: '/clients/{clientId}/stores/{storeId}/orders/{orderId}',
    auth: true,
    redirectOn401: true,
  },
  [DISPATCHER_CREATE_EVENT]: {
    method: 'POST',
    path: '/clients/{clientId}/stores/{storeId}/orders/{orderId}/events',
    auth: true,
    redirectOn401: true,
  },
  [DISPATCHER_CREATE_ORDER]: {
    method: 'POST',
    path: '/clients/{clientId}/stores/{storeId}/orders',
    auth: true,
    redirectOn401: true,
  },
  [LOGIN_API]: {
    method: 'POST',
    path: '/login',
    auth: false,
    redirectOn401: false,
  },
  [GET_SUPPORTED_PHONE_AREAS]: {
    method: 'GET',
    path: '/clients/{clientId}/phone-areas',
    auth: true,
    redirectOn401: true,
  },
  // Shared accross roles
  [REFRESH_TOKEN_API]: {
    method: 'POST',
    path: '/token/refresh',
    auth: true,
    redirectOn401: true,
  },
};

export const getEndpointMeta = (endpoint: string) => endpoints[endpoint];

export default endpoints;
