import React from 'react';

import { IonSpinner } from '@ionic/react';

import './Loader.scss';

const Loader = (props: any) => {
  const { className = '', withMinHeight = true, ...otherProps } = props;
  const parsedClassName = ['loader-wrapper', 'flex'];

  if (className) {
    parsedClassName.push(className);
  }

  if (withMinHeight) {
    parsedClassName.push('with-min-height');
  }

  return (
    <div className={`${parsedClassName.join(' ')}`}>
      <IonSpinner name="crescent" {...otherProps} />
    </div>
  );
};

export default Loader;
