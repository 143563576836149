import React, { useState, useContext, useCallback } from 'react';
import {
  IonButton,
  IonGrid,
  IonIcon,
  IonContent,
  IonModal,
  IonInput,
  IonText,
  IonRow,
  IonCol,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonItem,
} from '@ionic/react';
import { RootState } from 'reducers';
import { add, search, close } from 'ionicons/icons';
import UnifiedStoreForm from 'components/UnifiedStoreForm';
import './CPStoreDetails.scss';
import { useSelector } from 'react-redux';
import { getClientFilteredStores, getClientStore } from 'selectors/client';
import {
  FETCH_CLIENT_STORES,
  UPDATE_STORE_STATUS,
  SET_LOADING,
  REFRESH_TOKEN,
  LOGOUT_SAGA,
} from 'actions/actionTypes';
import { getClientId } from 'selectors/session';
import { StoreContext } from 'contexts';
import Loader from 'components/DesignSystem/Loader';
import StoreInfoCard from './subcomponent/StoreInfoCard';
import { getClient } from 'selectors/client';
import { getToken } from 'selectors/session';
import Anchor from 'components/DesignSystem/Anchor';
import { getHistory } from 'selectors/navigation';
import { CLIENT_ADM_ROLE } from 'constants/defaults';
import { getLoading } from 'selectors/ui';

interface ContainerProps {}

const CPStoreDetails: React.FC<ContainerProps> = () => {
  const history = useSelector(getHistory);
  const authToken = useSelector(getToken);
  const isLoading = useSelector(getLoading);
  const [searchText, setSearchText] = useState('');
  const [storeToUpdate, setStoreToUpdate] = useState(null);
  const [showAddStoreModal, setShowAddStoreModal] = useState(false);
  const [showUpdateStoreModal, setShowUpdateStoreModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [storeToUpdateStatus, setStoreToUpdateStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('');
  const clientStores = useSelector(getClientStore);
  const clientStoreData = useSelector((state: RootState) =>
    getClientFilteredStores(state)(searchText)
  );
  const clientId = useSelector(getClientId);
  const { action, notify } = useContext(StoreContext);
  const { maxNumberOfStores } = useSelector(getClient);

  const closeUpdateStoreModal = () => {
    setShowUpdateStoreModal(false);
    setStoreToUpdate(null);
  };
  const closeAddStoreModal = () => {
    setShowAddStoreModal(false);
  };
  const closeConfirmDelete = () => {
    setShowConfirmDelete(false);
    setStoreToUpdateStatus(null);
    setUpdateStatus('');
  };

  const editStoreDetails = (data: any) => {
    setStoreToUpdate(data);
    setShowUpdateStoreModal(true);
  };
  const updateClientStore = (id: any, status: string) => {
    setStoreToUpdateStatus(id);
    setUpdateStatus(status);
    setShowConfirmDelete(true);
  };
  const changeStoreStatus = () => {
    setIsSubmitting(true);

    action(UPDATE_STORE_STATUS, {
      clientId,
      storeId: storeToUpdateStatus,
      status: updateStatus,
    }).then((res: any) => {
      if (res && res.status === 200) {
        notify({
          color: 'success',
          message: `Store ${updateStatus}d successfully !`,
        });
        closeConfirmDelete();
      }
      setIsSubmitting(false);
    });
  };

  const activeStoresCount = clientStores.reduce(
    (count: number, store: any) => (store.active ? ++count : count),
    0
  );

  const returnToLogin = useCallback(() => {
    action(SET_LOADING, { loading: false });
    action(LOGOUT_SAGA, { role: CLIENT_ADM_ROLE });
  }, [history]);

  useIonViewWillEnter(() => {
    // Add loading flag to ensure we present loader during
    // refresh call
    action(SET_LOADING, { loading: true });

    if (authToken) {
      action(REFRESH_TOKEN, {
        clientId,
        role: CLIENT_ADM_ROLE,
      }).then((res: any) => {
        if (!res.data.token) {
          returnToLogin();
        }

        action(FETCH_CLIENT_STORES).then(() => {
          action(SET_LOADING, { loading: false });
        });
      });
    }
  }, [authToken]);

  useIonViewDidEnter(() => {
    if (!authToken) {
      // Remove loading
      action(SET_LOADING, { loading: false });

      // Notify the customer about his expiration
      notify({
        message: 'Your session is expired. Please, sign in again.',
        duration: 4000,
        color: 'danger',
      });

      returnToLogin();
    }
  }, [authToken]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="store-details">
      <IonGrid>
        <div className="available-info-wrapper">
          <div>
            <IonText className="available-info-text">
              You have used
              <b>{` ${activeStoresCount}/${maxNumberOfStores} `}</b>
              available licenses
            </IonText>
            {maxNumberOfStores === activeStoresCount && (
              <Anchor
                className="purchase-link"
                href="https://www.getcurbspot.com/pages/manage-your-subscription-here"
                target="_blank"
              >
                Purchase more licenses
              </Anchor>
            )}
          </div>
          <IonItem className="search-order">
            <IonIcon className="search-icon" slot="start" icon={search} />
            <IonInput
              value={searchText}
              placeholder="Search Stores"
              onIonChange={(e) => setSearchText(e.detail.value!)}
              clearInput
              mode="md"
              inputmode="search"
            ></IonInput>
          </IonItem>
          <IonButton
            className="add-store-button"
            color="dark"
            mode="ios"
            disabled={maxNumberOfStores === activeStoresCount}
            onClick={() => {
              setShowAddStoreModal(true);
            }}
          >
            <IonIcon slot="end" icon={add} />
            Add Store
          </IonButton>
        </div>
        <IonRow className="stores-container">
          {clientStoreData ? (
            clientStoreData.map((store: any) => {
              return (
                <IonCol
                  sizeXs="12"
                  sizeMd="4"
                  sizeLg="3"
                  className="store-card-wrapper"
                  key={store.id}
                >
                  <StoreInfoCard
                    store={store}
                    editStoreDetails={editStoreDetails}
                    updateClientStore={updateClientStore}
                    isActivatable={maxNumberOfStores === activeStoresCount}
                  />
                </IonCol>
              );
            })
          ) : (
            <Loader />
          )}
        </IonRow>
        {clientStoreData && clientStoreData.length === 0 && (
          <IonText>There are no stores created on your account.</IonText>
        )}
      </IonGrid>
      <IonModal
        cssClass="update-form-modal-container"
        isOpen={showUpdateStoreModal}
        onDidDismiss={() => closeUpdateStoreModal()}
        backdropDismiss={true}
        keyboardClose={false}
      >
        <IonContent>
          <div className="update-form-modal">
            <IonIcon
              src="/assets/icon/close-outline.svg"
              onClick={() => closeUpdateStoreModal()}
              className="modal-close-icon"
              icon={close}
            ></IonIcon>
            <UnifiedStoreForm
              closeModal={closeUpdateStoreModal}
              storeInfo={storeToUpdate}
              isUpdateForm={true}
            />
          </div>
        </IonContent>
      </IonModal>
      <IonModal
        cssClass="update-form-modal-container"
        isOpen={showAddStoreModal}
        onDidDismiss={() => closeAddStoreModal()}
        backdropDismiss={true}
        keyboardClose={false}
      >
        <IonContent>
          <div className="update-form-modal">
            <IonIcon
              src="/assets/icon/close-outline.svg"
              onClick={() => closeAddStoreModal()}
              className="modal-close-icon"
              icon={close}
            ></IonIcon>
            <UnifiedStoreForm
              closeModal={closeAddStoreModal}
              storeInfo={{}}
              isUpdateForm={false}
            />
          </div>
        </IonContent>
      </IonModal>

      <IonModal
        cssClass="confirm-modal-container"
        isOpen={showConfirmDelete}
        onDidDismiss={closeConfirmDelete}
        backdropDismiss={true}
        keyboardClose={false}
      >
        <IonContent>
          <div className="confirm-modal">
            <IonIcon
              src="/assets/icon/close-outline.svg"
              onClick={closeConfirmDelete}
              className="modal-close-icon"
              icon={close}
            ></IonIcon>
            <div>
              <IonText color="dark">
                <h1 className="confirm-heading">
                  {updateStatus === 'inactivate'
                    ? 'Deactivate Store'
                    : 'Reactivate Store'}
                </h1>
              </IonText>
              <IonText className="confirm-text">
                Are you sure you would like to
                <br />
                {updateStatus === 'inactivate'
                  ? ' Deactivate '
                  : ' Reactivate '}
                this store?
              </IonText>

              <IonButton
                className="confirm-button"
                color="dark"
                size="default"
                mode="ios"
                shape={undefined}
                onClick={changeStoreStatus}
                type="submit"
                disabled={isSubmitting}
                buttonType="button"
              >
                {isSubmitting ? (
                  <Loader withMinHeight={false} />
                ) : updateStatus === 'inactivate' ? (
                  'Deactivate'
                ) : (
                  'Reactivate'
                )}
              </IonButton>
              <IonButton
                className="confirm-button"
                color="light"
                size="default"
                mode="ios"
                shape={undefined}
                disabled={isSubmitting}
                onClick={closeConfirmDelete}
              >
                CANCEL
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default CPStoreDetails;
