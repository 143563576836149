import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getNotification } from '../../selectors/notification';
import { IonToast } from '@ionic/react';
import { StoreContext } from 'contexts';

const Notification: React.FC = () => {
  const { message: notifyMessage, duration = 2000, color } = useSelector(
    getNotification
  );
  const { notify } = useContext(StoreContext);

  return notifyMessage ? (
    <IonToast
      position="top"
      isOpen={!!notifyMessage}
      onDidDismiss={() => {
        notify({ message: '' });
      }}
      message={notifyMessage}
      duration={duration}
      color={color}
    />
  ) : null;
};
export default Notification;
