import React from 'react';
import { useSelector } from 'react-redux';
import { BOPIS_DELIVERY } from 'constants/defaults';
import { getInstructions } from 'utils/stringUtils';
import { getSessionLanguage } from 'selectors/session';
import { FormattedMessage } from 'react-intl';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsInfo from './OrderDetailsInfo';
import OrderDetailsPickupLocation from './OrderDetailsPickupLocation';
import OrderDetailsContentWrapper from './OrderDetailsContentWrapper';
import OrderDetailsBopisInstructions from './OrderDetailsBopisInstructions';

interface ComponentProps {
  order: any;
  store: any;
}

const OrderDetailsS1BopisOnly: React.FC<ComponentProps> = ({
  order,
  store,
}) => {
  const bopis = store.deliveryMethods.find(
    (method: any) => method.deliveryMethod.id === BOPIS_DELIVERY
  );

  const bopisInstructions = bopis.instructions || bopis.deliveryMethod.instructions;

  const sessionLanguage = useSelector(getSessionLanguage);
  const language = sessionLanguage || order.orderLanguage;
  const bopisInstructionsDecomposed = getInstructions(language, bopisInstructions);

  return (
    <>
      <OrderDetailsHeader order={order} />
      <OrderDetailsContentWrapper>
        <OrderDetailsInfo
          title={
            <FormattedMessage
              id="order.s1.bopis.title"
              defaultMessage='Your order is ready{linebreak}for pickup'
              values={{ linebreak: <br /> }}
            />
          }
          subtitle={
            <FormattedMessage
              id="order.s1.bopis.subtitle"
              defaultMessage='You can retrieve your order by walking into our store and heading to our pickup station.'
            />
          }
          additionalDesc={
            <FormattedMessage
              id="order.s1.bopis.additional_desc"
              defaultMessage='If you run into any trouble finding the pickup station, please let one of our team members know.'
            />
          }
        />
      </OrderDetailsContentWrapper>
      <OrderDetailsContentWrapper>
        <OrderDetailsBopisInstructions store={store} order={order} instructions={bopisInstructionsDecomposed} />
      </OrderDetailsContentWrapper>
      <OrderDetailsPickupLocation />
    </>
  );
};

export default OrderDetailsS1BopisOnly;
