import React, { useState, useContext, useCallback, useEffect } from 'react';
import './Home.scss';
import { add, search } from 'ionicons/icons';
import OrderList from 'components/OrderList';
import { withSize } from 'react-sizeme';
import CPFooter from 'components/CPFooter';
import CustomerReadyPopups from 'components/CustomerReadyPopups';
import UnifiedOrderForm from 'components/UnifiedOrderForm';
import CPHeader from 'components/CPHeader';
import { getViewPort } from 'utils/getViewPort';
import DatePicker from 'react-date-picker';
import {
  IonPage,
  IonContent,
  IonModal,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  useIonViewDidEnter,
} from '@ionic/react';

import {
  FETCH_ORDERS_POLLING,
  FETCH_ORDERS_FILTERED,
  GET_SUPPORTED_COUNTRIES,
} from 'actions/actionTypes';
import { useSelector } from 'react-redux';
import { StoreContext, ViewPortContext } from 'contexts';
import {
  ORDER_STATE_CUSTOMER_READY,
  ORDER_STATE_DELIVERED,
  ORDER_STATE_OUT_FOR_CURBSIDE,
  ORDER_STATE_PENDING_PICKUP,
  ORDER_STATE_UNDER_PICKUP,
} from 'constants/orderStates';
import { getCustomerReadyNotifications } from 'selectors/orders';
import { getClientId, getStoreId, getRole } from 'selectors/session';
import Loader from 'components/DesignSystem/Loader';
import { DISPATCHER_ROLE } from 'constants/defaults';
import { useIntl } from 'react-intl';

interface ContainerProps {
  size: any;
}

const Home: React.FC<ContainerProps> = ({ size }) => {
  const [selectedDate, setSelectedDate] = useState<any>();
  const { isMobile } = getViewPort(size.width);
  const { action } = useContext(StoreContext);
  const customerReadyNotifications = useSelector(getCustomerReadyNotifications);
  const role = useSelector(getRole);
  const [searchText, setSearchText] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expandColumn, setExpandColumn] = useState(false);
  const clientId = useSelector(getClientId);
  const storeId = useSelector(getStoreId);
  const currentDay = new Date();
  const intl = useIntl();

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchOrder = useCallback(() => {
    action(FETCH_ORDERS_FILTERED, {
      pauseCurbPolling: false,
      filters: {
        isFirstRequest: true,
        timeRangeInMinutes: undefined,
        fromDate: null,
        toDate: null,
      },
    })
      .then((data: any) => {
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
      });
  }, [action, setIsLoading]);

  const fetchOrderOfDate = (date: any) => {
    if (date !== null) {
      action(FETCH_ORDERS_FILTERED, {
        pauseCurbPolling: true,
        filters: {
          timeRangeInMinutes: undefined,
          fromDate: date.getTime(),
          toDate: date.getTime() + 86400000,
        },
      });
    } else {
      fetchOrder();
      setShowDatePicker(false);
      return;
    }
  };

  useIonViewDidEnter(() => {
    fetchOrder();
    action(FETCH_ORDERS_POLLING);
  }, [clientId, storeId]);

  useEffect(() => {
    if (role === DISPATCHER_ROLE) {
      action(GET_SUPPORTED_COUNTRIES, { storeId });
    }
  }, [storeId, action, role]);

  return (
    <ViewPortContext.Provider value={size}>
      <IonPage className="orders-page">
        <CPHeader />
        {customerReadyNotifications.length > 0 && (
          <CustomerReadyPopups
            setExpandColumn={setExpandColumn}
            customerReadyNotifications={customerReadyNotifications}
          />
        )}
        <IonContent className="reset-padding">
          <IonModal
            cssClass="add-address-modal-container"
            isOpen={showModal}
            onDidDismiss={() => closeModal()}
            backdropDismiss={true}
            keyboardClose={false}
          >
            <IonContent>
              <div className="add-address-modal">
                <IonIcon
                  src="/assets/icon/close-outline.svg"
                  onClick={() => closeModal()}
                  className="modal-close-icon"
                  name="close"
                  icon="close"
                ></IonIcon>
                <UnifiedOrderForm
                  order={{}}
                  isOrderUpdateForm={false}
                  closeModal={closeModal}
                />
              </div>
            </IonContent>
          </IonModal>
          <IonGrid className="reset-padding">
            <IonRow>
              <IonCol className="reset-padding" size="12">
                <IonRow>
                  <div className="order-container">
                    <IonRow className="filter-section">
                      <div className="add-order-div">
                        <IonButton
                          color="dark"
                          size="large"
                          mode="md"
                          shape={undefined}
                          disabled={role !== DISPATCHER_ROLE}
                          className="add-order-button"
                          onClick={() => setShowModal(true)}
                        >
                          {intl.formatMessage({
                            id: 'dashboard.addOrder',
                            defaultMessage: 'Add order'
                          })}
                          <IonIcon slot="end" icon={add} />
                        </IonButton>
                      </div>
                      <div
                        className={
                          isMobile ? 'search-field' : 'search-field d-flex'
                        }
                      >
                        {showDatePicker ? (
                          <DatePicker
                            className="date-picker-field"
                            value={selectedDate}
                            isOpen={showDatePicker}
                            clearIcon={selectedDate ? undefined : null}
                            onCalendarClose={() =>
                              !selectedDate && setShowDatePicker(false)
                            }
                            maxDate={currentDay}
                            minDate={new Date(currentDay.getTime() - 604800000)} // allowing date pick past 7 days.
                            onChange={(e) => {
                              setSelectedDate(e);
                              fetchOrderOfDate(e);
                            }}
                          />
                        ) : (
                          <IonButton
                            color="light"
                            size="large"
                            mode="md"
                            shape={undefined}
                            className="add-order-button date-filter-button"
                            onClick={() => setShowDatePicker(true)}
                          >
                            {intl.formatMessage({
                              id: 'dashboard.filter.button.label',
                              defaultMessage: 'Filter order by date'
                            })}
                          </IonButton>
                        )}
                        <IonItem className="search-order">
                          <IonIcon
                            className="search-icon"
                            slot="start"
                            icon={search}
                          />
                          <IonInput
                            value={searchText}
                            placeholder={intl.formatMessage({
                              id: 'dashboard.filter.placeholder',
                              defaultMessage: 'Search Orders'
                            })}
                            onIonChange={(e) => setSearchText(e.detail.value!)}
                            clearInput
                            mode="md"
                            inputmode="search"
                          ></IonInput>
                        </IonItem>
                      </div>
                    </IonRow>
                    {isLoading ? (
                      <Loader className="initial-loader" />
                    ) : (
                      <IonGrid className="orders-tab">
                        <IonRow className="orders">
                          <IonCol className="order-lane reset-margin-x-sm">
                            <OrderList
                              title={intl.formatMessage({
                                id: 'dashboard.orderReceivedColumn',
                                defaultMessage: 'Order Received'
                              })}
                              status={ORDER_STATE_UNDER_PICKUP}
                              searchText={searchText}
                              expandColumn={expandColumn}
                              setExpandColumn={setExpandColumn}
                            ></OrderList>
                          </IonCol>
                          <IonCol className="order-lane reset-margin-x-sm">
                            <OrderList
                              title={intl.formatMessage({
                                id: 'dashboard.readyForPickupColumn',
                                defaultMessage: 'Ready for pickup'
                              })}
                              status={ORDER_STATE_PENDING_PICKUP}
                              searchText={searchText}
                              expandColumn={expandColumn}
                              setExpandColumn={setExpandColumn}
                            ></OrderList>
                          </IonCol>
                          <IonCol className="order-lane reset-margin-x-sm">
                            <OrderList
                              title={intl.formatMessage({
                                id: 'dashboard.customerWaitingOutside',
                                defaultMessage: 'Customer Waiting outside'
                              })}
                              status={ORDER_STATE_CUSTOMER_READY}
                              searchText={searchText}
                              expandColumn={expandColumn}
                              setExpandColumn={setExpandColumn}
                            ></OrderList>
                          </IonCol>
                          <IonCol className="order-lane reset-margin-x-sm">
                            <OrderList
                              title={intl.formatMessage({
                                id: 'dashboard.outForCurbside',
                                defaultMessage: 'Out For Curbside'
                              })}
                              status={ORDER_STATE_OUT_FOR_CURBSIDE}
                              searchText={searchText}
                              expandColumn={expandColumn}
                              setExpandColumn={setExpandColumn}
                            ></OrderList>
                          </IonCol>
                          <IonCol className="order-lane reset-margin-x-sm">
                            <OrderList
                              title={intl.formatMessage({
                                id: 'dashboard.deliveredToday',
                                defaultMessage: 'Delivered Today'
                              })}
                              status={ORDER_STATE_DELIVERED}
                              searchText={searchText}
                              expandColumn={expandColumn}
                              setExpandColumn={setExpandColumn}
                            ></OrderList>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    )}
                  </div>
                </IonRow>
                <IonRow>
                  <IonCol sizeLg="12" sizeXs="12">
                    <CPFooter />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </ViewPortContext.Provider>
  );
};

export default withSize()(Home);
