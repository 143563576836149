import React, { useCallback, useContext } from 'react';
import { IonButton } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

import './OrderDetailsRetryPickupCta.scss';
import { StoreContext } from 'contexts';
import { START_PICKUP_RETRY } from 'actions/actionTypes';

interface ComponentProps {
  order: any;
}

const OrderDetailsRetryPickupCta: React.FC<ComponentProps> = ({ order }) => {
  const { action } = useContext(StoreContext);

  const restartPickup = useCallback(() => {
    // When Restart is clicked, we will just take the customer to screen one (S1)
    // If the customer reloads the page, he will come back to this page
    // because there's no action in the API
    action(START_PICKUP_RETRY, { isRetryingPickup: true });
  }, []);

  return (
    <div className="order-details-retry-pickup">
      <IonButton
        className="order-details-retry-pickup-cta button-primary w-100"
        color="dark"
        size="large"
        mode="md"
        shape={undefined}
        onClick={restartPickup}
      >
        <FormattedMessage
          id="order.s7.ready_pickup_button"
          defaultMessage="I Am Ready to Pickup"
        />
      </IonButton>
    </div>
  );
};

export default OrderDetailsRetryPickupCta;
