import React from 'react';
import { IonText, IonItem, IonLabel, IonCheckbox } from '@ionic/react';
import StoreImportInfo from '../StoreImportInfo';
import UploadPhoneOptedOut from '../UploadPhoneOptedOut';
import DownloadSamplesLink from '../DownloadSamplesLink';
import {
  PHONE_OPTED_OUT_FILE,
  CUSTOMER_FILE,
  STORE_FILE,
} from '../../constants';

interface UploadFileSectionProps {
  renderCard: any;
}

const UploadFileSection: React.FC<UploadFileSectionProps> = ({
  renderCard,
}) => {
  return (
    <div className="client-admin-board">
      <IonText>
        <h1 className="title">Upload Files</h1>
      </IonText>
      <div className="upload-card-wrapper">
        <div className="upload-card">
          <IonText>
            <p className="upload-card-title">Customer Information</p>
          </IonText>
          {renderCard({
            target: CUSTOMER_FILE,
            inputProps: { disabled: true },
          })}
          <IonItem className="checkbox-wrapper" lines="none">
            <IonLabel className="checkbox-label">
              Check to ignore phone number <br />
              opt-in validation
            </IonLabel>
            <IonCheckbox
              color="dark"
              className="checkbox-input"
              slot="start"
              checked={true}
              disabled={true}
            />
          </IonItem>
        </div>
        <div className="upload-card">
          <StoreImportInfo />
          <IonText>
            <p className="upload-card-title">Store Information</p>
          </IonText>
          {renderCard({ target: STORE_FILE })}
          <DownloadSamplesLink target="https://docs.google.com/spreadsheets/d/1OJnMCuOc7NW7rLlHu8_hMoVbcGhg7PU3qOKG88Fh69Q/edit#gid=667324325" />
        </div>
        <div className="upload-card">
          <UploadPhoneOptedOut />
          <IonText>
            <p className="upload-card-title">Phone Opted Out</p>
          </IonText>
          {renderCard({ target: PHONE_OPTED_OUT_FILE })}
          <DownloadSamplesLink target="https://docs.google.com/spreadsheets/d/19PG7QtruJz0-9yqRJKnLoDiCVkUGlSTq-G8KzOuQ9QA/edit?usp=sharing" />
        </div>
      </div>
    </div>
  );
};

export default UploadFileSection;
