import { put } from 'redux-saga/effects';
import { SET_CUSTOMER_READY_POP_UPS } from 'actions/actionTypes';

export function* updateCustomerReadyPopUps(action) {
  yield put({
    type: SET_CUSTOMER_READY_POP_UPS,
    payload: {
      customerReadyPopUps: action.payload.customerReadyPopUps || [],
    },
  });
}
