import { call, select } from 'redux-saga/effects';
import {
  uploadCustomersFile,
  uploadStoresFile,
  uploadOptedOutPhonesFile,
} from 'actions/apis/import';
import { getClientId } from 'selectors/session';

export function* importCustomersFile(action) {
  const clientId = yield select(getClientId);

  return yield call(uploadCustomersFile, { clientId, ...action.payload });
}

export function* importStoresFile(action) {
  const clientId = yield select(getClientId);

  return yield call(uploadStoresFile, { clientId, ...action.payload });
}

export function* importOptedOutPhonesFile(action) {
  const clientId = yield select(getClientId);

  return yield call(uploadOptedOutPhonesFile, { clientId, ...action.payload });
}
