import React from 'react';
import { useSelector } from 'react-redux';
import { CURBSPOT_DELIVERY} from 'constants/defaults';
import { getInstructions } from 'utils/stringUtils';
import { getSessionLanguage } from 'selectors/session';
import { FormattedMessage } from 'react-intl';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsInfo from './OrderDetailsInfo';
import OrderDetailsPickupLocation from './OrderDetailsPickupLocation';
import OrderDetailsReadyForPickup from './OrderDetailsReadyForPickup';
import OrderDetailsContentWrapper from './OrderDetailsContentWrapper';
import OrderDetailsDeliveryInstructions from './OrderDetailsDeliveryInstructions';
import { useIntl } from 'react-intl';

interface ComponentProps {
  order: any;
  store: any;
}

const OrderDetailsS1CurbsideOnly: React.FC<ComponentProps> = ({
  order,
  store,
}) => {
  const intl = useIntl();

  const curbspot = store.deliveryMethods.find(
    (method: any) => method.deliveryMethod.id === CURBSPOT_DELIVERY
  );

  const curbspotInstructions = curbspot.instructions || curbspot.deliveryMethod.instructions;

  const sessionLanguage = useSelector(getSessionLanguage);
  const language = sessionLanguage || order.orderLanguage;
  const curbspotInstructionsDecomposed = getInstructions(language, curbspotInstructions);

  return (
    <>
      <OrderDetailsHeader order={order} />
      <OrderDetailsContentWrapper>
        <OrderDetailsInfo
          title={
            <FormattedMessage
              id="order.s1.curbside.title"
              defaultMessage='Arrived at the store?'
            />
          }
          subtitle={
            <FormattedMessage
              id="order.s1.curbside.subtitle"
              defaultMessage='Please let our team members know how we can find you by adding your details below.'
            />
          }
        />
      </OrderDetailsContentWrapper>
      <OrderDetailsContentWrapper>
        <div className="inner-content-wrapper">
          {curbspotInstructionsDecomposed ?
            <OrderDetailsDeliveryInstructions
              title={
                intl.formatMessage({
                  id: 'order.s1.curbspot_instructions.title',
                  defaultMessage: 'Instructions for Curbside pickup'
                })
              }
              instructions={curbspotInstructionsDecomposed}
            />
          : null}
        </div>
        <OrderDetailsReadyForPickup />
      </OrderDetailsContentWrapper>
      <OrderDetailsPickupLocation />
    </>
  );
};

export default OrderDetailsS1CurbsideOnly;
