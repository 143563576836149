import React from 'react';
import { IonIcon } from '@ionic/react';
import { checkmark, checkmarkDone } from 'ionicons/icons';
import {
  ORDER_STATE_PENDING_PICKUP,
  ORDER_STATE_OUT_FOR_CURBSIDE,
} from 'constants/orderStates';
interface NotificationDetailsProps {
  notificationType: any;
  stateName: any;
  lastNotification: any;
}
const NotificationDetails: React.FC<NotificationDetailsProps> = ({
  stateName,
  lastNotification,
  notificationType,
}) => {
  const notificationsentMessage = `${notificationType} sent`;
  const notificationViewedMessage = `${notificationType} viewed`;
  return (
    <>
      {stateName === ORDER_STATE_PENDING_PICKUP && (
        <>
          {lastNotification.executed ? (
            <IonIcon
              icon={checkmarkDone}
              color="success"
              className="notification-status-icon"
              title={notificationViewedMessage}
            />
          ) : (
            lastNotification.sent && (
              <IonIcon
                icon={checkmark}
                color="primary"
                className="notification-status-icon"
                title={notificationsentMessage}
              />
            )
          )}
        </>
      )}
    </>
  );
};

export default NotificationDetails;
