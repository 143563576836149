import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IonIcon } from '@ionic/react';
import { warning } from 'ionicons/icons';

import './CP404.scss';

const CP404 = () => {
  return (
    <div className="cp-404 center">
      <IonIcon color="dark" className="cp-404-icon" icon={warning} />
      <h1 className="cp-404-title">404</h1>
      <p className="default-p cp-404-subtitle">
        <FormattedMessage
          id="404.subtitle"
          defaultMessage="If the error persists, contact the store."
        />
      </p>
    </div>
  );
};
export default CP404;
