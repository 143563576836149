export const getOrdersRoot = (state: any) => state.orders || {};

export const getOrders = (state: any) => {
  return getOrdersRoot(state).orders || {};
};

export const getOrder = (state: any) => (orderId: string) => {
  const orders = getOrders(state) || {};
  return orders[orderId] || {};
};

export const getFilteredOrders = (state: any) => (
  status: string,
  searchText?: string
) => {
  const orders = getOrders(state) || {};
  const lowerText = searchText && searchText.toLowerCase();

  return Object.keys(orders)
    .filter((item: any) => orders[item].state.stateName === status)
    .filter((item: any) =>
      searchText
        ? orders[item].externalId.toLowerCase().indexOf(lowerText) !== -1 ||
          (orders[item].customerInfo.customerEmail &&
            orders[item].customerInfo.customerEmail
              .toLowerCase()
              .indexOf(lowerText) !== -1) ||
          (orders[item].customerInfo.customerPhone &&
            orders[item].customerInfo.customerPhone
              .toLowerCase()
              .indexOf(lowerText) !== -1)
        : true
    )
    .reduce((Orderlist, item) => {
      Orderlist = Orderlist.concat(orders[item]);
      return Orderlist;
    }, []);
};
export const getRequestFilters = (state: any) => {
  return getOrdersRoot(state).filters || {};
};
export const getCurbPollingPaused = (state: any) => {
  return getOrdersRoot(state).pauseCurbPolling;
};
export const getCustomerOrder = (state: any) =>
  getOrdersRoot(state).currentOrder;

export const getPickupRetry = (state: any) =>
  getOrdersRoot(state).isRetryingPickup;

export const getCustomerReadyNotifications = (state: any) => {
  return getOrdersRoot(state).customerReadyPopUps || [];
};
