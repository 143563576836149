import React, { useContext, useState, useEffect } from 'react';
import { IonIcon, IonModal, IonContent } from '@ionic/react';
import './styles.scss';
import { getViewPort } from 'utils/getViewPort';
import { ViewPortContext } from 'contexts';
import { UPDATE_CUSTOMER_READY_POP_UPS } from 'actions/actionTypes';
import { StoreContext } from 'contexts';

import PopupList from './subcomponents/PopupList';
import Anchor from 'components/DesignSystem/Anchor';
interface ICUSTOMERPOPUPProps {
  customerReadyNotifications?: any;
  setExpandColumn: any;
}
const CustomerReadyPopups: React.FC<ICUSTOMERPOPUPProps> = ({
  customerReadyNotifications,
  setExpandColumn,
}) => {
  const { action } = useContext(StoreContext);
  const { width } = useContext(ViewPortContext);
  const { isMobile } = getViewPort(width);
  const [prevNotifications, setPrevNotifications] = useState([]);
  const [viewUpdates, setViewUpdates] = useState(false);
  const [showMobilePopup, setShowMobilePopup] = useState(isMobile);

  const closePopup = (id: any) => {
    // Avoid mutating the initial array
    const updatedList = customerReadyNotifications.filter(
      (notificationId: string) => notificationId !== id
    );

    action(UPDATE_CUSTOMER_READY_POP_UPS, {
      customerReadyPopUps: updatedList,
    });
  };
  const onViewOrderClick = (event: Event, orderId: string) => {
    event.preventDefault();
    setViewUpdates(false);
    setExpandColumn(true);
    // Scroll to the card position after 1 sec to make sure modal closes and Column expands
    setTimeout(function () {
      const targetCard = document.getElementById(`card-${orderId}`);
      // debugger;
      if (targetCard) {
        targetCard.scrollIntoView();
      }
    }, 1000);

    return false;
  };

  useEffect(() => {
    // If the notifications are empty, don't show the mobile popup
    if (!customerReadyNotifications.length || showMobilePopup) {
      // Make sure we update the prev is updated
      setPrevNotifications(customerReadyNotifications);

      return;
    }

    // In case the pop up was hidden, we need to ensure
    // we'll show it again in case our notifications have changed
    const hasChanged = !customerReadyNotifications.every(
      (notification: never) => prevNotifications.indexOf(notification) > -1
    );

    // If the notifications have changed, pop up the mobile
    // and update the latest version of notifications
    if (hasChanged) {
      setShowMobilePopup(true);
    }

    // Make sure we update the prev is updated
    setPrevNotifications(customerReadyNotifications);
  }, [customerReadyNotifications, prevNotifications]);

  // If we don't have notifications, we don't need to render the component
  if (!customerReadyNotifications.length) {
    return null;
  }

  return (
    <div className="notification-panel">
      {isMobile && showMobilePopup && (
        <>
          <div className="popup-card d-flex ion-justify-content-between">
            <p className="mobile-help-text">
              One or more orders have been moved to <br /> “Customer Ready”{' '}
              <Anchor
                href="#"
                onClick={(event: Event) => {
                  event.preventDefault();
                  setViewUpdates(true);
                }}
              >
                View Updates
              </Anchor>
            </p>
            <Anchor
              href="#"
              className="popup-card-mobile-close"
              onClick={(event: Event) => {
                event.preventDefault();
                setShowMobilePopup(false);
              }}
            >
              <IonIcon src="/assets/icon/whiteCross.svg" />
            </Anchor>
          </div>
          <IonModal
            cssClass="view-updates-modal-container"
            isOpen={viewUpdates}
            onDidDismiss={() => setViewUpdates(false)}
            backdropDismiss={true}
            keyboardClose={false}
          >
            <IonContent>
              <div className="view-updates-modal">
                <div className="order-update-header d-flex ion-justify-content-between">
                  <h5 className="heading">Order Updates</h5>
                  <IonIcon
                    src="/assets/icon/close-outline.svg"
                    onClick={() => setViewUpdates(false)}
                    className="modal-close-icon"
                    name="close"
                    icon="close"
                  />
                </div>
                <div className="mobile-popup-list">
                  <PopupList
                    customerReadyNotifications={customerReadyNotifications}
                    closePopup={closePopup}
                    onViewOrderClick={onViewOrderClick}
                  />
                </div>
              </div>
            </IonContent>
          </IonModal>
        </>
      )}

      {!isMobile && (
        <PopupList
          customerReadyNotifications={customerReadyNotifications}
          closePopup={closePopup}
          onViewOrderClick={onViewOrderClick}
        />
      )}
    </div>
  );
};

export default CustomerReadyPopups;
