import React from 'react';

import './Anchor.scss';

const Anchor = ({ variant, className, children, ...otherProps }: any = {}) => {
  const classNames = [];

  // If a specific variant has not been provided,
  // add the default style
  if (!variant) {
    classNames.push('default-link');
  }

  // Append additional
  if (className) {
    classNames.push(className);
  }

  return (
    <a className={classNames.join(' ')} {...otherProps}>
      {children}
    </a>
  );
};

export default Anchor;
