import React, { useContext, useState, useEffect } from 'react';
import './UnifiedOrderForm.scss';
import { useForm, Controller } from 'react-hook-form';
import { IonInput, IonLabel, IonButton, IonText, IonTextarea } from '@ionic/react';
import { StoreContext } from 'contexts';
import { ADD_ORDER, UPDATE_ORDER_DETAILS } from 'actions/actionTypes';
import { normalizeFormInput } from 'utils/formUtils';
import { getSupportedCountries, getAvailableLanguages } from 'selectors/client';
import Loader from 'components/DesignSystem/Loader';
import { useSelector } from 'react-redux';
import PhoneAreaCodeDropdown from '../CP-PhoneAreaCodeDropdown';
import LanguageDrowpdown from '../CPLanguageDropdown';
import { getSessionLanguage } from 'selectors/session';
import { useIntl } from 'react-intl';

interface ContainerProps {
  closeModal: Function;
  isOrderUpdateForm: boolean;
  order: any;
}
type Inputs = {
  orderLanguage: string,
  orderNumber: string;
  emailId: string;
  phoneNumber: string;
  binLocation?: any;
  shipmentId?: any;
};

/**
 * Normalizes form input data
 *
 * @param data Form input values
 * @returns form data with normalized field values
 */
const getNormalizedForm = (data: any) => {
  return {
    orderLanguage: normalizeFormInput(data.locale),
    orderNumber: normalizeFormInput(data.orderNumber),
    phoneNumber: normalizeFormInput(data.phoneNumber),
    emailId: normalizeFormInput(data.emailId),
    binLocation: normalizeFormInput(data.binLocation),
    shipmentId: normalizeFormInput(data.shipmentId),
  };
};

const UnifiedOrderForm: React.FC<ContainerProps> = ({
  closeModal,
  isOrderUpdateForm,
  order,
}) => {
  const { control, errors, handleSubmit, setValue, setError } = useForm<
    Inputs
  >();
  const { action, notify } = useContext(StoreContext);
  const supportedCountries = useSelector(getSupportedCountries);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const { externalId, customerInfo, orderLanguage, binLocation, shipmentId } = order;
  const storeCurrentLanguage = useSelector(getSessionLanguage);
  const language = orderLanguage || storeCurrentLanguage;
  const [selectedLanguage, setSelectedLanguage] = useState<any>(language);
  const intl = useIntl();

  const isFormValid = (data: any) => {
    if (data.orderNumber && (data.phoneNumber || data.emailId)) {
      return true;
    } else {
      if (!data.orderNumber) {
        setError('orderNumber', 'required');
      }

      if (!data.emailId && !data.phoneNumber) {
        setError('emailId', 'required');
        setError('phoneNumber', 'required');
      }

      return false;
    }
  };
  const createOrder = (form: any) => {
    const data = getNormalizedForm(form);
    // Format request to the API payload
    if (isFormValid(data)) {
      const payload = {
        externalId: data.orderNumber,
        orderLanguage: selectedLanguage,
        customerInfo: {
          customerPhone: data.phoneNumber,
          customerEmail: data.emailId,
          customerPhoneCountryCode: selectedCountry,
        },
        binLocation: data.binLocation,
        shipmentId: data.shipmentId,
      };
      setSubmitting(true);
      action(ADD_ORDER, payload)
        .then((res: any) => {
          notify({ message: intl.formatMessage({ id: 'notifications.orderAddedSuccess', defaultMessage: 'Order Added Successfully!' }), color: 'success' });
          setSubmitting(false);
          closeModal();
        })
        .catch((error: any) => {
          if (error.status === 400) {
            if (error.data.message === 'Phone number is invalid') {
              notify({ message: intl.formatMessage({ id: 'notifications.invalidPhone', defaultMessage: 'Phone number is invalid' }), color: 'warning' });
              setSubmitting(false);
            } else if (
              error.data.message ===
              intl.formatMessage({ id: 'notifications.cannotCreateOrderStoreLimit', defaultMessage: 'Can not create order because store daily order limit' })
            ) {
              notify({
                message:
                intl.formatMessage({ id: 'notifications.ordersPerDayExceeded', defaultMessage: 'Unable to create order: maximum number of orders per day exceeded!' }),
                color: 'danger',
              });
            } else {
              notify({
                message: intl.formatMessage({ id: 'notifications.orderExists', defaultMessage: 'Order ID already exist' }),
                color: 'danger',
              });
            }
          } else {
            notify({ message: intl.formatMessage({ id: 'notifications.orderFailedSubmit', defaultMessage: 'Failed to submit order.' }), color: 'danger' });
          }
          setSubmitting(false);
          closeModal();
        });
    }
  };

  const updateOrderDetails = (form: any) => {
    const data = getNormalizedForm(form);

    if (isFormValid(data)) {
      let body = {
        externalId: data.orderNumber,
        orderLanguage: selectedLanguage,
        customerInfo: {
          customerEmail: data.emailId,
          customerPhone: data.phoneNumber,
          customerPhoneCountryCode: selectedCountry,
        },
        clientInfo: order.clientInfo,
        binLocation: data.binLocation,
        shipmentId: data.shipmentId,
      };

      setSubmitting(true);
      action(UPDATE_ORDER_DETAILS, {
        body,
        orderId: order.id,
      }).then((res: any) => {
        if (res.status === 200) {
          setSubmitting(false);
          closeModal();
          notify({
            message: intl.formatMessage({ id: 'notifications.orderUpdateSuccess', defaultMessage: 'Order Update successfully!' }),
            color: 'success',
          });
        } else if (res.status === 400) {
          if (res.data.message === 'Phone number is invalid') {
            notify({ message: intl.formatMessage({ id: 'notifications.invalidPhoneNumber', defaultMessage: 'Phone number is invalid' }), color: 'warning' });
            setSubmitting(false);
          } else {
            setSubmitting(false);
            closeModal();
            notify({
              message: intl.formatMessage({ id: 'notifications.uniqueOrderId', defaultMessage: 'Order ID should be unique!' }),
              color: 'danger',
            });
          }
        } else {
          setSubmitting(false);
          closeModal();
          notify({
            message: intl.formatMessage({ id: 'notifications.updateOrderFailed', defaultMessage: 'Failed to update order!' }),
            color: 'danger',
          });
        }
      });
    }
  };

  useEffect(() => {
    setSelectedCountry(
      customerInfo && customerInfo.customerPhoneCountryCode
        ? customerInfo.customerPhoneCountryCode
        : supportedCountries[0].countryCode
    );
  }, [customerInfo, supportedCountries]);

  return (
    <div className="unified-order-form-wrapper">
      <IonText color="dark">
        <h1 className="unified-order-modal-header">
          {isOrderUpdateForm ?
          intl.formatMessage({ id: 'orderForm.updateOrder', defaultMessage: 'Update Order' }) :
          intl.formatMessage({ id: 'orderForm.addOrder', defaultMessage: 'Add Order' })}
        </h1>
      </IonText>
      <form
        onSubmit={handleSubmit((data) =>
          isOrderUpdateForm ? updateOrderDetails(data) : createOrder(data)
        )}
      >
        <div className="item-wrapper">
          <IonLabel className="uppercase">{intl.formatMessage({ id: 'orderForm.orderLanguage', defaultMessage: 'ORDER LANGUAGE' })}</IonLabel>
          <LanguageDrowpdown
            supportedLanguages={getAvailableLanguages(language)}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            customClassName="languages-dropdown"
          />
        </div>
        <div className="item-wrapper">
          <IonLabel className="uppercase">{intl.formatMessage({ id: 'orderForm.orderNumber', defaultMessage: 'ORDER NUMBER' })}</IonLabel>
          <Controller
            name="orderNumber"
            as={IonInput}
            placeholder=""
            type="text"
            control={control}
            defaultValue={isOrderUpdateForm ? externalId : ''}
            onIonChange={(e: any) => setValue('orderNumber', e.detail.value)}
          />
          <span className="error-feedback">
            {errors.orderNumber && 'Order number is required'}
          </span>
        </div>
        <div className="item-wrapper">
          <IonLabel className="uppercase">{intl.formatMessage({ id: 'orderForm.email', defaultMessage: 'EMAIL ADDRESS' })}</IonLabel>
          <Controller
            name="emailId"
            as={IonInput}
            placeholder=""
            type="email"
            rules={{
              pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            }}
            control={control}
            defaultValue={
              customerInfo && customerInfo.customerEmail !== null
                ? customerInfo.customerEmail
                : ''
            }
            onIonChange={(e: any) => setValue('emailId', e.detail.value)}
          />
          <span className="error-feedback">
            {errors.emailId &&
              errors.emailId.type === 'pattern' &&
              'Email is not valid'}
            {errors.emailId &&
              errors.emailId.type === 'required' &&
              'Either email or phone is required.'}
          </span>
        </div>
        <div className="item-wrapper">
          <IonLabel className="uppercase">{intl.formatMessage({ id: 'orderForm.phoneNumber', defaultMessage: 'PHONE NUMBER' })}</IonLabel>
          <div className="d-flex">
            <PhoneAreaCodeDropdown
              supportedCountries={supportedCountries}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
            <Controller
              name="phoneNumber"
              as={IonInput}
              placeholder=""
              type="tel"
              control={control}
              defaultValue={
                customerInfo && customerInfo.customerPhone !== null
                  ? customerInfo.customerPhone
                  : ''
              }
              onIonChange={(e: any) => setValue('phoneNumber', e.detail.value)}
            />
          </div>

          <span className="error-feedback">
            {errors.phoneNumber &&
              errors.phoneNumber.type === 'pattern' &&
              'Phone number is not valid'}
            {errors.phoneNumber &&
              errors.phoneNumber.type === 'required' &&
              'Either email or phone is required.'}
          </span>
        </div>
        <div className="d-flex cta-wrapper">
          <IonButton
            className="button-secondary"
            color="light"
            size="large"
            mode="md"
            shape={undefined}
            disabled={isSubmitting}
            onClick={() => closeModal()}
          >{intl.formatMessage({
            id: 'orderForm.cancel',
            defaultMessage: 'CANCEL'
          })}</IonButton>
          <IonButton
            className="button-primary"
            color="dark"
            size="large"
            mode="md"
            shape={undefined}
            disabled={isSubmitting}
            type="submit"
            buttonType="button"
          >
            {isSubmitting ? (
              <Loader withMinHeight={false} />
            ) : !isOrderUpdateForm ? (
              intl.formatMessage({ id: 'orderForm.addOrder', defaultMessage: 'ADD ORDER' })
            ) : (
              intl.formatMessage({ id: 'orderForm.updateOrder', defaultMessage: 'Update order' })
            )}
          </IonButton>
        </div>
      </form>
    </div>
  );
};
export default UnifiedOrderForm;
