import { NOTIFY } from 'actions/actionTypes';
import { defaultReducer, unknownReducer } from './utils';

import type { Action } from 'redux';

export type SessionState = {
  message: string;
  duration?: number;
};

const reducerMap: any = {
  [NOTIFY]: defaultReducer,
};

export default (state = {}, action: Action) => {
  const reducer = reducerMap[action.type] || unknownReducer;

  return reducer(state, action);
};
