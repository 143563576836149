import React from 'react';
import OrderDetailsOrderDelivered from './OrderDetailsOrderDelivered';

interface ComponentProps {}

const OrderDetailsS5: React.FC<ComponentProps> = () => {
  return <OrderDetailsOrderDelivered />;
};

export default OrderDetailsS5;
