import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { put, select } from 'redux-saga/effects';
import { API_BASE_URL } from 'configs/config';
import { getEndpointMeta } from 'constants/endpoints';
import { getToken } from 'selectors/session';
import { normalizePath, normalizeQuery } from 'utils/requestUtils';
import { LOGOUT_SAGA } from 'actions/actionTypes';

interface RequestParams extends AxiosRequestConfig {
  queryParams?: { [key: string]: string };
  pathParams?: { [key: string]: string };
  headers?: { [key: string]: string };
  body?: any;
  // Data overwrites
  token?: string;
  encodeQuery?: boolean;
  redirectOn401?: AxiosResponse<any> | any;
}

export function defaultResponseInterceptor(
  response: AxiosResponse
): AxiosResponse<any> {
  return response;
}

export function* request(
  endpointId: string,
  {
    queryParams = {},
    pathParams,
    headers = {},
    body,
    ...additionalProps
  }: RequestParams
) {
  const endpoint = getEndpointMeta(endpointId);

  if (!endpoint) {
    throw Error(`Endpoint ${endpointId} is not mapped.`);
  }

  const instance = axios.create({
    baseURL: API_BASE_URL,
  });

  // If the endpoint is authenticated, we must get the auth token
  if (endpoint.auth) {
    const token = yield select(getToken);

    // Check if the request has a
    headers.Authorization = `${additionalProps.token || token}`;
  }

  let response;

  // Send the request to the API
  try {
    response = yield instance.request({
      method: endpoint.method,
      url: normalizePath(endpoint.path, pathParams),
      params: normalizeQuery(queryParams, additionalProps.encodeQuery),
      data: body,
      headers,
    });
  } catch (error) {
    response = (error && error.response) || { data: {} };
  }

  // Check if we have to logout on failure
  const { redirectOn401 = endpoint.redirectOn401 } = additionalProps;

  if (response.status === 401 && redirectOn401) {
    // Take the customer to the login page
    yield put({ type: LOGOUT_SAGA });
  }

  return response;
}
