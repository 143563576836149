import React, { useContext, useState, useEffect } from 'react';
import './OrderDetails.scss';
import {
  IonLabel,
  IonButton,
  IonText,
  IonTextarea,
  IonIcon,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import { StoreContext } from 'contexts';
import { UPDATE_ORDER, UPDATE_ORDER_DETAILS } from 'actions/actionTypes';
import {
  ORDER_STATE_OUT_FOR_CURBSIDE,
  EVENT_DELIVERED,
  EVENT_CUSTOMER_READY,
  EVENT_CUSTOMER_NOT_FOUND,
  EVENT_UPDATED_CUSTOMER_INFO,
} from 'constants/orderStates';
import { DISPATCHER_ROLE } from 'constants/defaults';
import Loader from 'components/DesignSystem/Loader';
import Anchor from 'components/DesignSystem/Anchor';
import { lineBreakToBR } from 'utils/stringUtils';
import { useIntl } from 'react-intl';

interface ContainerProps {
  currentOrder: any;
  role: any;
  closeModal: Function;
}
const OrderDetails: React.FC<ContainerProps> = ({
  currentOrder,
  role,
  closeModal,
}) => {
  const [isEditableNote, setIsEditableNote] = useState<boolean>(false);
  const [orderNote, setOrderNote] = useState<string>('');
  const { action, notify } = useContext(StoreContext);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [eventType, setEventType] = useState<string>();
  const intl = useIntl();

  const {
    id,
    externalId,
    state: { stateName },
    note,
    binLocation,
  } = currentOrder;

  // Check if we have an event with location description
  // We must create a copy to avoid mutating the events list
  const allEvents = [...currentOrder.events];

  const lastEvent = allEvents
    .reverse() // Reverse to find from the latest events
    .find((event: any) => {
      return (
        event.type === EVENT_CUSTOMER_READY ||
        event.type === EVENT_UPDATED_CUSTOMER_INFO
      );
    });

  const customerDescription = lastEvent && lastEvent.locationDescription;

  const updateOrder = (type: string) => {
    setEventType(type);
    setSubmitting(true);

    action(UPDATE_ORDER, {
      type,
      orderId: id,
    })
      .then((data: any) => {
        setSubmitting(false);
        setEventType('');
        notify({
          message: intl.formatMessage({ id: 'notifications.updatedSuccess', defaultMessage: 'Updated successfully!' }),
          color: 'success',
        });
      })
      .catch((e: any) => {
        setSubmitting(false);
        setEventType('');
        notify({
          message: intl.formatMessage({ id: 'notifications.updatedFail', defaultMessage: 'Failed to update!' }),
          color: 'danger',
        });
      });
  };

  const addOrderNote = (orderNote: string) => {
    const body = {
      ...currentOrder,
      note: orderNote,
    };
    action(UPDATE_ORDER_DETAILS, {
      orderId: id,
      body,
    }).then((res: any) => {
      if (res.status === 200) {
        closeModal();
        notify({
          message: intl.formatMessage({ id: 'notifications.orderNoteUpdated', defaultMessage: 'Order Note updated Successfully!' }),
          color: 'success',
        });
        setIsEditableNote(false);
      }
    });
  };

  useEffect(() => {
    setOrderNote(note);
  }, [note]);

  if (!currentOrder) {
    return null;
  }

  return (
    <div className="order-details-wrapper">
      <IonText color="dark">
        <h1 className="header-note">{intl.formatMessage({ id: 'dashboard.orderDetails.modal.title', defaultMessage: 'Order Details' })}</h1>
        <p className="sub-header">
          {intl.formatMessage({ id: 'dashboard.orderDetails.modal.description', defaultMessage: 'Please verify the clients order number and description' })}
        </p>
        <IonLabel className="order-number-label">{intl.formatMessage({ id: 'dashboard.orderDetails.modal.orderNumber', defaultMessage: 'Order Number' })}</IonLabel>
        <h3 className="order-number">{externalId}</h3>

        <IonLabel className="description-label">{intl.formatMessage({ id: 'dashboard.orderDetails.modal.binLocationLabel', defaultMessage: 'BIN Location' })}</IonLabel>
        <p className="description">{binLocation || '-'}</p>

        <IonLabel className="description-label">{intl.formatMessage({ id: 'dashboard.orderDetails.modal.clientDescriptionTitle', defaultMessage: `Client’s Description` })}</IonLabel>
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: lineBreakToBR(customerDescription) || '-',
          }}
        />
      </IonText>

      <IonLabel className="description-label comment-label">{intl.formatMessage({ id: 'dashboard.orderDetails.modal.comments.title', defaultMessage: 'Comments' })}</IonLabel>
      {role === DISPATCHER_ROLE && !isEditableNote && (
        <Anchor
          href="#"
          className="edit-comment-icon"
          onClick={(e: Event) => {
            e.preventDefault();
            setIsEditableNote(true);
          }}
        >
          {orderNote ? (
            intl.formatMessage({ id: 'dashboard.orderDetails.modal.editComment', defaultMessage: 'Edit Comment' })
          ) : (
            <>
              <IonIcon icon={add}></IonIcon>{intl.formatMessage({ id: 'dashboard.orderDetails.modal.addComment', defaultMessage: 'Add Comment' })}
            </>
          )}
        </Anchor>
      )}
      {isEditableNote ? (
        <>
          <IonTextarea
            className="order-note"
            value={orderNote}
            onIonChange={(e: any) => setOrderNote(e.detail.value)}
          ></IonTextarea>
          <div className="d-flex cta-wrapper">
            <IonButton
              className="button-secondary"
              color="light"
              size="large"
              mode="md"
              disabled={isSubmitting}
              shape={undefined}
              onClick={() => setIsEditableNote(false)}
            >
              {intl.formatMessage({ id: 'dashboard.orderDetails.modal.cancel', defaultMessage: 'Cancel' })}
            </IonButton>
            <IonButton
              className="button-primary"
              color="dark"
              size="large"
              mode="md"
              disabled={isSubmitting}
              shape={undefined}
              onClick={() => {
                addOrderNote(orderNote);
              }}
            >
              {isSubmitting ? (
                <Loader withMinHeight={false} />
              ) : (
                intl.formatMessage({ id: 'dashboard.orderDetails.modal.updateDetails', defaultMessage: 'Update Details' })
              )}
            </IonButton>
          </div>
        </>
      ) : (
        <p
          className="description note-text"
          dangerouslySetInnerHTML={{
            __html: lineBreakToBR(note) || intl.formatMessage({ id: 'dashboard.orderDetails.modal.noComments', defaultMessage: 'No Comments' }),
          }}
        />
      )}

      {stateName === ORDER_STATE_OUT_FOR_CURBSIDE && (
        <>
          <div className="d-flex cta-wrapper">
            <IonButton
              className="button-secondary"
              color="light"
              size="large"
              mode="md"
              disabled={isSubmitting}
              shape={undefined}
              onClick={() => updateOrder(EVENT_CUSTOMER_NOT_FOUND)}
            >
              {isSubmitting && eventType === EVENT_CUSTOMER_NOT_FOUND ? (
                <Loader color="dark" withMinHeight={false} />
              ) : (
                intl.formatMessage({ id: 'dashboard.orderDetails.modal.notFound', defaultMessage: 'Customer Not Found' })
              )}
            </IonButton>
            <IonButton
              className="button-primary"
              color="dark"
              size="large"
              mode="md"
              disabled={isSubmitting}
              shape={undefined}
              onClick={() => updateOrder(EVENT_DELIVERED)}
            >
              {isSubmitting && eventType === EVENT_DELIVERED ? (
                <Loader withMinHeight={false} />
              ) : (
                intl.formatMessage({ id: 'dashboard.orderDetails.modal.orderDelivered', defaultMessage: 'Order Delivered' })
              )}
            </IonButton>
          </div>
        </>
      )}
    </div>
  );
};
export default OrderDetails;
