import React from 'react';

import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonFabList,
  IonList,
  IonLabel,
  IonItem,
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';

const UploadPhoneOptedOut = () => (
  <IonFab className="help-fab" vertical="top" horizontal="end" slot="fixed">
    <IonFabButton size="small">
      <IonIcon icon={helpCircleOutline} />
    </IonFabButton>
    <IonFabList>
      <IonList className="help-list" lines="none">
        <IonItem className="help-list-item">
          <IonLabel className="help-list-text">
            Provide a list of phone numbers that you want to opt-out from
            Curbspot.
          </IonLabel>
        </IonItem>
      </IonList>
    </IonFabList>
  </IonFab>
);

export default UploadPhoneOptedOut;
