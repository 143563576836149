export default {
  '404.subtitle': `Si l'erreur persiste, contactez le magasin.`,

  /* -- Footer -- */
  'footer.powered_by': 'Alimenté Par',
  'footer.all_rights_reserved': 'Tous droits réservés.',
  'footer.terms_and_conditions': 'Conditions Générales',
  'footer.privacy_policy': 'Politique de Confidentialité',

  /* Header */
  'header.storeDescription': 'MAGASIN#',
  'header.thisStoreDescription': 'Ce magasin est',
  'header.enabledDescription': 'activé.',
  'header.andDescription': 'et',
  'header.logout': 'Déconnexion',

  /* Dashboard */
  'dashboard.addOrder': 'Ajouter une commande',
  'dashboard.filter.button.label': `Filtrer l'ordre par date`,
  'dashboard.filter.placeholder': 'Ordres de Recherche',

  'dashboard.orderReceivedColumn': 'Ordre reçu',
  'dashboard.readyForPickupColumn': 'Prêt pour le ramassage',
  'dashboard.customerWaitingOutside': `Client en attente à l'extérieur`,
  'dashboard.outForCurbside': `À l'extérieur pour Curbside`,
  'dashboard.deliveredToday': `Livré aujourd'hui`,

  /* Order card */
  'orderCard.orderNumber': 'Numéro de commande',
  'orderCard.added': 'Ajoutée',
  'orderCard.wait': 'Attendre',
  'orderCard.orderLanguage': 'Langue de commande',
  'orderCard.readyForPickup': 'Prêt pour le ramassage',
  'orderCard.outForCurbside': `À l'extérieur pour Curbside`,
  'orderCard.orderDelivered': 'Commande livrée',
  'orderCard.confirmReadyForPickup': 'Confirmer prêt pour le ramassage!',
  'orderCard.confirmDelete': 'Confirmation de la suppression!',
  'orderCard.confirmEnsure': 'Êtes-vous sûr?',
  'orderCard.cancel': 'Résilier',
  'orderCard.ok': `D'accord`,

  /* MODALS */
  'orderForm.shippmentId': `ID d'expédition`,
  'orderForm.cancel': 'Résilier',
  'orderForm.addOrder': 'Ajouter le Commande',
  'orderForm.updateOrder': 'Commande de mise à jour',
  'orderForm.orderLanguage': 'Langue de commande',
  'orderForm.orderNumber': 'Numéro de commande',
  'orderForm.email': 'Adresse e-mail',
  'orderForm.phoneNumber': 'Numéro de téléphone',
  'orderForm.binLocation': 'Emplacement du bac',
  'orderForm.binlocation.placeholder': `Informer l'emplacement du bac`,
  'dashboard.orderDetails.modal.title': 'Détails de la Commande',
  'dashboard.orderDetails.modal.description': 'Veuillez vérifier le numéro de commande et la description du client',
  'dashboard.orderDetails.modal.orderNumber': 'Numéro de Commande',
  'dashboard.orderDetails.modal.clientDescriptionTitle': 'Description du Client',
  'dashboard.orderDetails.modal.comments.title': 'Commentaires',
  'dashboard.orderDetails.modal.addComment': 'Ajouter un Commentaire',
  'dashboard.orderDetails.modal.editComment': 'Modifier le Commentaire',
  'dashboard.orderDetails.modal.cancel': 'Annuler',
  'dashboard.orderDetails.modal.updateDetails': 'Mettre à Jour les Détails',
  'dashboard.orderDetails.modal.noComments': 'Sans commentaires',
  'dashboard.orderDetails.modal.notFound': 'Client introuvable',
  'dashboard.orderDetails.modal.orderDelivered': 'Commande Livrée',
  'dashboard.orderDetails.modal.shipmentIdLabel': `ID d'expédition`,
  'dashboard.orderDetails.modal.binLocationLabel': 'Emplacement du bac',

  /* -- Order -- */
  'order.order': 'Commande',

  // pickup location
  'order.pickup_location.title': 'Lieu de ramassage',
  'order.pickup_location.my_store': 'Mon Magasin',
  'order.pickup_location.map_link': 'Les directions',
  'order.pickup_location.store_link': 'Informations sur le Magasin',

  // S1
  // pickup form
  'order.s1.pickup_form.label': `Détails de l'emplacement`,
  'order.s1.pickup_form.placeholder':
    'Indiquez-nous de votre emplacement, de la marque et du modèle du véhicule ou de tout autre détail utile.',
  'order.s1.pickup_form.button': 'Je suis ici',
  'order.s1.pickup_form.send_error_message': 'Veuillez fournir des détails sur votre emplacement pour nous aider à vous trouver.',

  // bopis instructions
  'order.s1.bopis_instructions.title': 'Instructions pour le ramassage en magasin',
  'order.s1.curbspot_instructions.title': 'Instructions pour le ramassage Curbside',

  // bopis + curbside
  'order.s1.bopiscurbside.title': 'Votre commande est prête{linebreak}pour le ramassage',
  'order.s1.bopiscurbside.subtitle':
    'Vous pouvez recevoir votre commande de manière pratique{linebreak}via un ramassage en magasin ou en bordure de trottoir',
  'order.s1.bopiscurbside.curbside_instructions.title': 'Instructions pour le ramassage en bordure de trottoir',
  'order.s1.bopiscurbside.curbside_instructions.instructions': 'Veuillez informer notre équipe de votre emplacement',

  // bopis
  'order.s1.bopis.title': 'Votre commande est prête{linebreak}pour le ramassage',
  'order.s1.bopis.subtitle':
    'Vous pouvez récupérer votre commande en marchant dans notre magasin et en vous dirigeant vers notre station de ramassage.',
  'order.s1.bopis.additional_desc':
    `Si vous avez de la difficulté à trouver la station de ramassage, veuillez en informer l’un de nos membres de l'équipe.`,

  // curbside
  'order.s1.curbside.title': 'Arrivé au magasin?',
  'order.s1.curbside.subtitle':
    `Veuillez indiquer à nos membres de l'équipe comment nous pouvons vous trouver en ajoutant vos coordonnées ci-dessous.`,

  // S1A
  'order.s1a.title': 'Heureux de vous voir ici',
  'order.s1a.subtitle1': 'Nous serons avec vous dans environ 5 à 15 minutes.',
  'order.s1a.subtitle2': 'Si vous rencontrez des problèmes, veuillez contacter notre magasin au',

  // S3
  'order.s3.title': 'Nous sommes en route!',
  'order.s3.subtitle': 'En cas de problème, veuillez contacter notre magasin au',

  // S4
  'order.s4.title': `Désolé, nous n'avons pas pu vous trouver!`,
  'order.s4.subtitle': 'Mettez à jour les détails de votre emplacement. Veuillez être précis afin que nous puissions vous trouver facilement.',
  'order.s4.additional_desc': 'En cas de problème, veuillez contacter notre magasin au',

  // update pickup form
  'order.s4.update_pickup_form.label': 'Mettez à jour vos détails',
  'order.s4.update_pickup_form.placeholder':
    'Parlez-nous de votre emplacement, de la marque et du modèle du véhicule, ou de tout autre détail utile.',
  'order.s4.update_pickup_form.button': 'Mettre à jour les détails',
  'order.s4.update_pickup_form.send_error_message': 'Veuillez fournir des détails sur votre emplacement pour nous aider à vous trouver.',
  'order.s4.pickup_later.button': 'Ramasser plus tard',

  // S5
  'order.s5.title': 'Merci de magasiner chez nous',
  'order.s5.subtitle': 'Nous apprécions votre confiance et espérons vous revoir bientôt.',

  // S6
  'order.s6.title': 'Êtes-vous sûr de vouloir récupérer plus tard?',
  'order.s6.subtitle': 'Si vous avez besoin de plus de temps, vous pouvez contacter notre magasin pour modifier vos arrangements.',
  'order.s6.pickup_later_button': 'Ramasser plus tard',
  'order.s6.continue_pickup_button': 'Continuer avec le ramassage maintenant',

  // S7
  'order.s7.title': 'Vous pouvez récupérer votre commande à un autre moment.',
  'order.s7.subtitle': `Nous le garderons pour vous jusqu'au {storeLastPickupDay}. Après cela, votre commande sera automatiquement annulée.`,
  'order.s7.additional_desc':
    'Pour éviter une annulation, contactez notre magasin au {phoneAnchor} ou cliquez sur le bouton ci-dessous lorsque que vous êtes de retour au lieu de ramassage.',
  'order.s7.ready_pickup_button': 'Je suis prêt à ramasser',

  // notifications
  'notifications.gladTooSeeYou': 'Heureux de vous voir ici',
  'notifications.wellBeWithYouIn': 'Nous serons avec vous dans environ 5 à 15 minutes.',
  'notifications.ifYouAreInThrouble': `Si vous avez des problèmes, s'il vous plaît contacter notre magasin à`,
  'notifications.readyForPickUp': 'Votre commande est prête{linebreak}pour le ramassage',
  'notifications.receiveInStore': 'Vous pouvez facilement recevoir votre commande{linebreak}via un ramassage en magasin ou sur le trottoir',
  'notifications.curbsideInstructions': 'Instructions pour le ramassage en bordure de rue',
  'notifications.orderIsReady': 'Votre commande est prête{linebreak}pour le ramassage',
  'notifications.retreiveYoursel': 'Vous pouvez récupérer votre commande en entrant dans notre magasin et en vous dirigeant vers notre station de ramassage.',
  'notifications.ifYouRunIntoThroubles': `Si vous rencontrez des difficultés pour trouver la station de ramassage, veuillez en informer l'un de nos membres de l'équipe.`,
  'notifications.arrivedAt': 'Arrivé au magasin?',
  'notifications.letUsKnowHowToFindYou': `Veuillez informer nos membres de l'équipe de la manière dont nous pouvons vous trouver en ajoutant vos coordonnées ci-dessous.`,
  'notifications.ifYouHaveAnyThrouble': 'Si vous rencontrez des problèmes, veuillez contacter notre magasin au',
  'notifications.onOurWay': 'Nous sommes en route!',
  'notifications.couldntFindYou': `Désolé, nous n'avons pas pu vous trouver!`,
  'notifications.updateLocationDetails': 'Mettez à jour les détails de votre emplacement. Veuillez être précis afin que nous puissions vous trouver facilement.',
  'notifications.avoidCancelation': 'Pour éviter toute annulation, contactez notre magasin à {phoneAnchor} ou cliquez sur le bouton ci-dessous chaque fois que vous êtes de retour au lieu de ramassage.',
  'notifications.pickupAnotherTime': 'Vous pouvez récupérer votre commande à un autre moment.',
  'notifications.holdingItUntil': `Nous le garderons pour vous jusqu'au {storeLastPickupDay}. Après cela, votre commande sera automatiquement annulée.`,
  'notifications.updateOnOrder': 'MISE À JOUR SUR LA COMMANDE #',
  'notifications.customerReady': 'Le client est prêt à récupérer!',
  'notifications.orderReady': 'La commande est prête pour le ramassage.',
  'notifications.unableToMoveOrderToReady': 'Impossible de déplacer la commande vers Prêt pour le ramassage.',
  'notifications.readyForCurbside': 'La commande est Out to Curbside.',
  'notifications.unableToMoveReadyForCurbside': 'Impossible de déplacer la commande vers Out to Curbside.',
  'notifications.orderRemoved': 'La commande est supprimée.',
  'notifications.unableToRemove': 'Impossible de supprimer la commande.',
  'notifications.updated': 'Mis à jour avec succés!',
  'notifications.failed': 'Échec de mise à jour!',
  'notifications.cannotUpdate': 'La commande ne peut pas être mise à jour.',
  'notifications.wait': 'Attendre',
  'notifications.added': 'Ajoutée',
  'notifications.orderLanguage': 'Langue de commande',
  'notifications.readyForPickupI': 'Prêt pour le ramassage',
  'notifications.confirmOutForCurbside': 'Confirmer pour le Curbside!',
  'notifications.areYousure': 'Êtes-vous sûr?',
  'notifications.cancel': 'Résilier',
  'notifications.ok': `D'accord`,
  'notifications.viewOrder': `Voir l'ordre`,
  'notifications.updatedAt': 'Mis à jour à:',
  'notifications.invalidLogoUrl': `L'URL du logo n'est pas valide`,
  'notifications.companyNameNotBeEmpty': `Le nom de l'entreprise ne peut pas être vide!`,
  'notifications.passwordCannotBeEmpty': `Le mot de passe ne peut pas être vide!`,
  'notifications.clientDetailsUpdated': `Détails du client mis à jour!`,
  'notifications.failedUpdatingDetails': `Échec de la mise à jour des détails!`,
  'notifications.enterCompanyName': `Entrez le nouveau nom de l'entreprise`,
  'notifications.passwordResetSuccess': `Réinitialisation du mot de passe avec succès!`,
  'notifications.sessionExpired': `La session a expiré. Veuillez réessayer plus tard.`,
  'notifications.clientResetPassw': `Mot de passe de réinitialisation du client`,
  'notifications.passwDontMatch': `Le mot de passe ne correspond pas!`,
  'notifications.passwCannotBeEmpty': `Le mot de passe ne peut pas être vide!`,
  'notifications.resetPassw': `Réinitialiser le mot de passe`,
  'notifications.updatedSuccess': `Mis à jour avec succés!`,
  'notifications.updatedFail': `Échec de mise à jour!`,
  'notifications.orderNoteUpdated': `La note de commande a été mise à jour avec succès!`,
  'notifications.orderAddedSuccess': `Commande ajoutée avec succès!`,
  'notifications.invalidPhone': `Le numéro de téléphone n'est pas valide`,
  'notifications.cannotCreateOrderStoreLimit': `Impossible de créer une commande car la limite de commande quotidienne du magasin`,
  'notifications.ordersPerDayExceeded': `Impossible de créer la commande: nombre maximum de commandes par jour dépassé!`,
  'notifications.orderExists': `Le numéro de commande existe déjà`,
  'notifications.orderFailedSubmit': `Échec de la soumission de la commande.`,
  'notifications.orderUpdateSuccess': `Mise à jour de la commande réussie!`,
  'notifications.invalidPhoneNumber': `Le numéro de téléphone n'est pas valide`,
  'notifications.uniqueOrderId': `L'ID de commande doit être unique!`,
  'notifications.updateOrderFailed': `Échec de la mise à jour de la commande!`,
};
