export const ORDER_STATE_UNDER_PICKUP = 'UnderPickup'; // No Customer Screen
export const ORDER_STATE_PENDING_PICKUP = 'Pending'; // S1
export const ORDER_STATE_CUSTOMER_READY = 'Ready'; // S1A
export const ORDER_STATE_OUT_FOR_CURBSIDE = 'Delivering'; // S3
export const ORDER_STATE_DELIVERED = 'Completed'; // S5
export const ORDER_STATE_FAILED = 'Failed'; // S4 (can't find), S7 (customer canceled)

export const EVENT_ORDER_CREATED = 'OrderCreatedEvent'; // Associate Dashboard
export const EVENT_ORDER_READY = 'OrderReadyEvent'; // Associate Dashboard
export const EVENT_CUSTOMER_READY = 'CustomerReadyEvent'; // S1
export const EVENT_OUT_FOR_CURBSIDE = 'OrderOutToCurbsideEvent'; // Associate Dashboard
export const EVENT_DELIVERED = 'OrderDeliveredEvent'; // Associate Dashboard
export const EVENT_CANCELLED = 'OrderCancelledEvent'; // Order Removed (Assoc. Dashboard)
export const EVENT_UPDATED_CUSTOMER_INFO = 'UpdatedCustomerInfoEvent'; // S4 (new info)
export const EVENT_PICKUP_LATER = 'DeferPickupEvent'; // S6 (customer canceled)
export const EVENT_CUSTOMER_NOT_FOUND = 'CustomerNotFoundEvent'; // Associate Dashboard
export const EVENT_ORDER_MODIFIED = 'OrderModifiedEvent'; // Event when Order updated
