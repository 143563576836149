import { call, put, select } from 'redux-saga/effects';
import {
  fetchClientDetails,
  fetchClientStores,
  createClientStore,
  updateStoreStatus,
  updateClientStores,
  getSupportedCountries,
  updateClientDetails,
  clientPasswordResetRequest,
  clientPasswordReset,
  getClientAllStoresIds,
} from 'actions/apis/client';

import {
  FETCH_CLIENT_RECEIVED,
  SET_SESSION,
  SET_SUPPORTED_COUNTRIES,
} from 'actions/actionTypes';
import { DEFAULT_LOGO_URL, CLIENT_ADM_ROLE } from 'constants/defaults';
import { getHistory } from 'selectors/navigation';
import { appLogin } from 'actions/apis/login';
import { getClientCode } from 'selectors/client';
import { getClientCodeFromUrl } from 'utils/urlUtils';
import { request } from 'utils/restClient';
import {
  CUSTOMER_GET_ORIGINAL_URL,
  CUSTOMER_GET_REDIRECT_URL,
} from 'constants/endpoints';
import { getAppToken, getClientId } from 'selectors/session';
import { API_BASE_URL } from 'configs/config';

export function* fetchClientDetailsSaga(action) {
  try {
    const loginResponse = yield call(appLogin);

    if (loginResponse && loginResponse.status === 200) {
      const request = {
        clientCode: action.payload.clientCode,
        token: loginResponse.data.token,
      };

      const response = yield call(fetchClientDetails, request);
      if (response && response.status === 200) {
        const { data = {} } = response;

        // Just to make sure a logo will be available
        if (!data.logo) {
          data.logo = DEFAULT_LOGO_URL;
        }

        yield put({
          type: SET_SESSION,
          payload: { appToken: loginResponse.data.token },
        });
        yield put({
          type: FETCH_CLIENT_RECEIVED,
          payload: { clientLoaded: true, ...data },
        });
      } else {
        // Client not found - redirect to the Curbspot page
        const history = yield select(getHistory);
        history.push('/404');
      }
    }
  } catch (error) {
    // Client not found - redirect to the Curbspot page
    const history = yield select(getHistory);
    history.push('/404');
  }
}

export function* getClientAllStoresIdsSaga(action) {
  const loginResponse = yield call(appLogin);
  const storesId = yield call(getClientAllStoresIds, {
    clientId: action.payload.clientCode,
    token: loginResponse.data.token,
  });
  if (storesId && storesId.status === 200) {
    yield put({
      type: FETCH_CLIENT_RECEIVED,
      payload: { clientAllStoresIds: storesId.data },
    });
  }
}

export function* fetchClientStoresSaga() {
  try {
    let clientId = yield select(getClientCode);

    if (!clientId) {
      clientId = getClientCodeFromUrl();
    }

    const storesData = yield call(fetchClientStores, {
      clientId,
    });
    yield put({
      type: FETCH_CLIENT_RECEIVED,
      payload: { clientStores: storesData.data },
    });
  } catch (error) {
    // handled in component;
  }
}

export function* createClientStoreSaga(action) {
  try {
    const response = yield call(createClientStore, {
      clientId: action.payload.clientId,
      data: action.payload.body,
    });
    if (response && response.status === 200) {
      yield fetchClientStoresSaga();
    }
    return response;
  } catch (error) {
    // handled in component;
  }
}

export function* updateClientStoresSaga(action) {
  try {
    const response = yield call(updateClientStores, {
      clientId: action.payload.clientId,
      data: action.payload.body,
    });
    if (response && response.status === 200) {
      yield fetchClientStoresSaga();
    }
    return response;
  } catch (error) {
    // handled in component;
  }
}

export function* updateStoreStatusSaga(action) {
  try {
    const response = yield call(updateStoreStatus, {
      clientId: action.payload.clientId,
      storeId: action.payload.storeId,
      status: action.payload.status,
    });
    if (response && response.status === 200) {
      yield fetchClientStoresSaga();
    }
    return response;
  } catch (error) {
    // handled in component;
  }
}

export function* fetchOriginalUrl(action) {
  const appToken = yield select(getAppToken);
  const shortenKey = action.payload.urlToken;

  const originalResponse = yield request(CUSTOMER_GET_ORIGINAL_URL, {
    pathParams: { shortenKey },
    headers: {
      Authorization: appToken,
    },
  });

  if (originalResponse.status === 200) {
    const url = new URL(originalResponse.data.originalUrl, API_BASE_URL);
    const shortenKey = url.pathname.slice(url.pathname.lastIndexOf('/') + 1);
    const searchParams = new URLSearchParams(url.search);

    const queryParams = {};
    for (const [key, value] of searchParams.entries()) {
      queryParams[key] = value;
    }

    const redirectResponse = yield request(CUSTOMER_GET_REDIRECT_URL, {
      pathParams: { shortenKey },
      queryParams,
    });

    if (redirectResponse.status === 200) {
      const { clientId } = redirectResponse.data.clientInfo;
      const { storeId } = redirectResponse.data.storeInfo;
      const orderId = redirectResponse.data.id;

      originalResponse.data.redirectUrl = `/clients/${clientId}/stores/${storeId}/orders/${orderId}?token=${queryParams.token}`;
    }
  }

  return originalResponse;
}

export function* getSupportedCountriesSaga(action) {
  const clientId = yield select(getClientId);
  const response = yield call(getSupportedCountries, {
    clientId,
    ...action.payload,
  });
  if (response.status === 200) {
    yield put({
      type: SET_SUPPORTED_COUNTRIES,
      payload: {
        supportedCountries: response.data,
      },
    });
  }
}

export function* updateClientDetailsSaga(action) {
  const clientId = yield select(getClientId);
  const response = yield call(updateClientDetails, {
    clientId,
    body: action.payload.data,
  });
  if (response.status === 200) {
    yield fetchClientDetailsSaga({ payload: { clientCode: clientId } });
  }
  return response;
}

export function* requestPasswordResetSaga() {
  const clientId = yield select(getClientCode);
  const body = {
    clientCode: clientId,
    role: CLIENT_ADM_ROLE,
  };
  const response = yield call(clientPasswordResetRequest, { body });
  return response;
}

export function* updateResetPasswordSaga(action) {
  const clientId = yield select(getClientCode);
  const { body, token } = action.payload;
  const response = yield call(clientPasswordReset, { body, clientId, token });
  return response;
}
