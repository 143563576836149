import React, { useState, useContext } from 'react';
import { StoreContext } from 'contexts';
import { CLIENT_UPDATE_PASSWORD } from 'actions/actionTypes';
import { IonInput, IonButton, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';

import './CPClientResetPassword.scss';

const CPClientResetPassword: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const parsedParams = new URLSearchParams(window.location.search);
  const token = parsedParams.get('token');
  const { action, notify } = useContext(StoreContext);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState<boolean>();
  const [isEmptyPassword, setIsEmptyPassword] = useState<boolean>();

  const isValidPassword = () => {
    if (newPassword !== confirmPassword) {
      setPasswordMatch(true);
      return false;
    } else if (newPassword.length === 0) {
      setIsEmptyPassword(true);
      return false;
    } else {
      return true;
    }
  };

  const resetPassword = () => {
    setPasswordMatch(false);
    setIsEmptyPassword(false);
    const isValid = isValidPassword();
    if (isValid) {
      const body = {
        password: newPassword,
      };
      action(CLIENT_UPDATE_PASSWORD, { body, token }).then((res: any) => {
        if (res.status === 200) {
          notify({
            message: intl.formatMessage({ id: 'notifications.passwordResetSuccess', defaultMessage: 'Password reset successfully!' }),
            color: 'success',
          });
          history.push('/login');
        } else if (res.status === 401) {
          notify({
            message: intl.formatMessage({ id: 'notifications.sessionExpired', defaultMessage: 'Session expired. Please, try again later.' }),
            color: 'warning',
          });
          history.push('/login');
        }
      });
    }
  };

  return (
    <div className="reset-password-wrapper">
      <h1 className="reset-password-title center">{intl.formatMessage({ id: 'notifications.clientResetPassw', defaultMessage: 'Client Reset Password' })}</h1>
      <IonInput
        className="reset-password-field"
        type="password"
        placeholder="Enter new password"
        value={newPassword}
        onIonChange={(e: any) => setNewPassword(e.detail.value)}
      ></IonInput>
      <IonInput
        className="reset-password-field"
        type="password"
        placeholder="Confirm new password"
        value={confirmPassword}
        onIonChange={(e: any) => setConfirmPassword(e.detail.value)}
      ></IonInput>
      {passwordMatch && (
        <IonText className="password-match-error">
          {intl.formatMessage({ id: 'notifications.passwDontMatch', defaultMessage: 'Password did not match!' })}
        </IonText>
      )}
      {isEmptyPassword && (
        <IonText className="password-match-error">
          {intl.formatMessage({ id: 'notifications.passwCannotBeEmpty', defaultMessage: 'Password cannot be empty!' })}
        </IonText>
      )}
      <IonButton
        color="dark"
        className="reset-password-button"
        onClick={() => resetPassword()}
      >
        {intl.formatMessage({ id: 'notifications.resetPassw', defaultMessage: 'Reset password' })}
      </IonButton>
    </div>
  );
};
export default CPClientResetPassword;
