import React from 'react';
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonFabList,
  IonList,
  IonAvatar,
  IonLabel,
  IonItem,
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import Anchor from 'components/DesignSystem/Anchor';

const StoreImportInfo = () => {
  return (
    <IonFab className="help-fab" vertical="top" horizontal="end" slot="fixed">
      <IonFabButton size="small">
        <IonIcon icon={helpCircleOutline} />
      </IonFabButton>
      <IonFabList>
        <IonList className="help-list" lines="none">
          <IonItem className="help-list-item">
            <IonAvatar className="help-list-numeral">
              <span>1</span>
            </IonAvatar>
            <IonLabel className="help-list-text">
              As a beta user, please feel free to{' '}
              <Anchor
                href="https://meetings.hubspot.com/adam-willmouth"
                target="_blank"
              >
                schedule a free 15-minute onboarding call
              </Anchor>{' '}
              to help you set up your stores. This typically takes 5-10 minutes.
            </IonLabel>
          </IonItem>
          <IonItem className="help-list-item">
            <IonAvatar className="help-list-numeral">
              <span>2</span>
            </IonAvatar>
            <IonLabel className="help-list-text">
              You can view a video version of the instructions: Click{' '}
              <Anchor href="https://youtu.be/YpGfWxtykWE" target="_blank">
                here
              </Anchor>
              .
            </IonLabel>
          </IonItem>
          <IonItem className="help-list-item">
            <IonAvatar className="help-list-numeral">
              <span>3</span>
            </IonAvatar>
            <IonLabel className="help-list-text">
              You can read the detailed FAQ instructions clicking{' '}
              <Anchor
                href="https://curby-the-easiest-curbside-pickup-software-in-the-world.reamaze.com/kb/getting-started/filling-out-your-store-csv-file"
                target="_blank"
              >
                here
              </Anchor>
              .
            </IonLabel>
          </IonItem>
        </IonList>
      </IonFabList>
    </IonFab>
  );
};

export default StoreImportInfo;
