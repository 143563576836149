import React from 'react';

import './OrderDetailsContentWrapper.scss';

interface ComponentProps {
  className?: string;
  children: any;
}

const OrderDetailsContentWrapper: React.FC<ComponentProps> = ({
  className,
  children,
}) => {
  const classNames = ['default-wrapper'];

  if (className) {
    classNames.push(className);
  }

  return <div className={`${classNames.join(' ')}`}>{children}</div>;
};

export default OrderDetailsContentWrapper;
