import React, { useEffect, useContext, useRef } from 'react';
import CPFooter from 'components/CPFooter';

import { IonPage, IonGrid, IonRow, IonCol, IonContent } from '@ionic/react';
import CPHeader from 'components/CPHeader';
import CPCustomerOrderDetails from 'components/CPCustomerOrderDetails';
import { useSelector } from 'react-redux';
import { getLoading } from 'selectors/ui';
import Loader from 'components/DesignSystem/Loader';
import { StoreContext } from 'contexts';
import {
  SET_LOADING,
  FETCH_CUSTOMER_ORDER,
  SET_SESSION,
} from 'actions/actionTypes';

import './CustomerOrderDetails.scss';
import { RouteComponentProps, useParams, useHistory } from 'react-router';
import { getCustomerOrder } from 'selectors/orders';
import { getClientLoaded } from 'selectors/client';

interface ComponentProps extends RouteComponentProps {}

interface PathParams {
  clientId: string;
  storeId: string;
  orderId: string;
}

const CustomerOrderDetails: React.FC<ComponentProps> = () => {
  const { action } = useContext(StoreContext);
  const isLoading = useSelector(getLoading);
  const order = useSelector(getCustomerOrder);
  const history = useHistory();
  const clientLoaded = useSelector(getClientLoaded);
  const { clientId, storeId, orderId } = useParams<PathParams>();
  // Parses the query param to collect the token
  // IMPORTANT: not applicable for IE11 - if we have to support
  // it in the future, we need to find another solution
  const parsedParams = new URLSearchParams(window.location.search);
  const token = parsedParams.get('token');

  // Scroll to top to ensure we don't present "the middle" of the screen
  const contentRef = useRef(null);

  if (
    contentRef &&
    contentRef.current &&
    // @ts-ignore
    typeof contentRef.current.scrollToTop === 'function'
  ) {
    // @ts-ignore
    contentRef.current.scrollToTop(500);
  }

  useEffect(() => {
    if (!clientLoaded) {
      return;
    }

    action(FETCH_CUSTOMER_ORDER, { clientId, storeId, orderId, token }).then(
      (res: any = {}) => {
        if (res.status === 200) {
          // Include parsed data into the state to avoid
          // code duplication
          action(SET_SESSION, { clientId, storeId, orderId, orderLanguage: res.data.orderLanguage });
          action(SET_LOADING, { loading: false });
        } else {
          action(SET_LOADING, { loading: false });
          history.push('/404');
        }
      }
    );
  }, [clientLoaded]);

  return (
    <IonPage className="customer-order-details-page">
      <CPHeader suppressStore={true} suppressLogout={true} />
      <IonContent ref={contentRef} scrollEvents={true}>
        <IonGrid className="reset-padding">
          <IonRow className="oe-container">
            <IonCol className="reset-padding" size="12">
              {isLoading || !order ? (
                <Loader className="page-loader" />
              ) : (
                <CPCustomerOrderDetails order={order} />
              )}
              <CPFooter />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CustomerOrderDetails;
