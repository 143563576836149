import React, { useEffect, useContext, useState } from 'react';
import { StoreContext } from 'contexts';
import { UPDATE_CLIENT_DETAILS } from 'actions/actionTypes';
import { DEFAULT_LOGO_URL } from 'constants/defaults';
import { useIntl } from 'react-intl';
import './ClientUpdateForm.scss';
import {
  IonCol,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonText,
} from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import Anchor from 'components/DesignSystem/Anchor';
interface IClientUpdateProps {
  client: any;
  closeModal: Function;
}
const ClientUpdateForm: React.FC<IClientUpdateProps> = ({
  client,
  closeModal,
}) => {
  const intl = useIntl();
  const [passwordMasking, setPasswordMasking] = useState<boolean>(false);
  const [updatePassword, setUpdatePassword] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [logo, setLogo] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { action, notify } = useContext(StoreContext);
  const isValid = () => {
    const isValidLogo = logo.startsWith('https://') || logo === '';
    if (!isValidLogo) {
      notify({ message: intl.formatMessage({ id: 'notifications.invalidLogoUrl', defaultMessage: 'Logo url is invalid' }), color: 'warning' });
    }
    const isValidCompanyName = companyName.length > 0;
    if (!isValidCompanyName) {
      notify({
        message: intl.formatMessage({ id: 'notifications.companyNameNotBeEmpty', defaultMessage: 'Company name cannot be empty!' }),
        color: 'warning',
      });
    }
    const isPasswordEmpty = updatePassword ? password.length === 0 : false;
    if (isPasswordEmpty) {
      notify({
        message: intl.formatMessage({ id: 'notifications.passwordCannotBeEmpty', defaultMessage: 'Password cannot be empty!' }),
        color: 'warning',
      });
    }
    return isValidLogo && isValidCompanyName && !isPasswordEmpty;
  };
  const updateClient = () => {
    const valid = isValid();
    if (valid) {
      const data = {
        companyName,
        logo,
      };
      let body = {};
      if (updatePassword) {
        body = {
          ...data,
          password,
        };
      } else {
        body = { ...data };
      }
      action(UPDATE_CLIENT_DETAILS, {
        data: body,
      }).then((res: any) => {
        if (res.status === 200) {
          closeModal();
          notify({
            message: intl.formatMessage({ id: 'notifications.clientDetailsUpdated', defaultMessage: 'Client details updated!' }),
            color: 'success',
          });
        } else {
          notify({
            message: intl.formatMessage({ id: 'notifications.failedUpdatingDetails', defaultMessage: 'Failed to update details!' }),
            color: 'danger',
          });
        }
      });
    }
  };
  useEffect(() => {
    if (client) {
      setCompanyName(client.companyName);
      setLogo(client.logo === DEFAULT_LOGO_URL ? '' : client.logo);
    }
  }, [client]);

  return (
    <div className="client-update-form">
      <IonText className="header-wrapper">
        <h1 className="unified-order-modal-header">{intl.formatMessage({ id: 'notifications.', defaultMessage: 'Update Client' })}</h1>
      </IonText>
      <IonLabel className="description-label">Company name</IonLabel>
      <IonInput
        placeholder={intl.formatMessage({ id: 'notifications.enterCompanyName', defaultMessage: 'Enter new Company name' })}
        className="client-admin-input"
        value={companyName}
        onIonChange={(e: any) => {
          setCompanyName(e.detail.value);
        }}
      />
      <IonLabel className="description-label">Company logo</IonLabel>
      <IonInput
        placeholder="Enter new logo"
        className="client-admin-input"
        value={logo}
        onIonChange={(e: any) => {
          setLogo(e.detail.value);
        }}
      />
      <IonCol sizeLg="12" sizeXs="12">
        <IonLabel className="description-label">Password</IonLabel>
        <Anchor
          className="change-link"
          href="#"
          onClick={(event: Event) => {
            event.preventDefault();
            setUpdatePassword(!updatePassword);
            setPassword('');
          }}
        >
          {!updatePassword ? 'Change' : 'Cancel'}
        </Anchor>
        <IonInput
          className="client-admin-input d-flex password-field"
          value={password}
          type={passwordMasking ? 'text' : 'password'}
          disabled={!updatePassword}
          onIonChange={(e: any) => {
            setPassword(e.detail.value);
          }}
        >
          {passwordMasking ? (
            <IonIcon
              icon={eyeOutline}
              className="pasword-masking"
              onClick={() => setPasswordMasking(false)}
            />
          ) : (
            <IonIcon
              icon={eyeOffOutline}
              className="pasword-masking"
              onClick={() => setPasswordMasking(true)}
            />
          )}
        </IonInput>
      </IonCol>
      <div className="d-flex cta-wrapper">
        <IonButton
          className="button-secondary"
          color="light"
          size="large"
          mode="md"
          onClick={() => closeModal()}
        >
          CANCEL
        </IonButton>
        <IonButton
          className="button-primary"
          color="dark"
          size="large"
          mode="md"
          type="submit"
          buttonType="button"
          onClick={() => updateClient()}
        >
          Update Client
        </IonButton>
      </div>
    </div>
  );
};

export default ClientUpdateForm;
