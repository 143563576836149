export const DEFAULT_LOGO_URL = '/assets/logo/curbspotlogo.png';
export const DEFAULT_NAME = 'Curbspot';
// Local Storage key for Curby data
export const CURBY_LS_AUTH_TOKEN = 'curby-auth-token';

export const ASSOCIATE_ROLE = 'STORE_SALES_ASSOCIATE';
export const DISPATCHER_ROLE = 'STORE_DISPATCHER';
export const CLIENT_ADM_ROLE = 'CLIENT_ADMIN';
export const CUSTOMER_APP_ROLE = 'CUSTOMER_APP';

export const SYSTEM_ROLES = {
  ASSOCIATE_ROLE,
  CLIENT_ADM_ROLE,
  DISPATCHER_ROLE,
  CUSTOMER_APP_ROLE,
};

export const CURBSPOT_DELIVERY = 'curbside';
export const BOPIS_DELIVERY = 'bopis';
export const DEFAULT_APP_LANGUAGE  = 'en';
export const SUPPORTED_LANGUAGES  = {
  en: {value: 'en', placeholder: 'English (en)'},
  fr: {value: 'fr', placeholder: 'French (fr)'},
};
// local storage key for the current language
export const LOCAL_STORE_CURRENT_LAGUAGE = 'store-current-language';
export const LANGUAGE_SEPARATOR = '||';
export const LANGUAGE_INDICATOR_SEPARATOR = '=';
