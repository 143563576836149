import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStore } from 'selectors/session';
import { Redirect } from 'react-router';
import OrderDetailsS1BopisOnly from './OrderDetailsS1BopisOnly';
import OrderDetailsS1CurbsideOnly from './OrderDetailsS1CurbsideOnly';
import OrderDetailsS1BopisCurbside from './OrderDetailsS1BopisCurbside';
import { BOPIS_DELIVERY } from 'constants/defaults';

const OrderDetailsS1Entry: React.FC<{ order: any }> = ({ order }) => {
  const store = useSelector(getCurrentStore) || {};
  const { deliveryMethods = [] } = store;

  const allMethods = deliveryMethods
    .filter((method: any) => method.deliveryMethod.active)
    .map((method: any) => method.deliveryMethod.id);

  // Can't define what method this store handles - takes to 404
  if (!allMethods.length) {
    return <Redirect to="/404" />;
  }

  // Now check if we have BOPIS + Curbside
  if (allMethods.length > 1) {
    return <OrderDetailsS1BopisCurbside order={order} store={store} />;
  }

  // If it has BOPIS in the list, return BOPIS view
  return allMethods.indexOf(BOPIS_DELIVERY) !== -1 ? (
    <OrderDetailsS1BopisOnly order={order} store={store} />
  ) : (
    // Otherwise, return the existing curbside view
    <OrderDetailsS1CurbsideOnly order={order} store={store} />
  );
};

export default OrderDetailsS1Entry;
