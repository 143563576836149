import React, { useContext, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IonButton } from '@ionic/react';

import OrderDetailsFormWrapper from '../OrderDetailsFormWrapper';
import { StoreContext } from 'contexts';
import { NOTIFY, CREATE_CUSTOMER_EVENT } from 'actions/actionTypes';
import {
  EVENT_UPDATED_CUSTOMER_INFO,
  EVENT_CUSTOMER_READY,
} from 'constants/orderStates';
import Loader from 'components/DesignSystem/Loader';

interface ComponentProps {
  order: any;
}

const OrderDetailsUpdateDetails: React.FC<ComponentProps> = ({ order }) => {
  const { action } = useContext(StoreContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { customerEmail, customerPhone } = order.customerInfo;
  const [location, setLocation] = useState<string>(() => {
    const events = [...order.events];
    const lastEvent = events.reverse().find(
      (event: any) =>
        event.type === EVENT_UPDATED_CUSTOMER_INFO ||
        event.type === EVENT_CUSTOMER_READY // checking if last udate on order
    );
    return lastEvent ? lastEvent.locationDescription : '';
  });
  const intl = useIntl();

  const sendError = useCallback(
    (message: string) => {
      action(NOTIFY, {
        color: 'danger',
        message,
      });
    },
    [action]
  );

  const updateErrorTranslation = intl.formatMessage({
    id: 'order.s4.pickup_form.send_error_message',
    defaultMessage: 'Please provide details about your location to help us to find you.'
  });

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (location) {
        setLoading(true);
        action(CREATE_CUSTOMER_EVENT, {
          type: EVENT_UPDATED_CUSTOMER_INFO,
          locationDescription: location,
          customerEmail,
          customerPhone,
        }).then((res: any) => {
          if (res && res.status !== 200) {
            setLoading(false);
            sendError(updateErrorTranslation);
          }
        });
      } else {
        sendError(updateErrorTranslation);
      }
    },
    [location, action]
  );

  return (
    <OrderDetailsFormWrapper>
      <form onSubmit={(e) => onSubmit(e)} className="customer-order-form">
        <label htmlFor="location" className="customer-order-form-label">
          <FormattedMessage
            id="order.s4.update_pickup_form.label"
            defaultMessage="Update your Details"
          />
        </label>
        <textarea
          className="customer-order-form-textarea"
          rows={4}
          value={location}
          placeholder={
            intl.formatMessage({
              id: 'order.s4.update_pickup_form.placeholder',
              defaultMessage: 'Tell us about your location, vehicle make and model, or any other helpful details.'
            })
          }
          name="location"
          onChange={(e) => setLocation(e.currentTarget.value)}
        ></textarea>
        <IonButton
          className="customer-order-form-submit button-primary"
          color="dark"
          disabled={loading}
          shape={undefined}
          type="submit"
        >
          {loading
            ? <Loader withMinHeight={false} />
            : intl.formatMessage({
              id: 'order.s4.update_pickup_form.button',
              defaultMessage: 'Update Details'
            })}
        </IonButton>
      </form>
    </OrderDetailsFormWrapper>
  );
};

export default OrderDetailsUpdateDetails;
