export const getClient = (state: any) => state.client || {};
export const getClientCode = (state: any) => getClient(state).id;
export const getName = (state: any) => getClient(state).name;
export const getLogo = (state: any) => getClient(state).logo;
export const getClientLoaded = (state: any) => getClient(state).clientLoaded;
export const getClientStore = (state: any) => getClient(state).clientStores;
export const getClientFilteredStores = (state: any) => (searchText: string) => {
  const stores = getClient(state).clientStores;
  const lowerText = searchText && searchText.toLowerCase();
  return stores.filter(
    (store: any) =>
      store.id.toLowerCase().includes(lowerText) ||
      store.address.toLowerCase().includes(lowerText)
  );
};
export const getClientAllStoresIds = (state: any) =>
  getClient(state).clientAllStoresIds;
export const getSupportedCountries = (state: any) => {
  return getClient(state).supportedCountries || [];
};
export const getAvailableLanguages = (currentLanguage: string) => {
  return [{ code: 'en', description: 'English' }, { code: 'fr', description: 'Français' }];
};
