import React from 'react';
import './CPCustomerOrderDetails.scss';
import OrderDetailsS1Entry from './subcomponents/OrderDetailsS1Entry';
import OrderDetailsS1A from './subcomponents/OrderDetailsS1A';
import OrderDetailsS4 from './subcomponents/OrderDetailsS4';
import OrderDetailsS3 from './subcomponents/OrderDetailsS3';
import OrderDetailsS5 from './subcomponents/OrderDetailsS5';
import {
  ORDER_STATE_PENDING_PICKUP,
  ORDER_STATE_CUSTOMER_READY,
  ORDER_STATE_OUT_FOR_CURBSIDE,
  ORDER_STATE_DELIVERED,
  EVENT_PICKUP_LATER,
  EVENT_ORDER_READY,
  EVENT_CUSTOMER_NOT_FOUND,
  EVENT_ORDER_MODIFIED,
} from 'constants/orderStates';
import OrderDetailsS7 from './subcomponents/OrderDetailsS7';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { getPickupRetry } from 'selectors/orders';

interface ContainerProps {
  order: any;
}

const CPCustomerOrderDetails: React.FC<ContainerProps> = ({ order }) => {
  const retryPickup = useSelector(getPickupRetry);

  const renderView = () => {
    switch (order.state.stateName) {
      case ORDER_STATE_PENDING_PICKUP:
        // Pending has four use cases:
        // 1. Customer first pickup time
        // 2. Customer not found (from dispatcher)
        // 3. Customer pickup later
        // 4. Customer wants to retry after pickup later - this one is not
        //    a state per say, but a fake action we call just to move the page
        const { events = [] } = order;
        const allEvents = [...events].reverse();
        // If we don't have events we present 404 because
        // we can't identify the current action
        if (!events.length) {
          return <Redirect to="/404" />;
        }
        //ignoring order upadate events
        const lastEvent = allEvents.find(
          (event: any) => event.type !== EVENT_ORDER_MODIFIED
        );

        // The last event is order created (use case 1) OR
        // retryPickup flag is true
        if (lastEvent.type === EVENT_ORDER_READY || retryPickup) {
          return <OrderDetailsS1Entry order={order} />;
          // The last event is pickup later (use case 3)
        } else if (lastEvent.type === EVENT_PICKUP_LATER) {
          return <OrderDetailsS7 order={order} />;
        } else if (lastEvent.type === EVENT_CUSTOMER_NOT_FOUND) {
          // The last event is cancelled (use case 2)
          return <OrderDetailsS4 order={order} />;
        }
        // Can't find the event so take to 404
        return <Redirect to="/404" />;

      case ORDER_STATE_CUSTOMER_READY:
        return <OrderDetailsS1A order={order} />;
      case ORDER_STATE_OUT_FOR_CURBSIDE:
        return <OrderDetailsS3 order={order} />;
      case ORDER_STATE_DELIVERED:
        return <OrderDetailsS5 />;
      default:
        return <Redirect to="/404" />;
    }
  };

  // If we can't see the order state name, this order is not valid
  // Presenting 404
  if (!order.state || !order.state.stateName) {
    return <Redirect to="/404" />;
  }

  return (
    <div className="customer-order-details">
      {renderView()}
    </div>
  );
};
export default CPCustomerOrderDetails;
