import React, { useCallback, useContext } from 'react';

import { useSelector } from 'react-redux';
import { getToken } from 'selectors/session';
import { StoreContext } from 'contexts';

import { LOGOUT_SAGA } from 'actions/actionTypes';
import Anchor from 'components/DesignSystem/Anchor';
import './CPLogoutCTA.scss';
const CPLogoutCTA: React.FC<any> = () => {
  const { action } = useContext(StoreContext);
  const token = useSelector(getToken);

  const onLogoutSubmit = useCallback((e) => {
    e.preventDefault();
    action(LOGOUT_SAGA);
    return false;
  }, []);

  if (!token) {
    return null;
  }

  return (
    <Anchor
      className="logout-style"
      href="#"
      onClick={(event: Event) => {
        event.preventDefault();
        onLogoutSubmit(event);
      }}
    >
      Logout
    </Anchor>
  );
};
export default CPLogoutCTA;
