import React, { useCallback, useContext, useState } from 'react';
import { IonModal, IonContent, IonIcon, IonButton } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import OrderDetailsContentWrapper from '../OrderDetailsContentWrapper';
import './OrderDetailsS6.scss';
import { StoreContext } from 'contexts';
import { CREATE_CUSTOMER_EVENT } from 'actions/actionTypes';
import Loader from 'components/DesignSystem/Loader';
import { EVENT_PICKUP_LATER } from 'constants/orderStates';

interface ComponentProps {
  isOpen: boolean;
  setOpen: Function;
}

const OrderDetailsS6: React.FC<ComponentProps> = ({ isOpen, setOpen }) => {
  const { action } = useContext(StoreContext);
  const [loading, setLoading] = useState<boolean>(false);

  const onCancelConfirm = useCallback(() => {
    setLoading(true);
    action(CREATE_CUSTOMER_EVENT, {
      type: EVENT_PICKUP_LATER,
    }).then((res: any) => {
      if (res && res.status !== 200) {
        // TODO: Present error message
        setLoading(false);
      }
    });
  }, [action]);

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false} keyboardClose={false}>
      <IonContent>
        <div className="order-details-cancel-pickup-modal">
          <div className="modal-close-wrapper">
            <IonIcon
              src="/assets/icon/close-outline.svg"
              onClick={() => setOpen(false)}
              className="modal-close-icon"
            />
          </div>
          <OrderDetailsContentWrapper>
            <div className="center">
              <h1 className="cancel-pickup-title">
                <FormattedMessage
                  id="order.s6.title"
                  defaultMessage="Are you sure you want to pickup later?"
                />
              </h1>
              <p className="cancel-pickup-message default-p">
                <FormattedMessage
                  id="order.s6.subtitle"
                  defaultMessage="If you need more time you can contact our store to modify your
                  arrangements."
                />
              </p>
              <IonButton
                className="cancel-pickup-confirm button-primary w-100"
                onClick={() => onCancelConfirm()}
                disabled={loading}
                color="dark"
                size="large"
                mode="md"
                shape={undefined}
              >
                {loading
                  ? <Loader withMinHeight={false} />
                  : <FormattedMessage
                      id="order.s6.pickup_later_button"
                      defaultMessage="Pickup Later"
                    />}
              </IonButton>
              <IonButton
                className="button-secondary w-100 reset-margin"
                onClick={() => setOpen(false)}
                disabled={loading}
                color="light"
                size="large"
                mode="md"
                shape={undefined}
              >
                <FormattedMessage
                  id="order.s6.continue_pickup_button"
                  defaultMessage="Continue with Pickup Now"
                />
              </IonButton>
            </div>
          </OrderDetailsContentWrapper>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default OrderDetailsS6;
