import React from 'react';
import { useIntl } from 'react-intl';
import OrderDetailsDeliveryInstructions from '../OrderDetailsDeliveryInstructions';

import './OrderDetailsBopisinstructions.scss';

const OrderDetailsBopisInstructions: React.FC<{ store: any, order: any, instructions: any }> = ({ store, order, instructions }) => {
  const intl = useIntl();

  return (
    <div className="inner-content-wrapper">
      {instructions ?
        <OrderDetailsDeliveryInstructions
          title={
            intl.formatMessage({
              id: 'order.s1.bopis_instructions.title',
              defaultMessage: 'Instructions for in-store pickup'
            })
          }
          instructions={instructions}
        />
      : null}
    </div>
  );
};

export default OrderDetailsBopisInstructions;
