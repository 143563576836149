import React from 'react';
import { FormattedMessage } from 'react-intl';

import './OrderDetailsOrderDelivered.scss';

const OrderDetailsOrderDelivered = () => {
  return (
    <div className="customer-order-success center">
      <h1 className="customer-order-success-title reset-margin">
      <FormattedMessage
        id="order.s5.title"
        defaultMessage="Thank you{linebreak}for shopping with us"
        values={{ linebreak: <br /> }}
      />
      </h1>
      <p className="default-p reset-margin">
        <FormattedMessage
          id="order.s5.subtitle"
          defaultMessage="We appreciate your business{linebreak}and hope to see you again soon."
          values={{ linebreak: <br /> }}
        />
      </p>
    </div>
  );
};

export default OrderDetailsOrderDelivered;
