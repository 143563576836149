import React from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import './LanguageDropdown.scss';
interface ContainerProps {
  selectedLanguage: string;
  setSelectedLanguage: Function;
  supportedLanguages: any;
  customClassName?: any;
}

const LanguageDropdown: React.FC<ContainerProps> = ({
  selectedLanguage,
  setSelectedLanguage,
  supportedLanguages,
  customClassName,
}) => {

  const className = `${customClassName}`;

  return (
    <IonSelect
      className={className}
      value={selectedLanguage}
      onIonChange={(e: any) => {
        setSelectedLanguage(e.detail.value);
      }}
    >
      {supportedLanguages.map((language: any) => (
        <IonSelectOption value={language.code} key={language.code}>
          {`${language.code.toUpperCase()} - ${language.description}`}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};
export default LanguageDropdown;
