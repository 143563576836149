import React, { useEffect, useContext } from 'react';
import { withSize } from 'react-sizeme';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import { ViewPortContext } from 'contexts';
import CPStoreDetails from 'components/CPStoreDetails/component';
import CPFooter from 'components/CPFooter';
import { StoreContext } from 'contexts';
import './ClientAdminStore.scss';
import { getClientId, getRole } from 'selectors/session';
import CPClientLogo from 'components/CPClientLogo';
import { CLIENT_ADM_ROLE } from 'constants/defaults';
import { GET_SUPPORTED_COUNTRIES } from 'actions/actionTypes';
import { useSelector } from 'react-redux';

interface ClientAdminHomeprops {
  size: object;
}
const ClientAdminStore: React.FC<ClientAdminHomeprops> = ({ size }) => {
  const { action } = useContext(StoreContext);
  const clientId = useSelector(getClientId);
  const role = useSelector(getRole);

  useEffect(() => {
    if (role === CLIENT_ADM_ROLE) {
      action(GET_SUPPORTED_COUNTRIES);
    }
  }, [action, clientId, role]);

  return (
    <ViewPortContext.Provider value={size}>
      <IonPage className="client-admin-page">
        <IonContent>
          <IonGrid>
            <IonRow className="oe-container">
              <IonCol size="12">
                <CPClientLogo />
                <CPStoreDetails />
                <CPFooter />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </ViewPortContext.Provider>
  );
};

export default withSize()(ClientAdminStore);
