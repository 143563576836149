import React, { useState, useContext, useCallback, useEffect } from 'react';
import {
  CLIENT_ADM_ROLE,
  DISPATCHER_ROLE,
  ASSOCIATE_ROLE,
} from 'constants/defaults';
import { StoreContext } from 'contexts';
import { useSelector } from 'react-redux';
import { getRole, getToken, getClientId, getStoreId } from 'selectors/session';
import { getClientAllStoresIds } from 'selectors/client';
import {
  NOTIFY,
  SET_LOADING,
  REFRESH_TOKEN,
  LOGIN_SAGA,
  CLIENT_REQUEST_PASSWORD_RESET,
  GET_CLIENT_ALL_STORES_IDS,
} from 'actions/actionTypes';
import Anchor from 'components/DesignSystem/Anchor';
import { getLoading } from 'selectors/ui';
import Loader from 'components/DesignSystem/Loader';
import {
  useIonViewWillEnter,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonAlert,
} from '@ionic/react';
import { useHistory } from 'react-router';

import './CPUnifiedLogin.scss';
import { getClientCodeFromUrl } from 'utils/urlUtils';

interface ContainerProps {}

const getFormTouchState = ({ touched = false }) => ({
  role: { touched },
  storeId: { touched },
  password: { touched },
});

const isFormValid = ({ role, storeId, password }: any) => {
  return password && role && role === CLIENT_ADM_ROLE ? true : storeId;
};

const PLACEHOLDER_MESSAGE_MAP: any = {
  [ASSOCIATE_ROLE]: 'Paste the password you imported in the CSV file',
  [DISPATCHER_ROLE]: 'Paste the password you imported in the CSV file',
  [CLIENT_ADM_ROLE]: 'Paste the password you received by email',
};

const CPUnifiedLogin: React.FC<ContainerProps> = () => {
  const { action, notify } = useContext(StoreContext);
  const history = useHistory();
  const stateRole = useSelector(getRole);
  const stateToken = useSelector(getToken);
  const stateStoreId = useSelector(getStoreId);
  const stateClientId = useSelector(getClientId);
  const stateIsLoading = useSelector(getLoading);
  const clientAllStoresIds = useSelector(getClientAllStoresIds);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [role, setRole] = useState<any>(CLIENT_ADM_ROLE);
  const [storeId, setStoreId] = useState<any>();
  const [password, setPassword] = useState<any>('');
  const [formTouch, setFormTouch] = useState(
    getFormTouchState({ touched: false })
  );
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const redirectToAdmin = (role: string) => {
    history.push(role === CLIENT_ADM_ROLE ? '/admin/stores' : '/manager/home');
  };
  const setValue = useCallback(
    (target, setter, value) => {
      setter(value);
      setFormTouch({ ...formTouch, [target]: { touched: true } });
    },
    [formTouch]
  );

  const resetForm = useCallback(() => {
    setRole(CLIENT_ADM_ROLE);
    setStoreId('');
    setPassword('');
    setFormTouch(getFormTouchState({ touched: false }));
  }, []);
  const resetClientPassword = () => {
    action(CLIENT_REQUEST_PASSWORD_RESET).then((res: any) => {
      if (res.status === 200) {
        notify({
          message: 'Reset password link sent to your email!',
          color: 'success',
        });
      }
    });
  };
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (isFormValid({ role, storeId, password })) {
        setSubmitting(true);

        action(LOGIN_SAGA, {
          role,
          password,
          login: role,
          // Make sure store is not sent on admin login
          storeId: role !== CLIENT_ADM_ROLE ? storeId : undefined,
        }).then((response: any) => {
          setSubmitting(false);

          if (response && response.status === 200) {
            resetForm();
            redirectToAdmin(role);
          } else {
            action(NOTIFY, {
              message: 'We do not recognize that credentials combination.',
              color: 'danger',
            });
          }
        });
      } else {
        setFormTouch(getFormTouchState({ touched: true }));
      }
    },
    [role, password, storeId, action, resetForm, setFormTouch]
  );

  /**
   * Checks if the customer is logged in and the session is NOT expired
   */
  useIonViewWillEnter(() => {
    // If I have a token in the state,
    // I'll check if the token is valid
    if (stateToken && stateRole) {
      action(REFRESH_TOKEN, {
        role: stateRole,
        storeId: stateStoreId,
        clientId: stateClientId,
      }).then((response: any) => {
        if (response && response.status === 200) {
          redirectToAdmin(stateRole);
        } else {
          action(SET_LOADING, { loading: false });
        }
      });
    } else {
      // Otherwise, just hide the loader
      action(SET_LOADING, { loading: false });
    }
  }, [stateToken, stateClientId, stateStoreId, stateRole]);

  useIonViewWillEnter(() => {
    const clientCode = getClientCodeFromUrl();
    action(GET_CLIENT_ALL_STORES_IDS, { clientCode });
  }, []);

  useEffect(() => {
    if (role !== CLIENT_ADM_ROLE && clientAllStoresIds.length === 0) {
      notify({
        message: 'There are no stores currently active!',
        color: 'warning',
        duration: 4000,
      });
    }
  }, [role, clientAllStoresIds]);

  if (stateIsLoading) {
    return <Loader />;
  }

  return (
    <div className="login-form-wrapper">
      <h1 className="login-form-title center">Login</h1>
      <form className="login-form" onSubmit={handleSubmit}>
        <fieldset className="login-form-fieldset">
          <div className="form-field-wrapper">
            <IonLabel className="form-field-label" position="fixed">
              Role
            </IonLabel>
            <IonSelect
              className="form-field-select"
              id="role"
              name="role"
              value={role}
              interface="popover"
              interfaceOptions={{
                lines: 'none',
                cssClass: 'login-form-select form-field-select-content',
              }}
              onIonChange={(e) => setValue('role', setRole, e.detail.value)}
            >
              <IonSelectOption value={CLIENT_ADM_ROLE}>Admin</IonSelectOption>
              <IonSelectOption value={DISPATCHER_ROLE}>
                Dispatcher
              </IonSelectOption>
              <IonSelectOption value={ASSOCIATE_ROLE}>
                Sales Associate
              </IonSelectOption>
            </IonSelect>
            {formTouch.role.touched && !role && (
              <span className="error-feedback">Please select a role</span>
            )}
          </div>
          {role !== CLIENT_ADM_ROLE && (
            <div className="form-field-wrapper">
              <label className="form-field-label" htmlFor="storeId">
                Store ID
              </label>
              <IonSelect
                className="form-field-select"
                id="storeId"
                name="storeId"
                value={storeId}
                disabled={clientAllStoresIds.length === 0}
                interface="popover"
                placeholder="Select store id"
                interfaceOptions={{
                  lines: 'none',
                  cssClass: 'login-form-select form-field-select-content',
                }}
                onIonChange={(e) =>
                  setValue('storeId', setStoreId, e.detail.value)
                }
              >
                {clientAllStoresIds.map((item: any) => (
                  <IonSelectOption value={item} key={`storeId-${item}`}>
                    {item}
                  </IonSelectOption>
                ))}
              </IonSelect>
              {formTouch.storeId.touched && !storeId && (
                <span className="error-feedback">
                  Store ID is required for Dispatchers and Team members
                </span>
              )}
            </div>
          )}
          <div className="form-field-wrapper">
            <div className="d-flex">
              <label className="form-field-label" htmlFor="password">
                Password
              </label>
              {role === CLIENT_ADM_ROLE && (
                <Anchor
                  className="change-password-link"
                  href="#"
                  onClick={(event: Event) => {
                    event.preventDefault();
                    setShowAlert(true);
                  }}
                >
                  Reset password
                </Anchor>
              )}
              <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={'Confirm Reset!'}
                message={'Are you sure want to reset password?'}
                buttons={[
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                      setShowAlert(false);
                    },
                  },
                  {
                    text: 'Ok',
                    handler: () => {
                      resetClientPassword();
                    },
                  },
                ]}
              />
            </div>
            <input
              className="form-field-input"
              id="password"
              name="password"
              type="password"
              value={password}
              placeholder={PLACEHOLDER_MESSAGE_MAP[role]}
              onChange={(e) =>
                setValue('password', setPassword, e.target.value)
              }
            />
            {formTouch.password.touched && !password && (
              <span className="error-feedback">Password is required</span>
            )}
          </div>
          <div className="form-submit-wrapper">
            <button
              type="submit"
              disabled={isSubmitting}
              className="submit-button button-primary not-ionic"
            >
              {isSubmitting ? (
                <Loader color="light" withMinHeight={false} />
              ) : (
                'LOGIN'
              )}
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};
export default CPUnifiedLogin;
