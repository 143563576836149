import React from 'react';
import { useSelector } from 'react-redux';
import { getOrders } from 'selectors/orders';
import { IonIcon } from '@ionic/react';
import { useIntl } from 'react-intl';

import './styles.scss';
import { unixToLocalTime } from 'utils/timeUtils';
import Anchor from 'components/DesignSystem/Anchor';

interface IPopupListProps {
  customerReadyNotifications?: any;
  closePopup: any;
  onViewOrderClick:any;
}
const PopupList: React.FC<IPopupListProps> = ({
  customerReadyNotifications,
  closePopup,
  onViewOrderClick
}) => {
  const intl = useIntl();
  const orders = useSelector(getOrders);

  return customerReadyNotifications.map((item: any) => {
    const order = orders[item];
    return (
      <div className="popup-card" key={item}>
        <div className="d-flex ion-justify-content-between">
          {order && (
            <p className="card-paragraph order-id">{intl.formatMessage({ id: 'notifications.updateOnOrder', defaultMessage: 'UPDATE ON ORDER #' })}{order.externalId}</p>
          )}
          <Anchor
            href="#"
            className="view-order-popup-close-link"
            onClick={(event: Event) => {
              event.preventDefault();
              closePopup(order.id);
            }}
          >
            <IonIcon
              class="view-order-popup-close-icon"
              src="/assets/icon/whiteCross.svg"
            />
          </Anchor>
        </div>
        <p className="card-paragraph supporting-text">{intl.formatMessage({ id: 'notifications.customerReady', defaultMessage: 'Customer is ready to pick up!' })}</p>
        <div className="d-flex ion-justify-content-between">
          {order && (
            <p className="card-paragraph details-text">
              {intl.formatMessage({ id: 'notifications.updatedAt', defaultMessage: 'Updated at:' })} {unixToLocalTime(order.lastEventDate)}
            </p>
          )}
          <Anchor
            href="#"
            className="view-order-popup-link"
            onClick={(event: Event) => onViewOrderClick(event, order.id)}
          >
            {intl.formatMessage({ id: 'notifications.viewOrder', defaultMessage: 'View Order' })}
          </Anchor>
        </div>
      </div>
    );
  });
};
export default PopupList;
