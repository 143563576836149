import React, { useEffect, useContext } from 'react';
import { IonPage, IonGrid, IonRow, IonCol, IonContent } from '@ionic/react';
import { useSelector } from 'react-redux';
import Loader from 'components/DesignSystem/Loader';
import { StoreContext } from 'contexts';
import { SET_LOADING, FETCH_ORIGINAL_URL } from 'actions/actionTypes';

import { useParams, useHistory, Redirect } from 'react-router';
import { getClientLoaded } from 'selectors/client';

import './CustomerEntryPoint.scss';

interface PathParams {
  urlToken: string;
}

const CustomerEntryPoint = () => {
  const { action } = useContext(StoreContext);
  const clientLoaded = useSelector(getClientLoaded);
  const history = useHistory();
  const { urlToken } = useParams<PathParams>();

  useEffect(() => {
    if (!clientLoaded) {
      return;
    }

    action(FETCH_ORIGINAL_URL, { urlToken }).then((res: any = {}) => {
      if (res && res.status === 200 && res.data.redirectUrl) {
        history.push(res.data.redirectUrl);
      } else {
        action(SET_LOADING, { loading: false });
        history.push('/404');
      }
    });
  }, [clientLoaded]);

  if (!urlToken) {
    return <Redirect to="/404" />;
  }

  return (
    <IonPage className="customer-entry-point-page">
      <IonContent scrollEvents={true}>
        <IonGrid className="reset-padding">
          <IonRow className="oe-container">
            <IonCol className="reset-padding">
              <Loader className="page-loader" />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CustomerEntryPoint;
