import { all } from 'redux-saga/effects';
import { takeLeading } from 'utils/sagaUtils';

import * as sagas from 'actions/sagas';
import * as types from 'actions/actionTypes';

export default function* rootSaga() {
  yield all([
    takeLeading(types.INIT_SAGA, sagas.initSaga),
    takeLeading(types.INJECT_HISTORY, sagas.injectHistory),
    takeLeading(types.LOGOUT_SAGA, sagas.logoutSaga),
    takeLeading(types.FETCH_ORDERS_FILTERED, sagas.fetchFilteredOrders),
    takeLeading(types.FETCH_ORDERS_POLLING, sagas.ordersPolling),
    takeLeading(types.UPDATE_ORDER, sagas.updateOrderSaga),
    takeLeading(types.UPDATE_ORDER_DETAILS, sagas.updateOrderDetailsSaga),
    takeLeading(types.FETCH_ORDER_DETAILS, sagas.fetchOrderDetailsSaga),
    takeLeading(types.GET_SUPPORTED_COUNTRIES, sagas.getSupportedCountriesSaga),
    takeLeading(types.REFRESH_TOKEN, sagas.refreshToken),
    takeLeading(types.LOGIN_SAGA, sagas.submitLogin),
    takeLeading(types.IMPORT_CLIENT_ADMIN_STORES_FILE, sagas.importStoresFile),
    takeLeading(
      types.IMPORT_CLIENT_ADMIN_CUSTOMERS_FILE,
      sagas.importCustomersFile
    ),
    takeLeading(
      types.IMPORT_CUSTOMERS_PHONE_OPTED_OUT_FILE,
      sagas.importOptedOutPhonesFile
    ),
    takeLeading(
      types.UPDATE_CUSTOMER_READY_POP_UPS,
      sagas.updateCustomerReadyPopUps
    ),
    takeLeading(types.ADD_ORDER, sagas.addOrderSaga),
    // Customer Sagas
    takeLeading(types.FETCH_CUSTOMER_ORDER, sagas.fetchCustomerOrder),
    takeLeading(types.CREATE_CUSTOMER_EVENT, sagas.submitCustomerEvent),
    takeLeading(types.CUSTOMER_ORDER_RECEIVED, sagas.customerOrderReceived),
    //client saga
    takeLeading(types.FETCH_CLIENT, sagas.fetchClientDetailsSaga),
    takeLeading(
      types.CLIENT_REQUEST_PASSWORD_RESET,
      sagas.requestPasswordResetSaga
    ),
    takeLeading(
      types.GET_CLIENT_ALL_STORES_IDS,
      sagas.getClientAllStoresIdsSaga
    ),
    takeLeading(types.CLIENT_UPDATE_PASSWORD, sagas.updateResetPasswordSaga),
    takeLeading(types.GET_STORE_DETAILS, sagas.getStoreDetailsSaga),
    takeLeading(types.UPDATE_CLIENT_DETAILS, sagas.updateClientDetailsSaga),
    takeLeading(types.FETCH_CLIENT_STORES, sagas.fetchClientStoresSaga),
    takeLeading(types.CREATE_CLIENT_STORE, sagas.createClientStoreSaga),
    takeLeading(types.UPDATE_STORE_STATUS, sagas.updateStoreStatusSaga),
    takeLeading(types.UPDATE_CLIENT_STORE, sagas.updateClientStoresSaga),
    takeLeading(types.FETCH_ORIGINAL_URL, sagas.fetchOriginalUrl),
    takeLeading(types.SET_STORE_LANGUAGE, sagas.setStoreLanguage),
    takeLeading(types.FETCH_STORE_DETAILS_BY_ID, sagas.fetchStoreDetailsSaga),
  ]);
}
