import { call, put, select, all, delay } from 'redux-saga/effects';
import { DEFAULT_ORDER_POLL_TIME } from 'configs/config';
import {
  fetchOrders,
  addOrder,
  updateOrder,
  getCustomerOrder,
  sendCustomerEvent,
  fetchOrderDetails,
  updateOrderDetails,
} from 'actions/apis/orders';
import {
  FETCH_ORDERS_RECEIVED,
  FETCH_ORDERS_POLLING,
  SET_CUSTOMER_ORDER,
  SET_SESSION,
  SET_CUSTOMER_READY_POP_UPS,
  SET_ORDER_STATE,
  SET_ORDER_FILTER,
} from 'actions/actionTypes';
import {
  getCustomerOrder as readCustomerOrder,
  getOrders,
  getRequestFilters,
  getCurbPollingPaused,
} from 'selectors/orders';
import {
  ORDER_STATE_DELIVERED,
  ORDER_STATE_PENDING_PICKUP,
  ORDER_STATE_CUSTOMER_READY,
  ORDER_STATE_UNDER_PICKUP,
  ORDER_STATE_OUT_FOR_CURBSIDE,
} from 'constants/orderStates';

import {
  getStoreId,
  getClientId,
  getCurrentStore,
  getShortLiveToken,
  getToken,
} from 'selectors/session';
import { minutesFromMidnight } from 'utils/timeUtils';
import { getStoreDetailsForCustomer } from 'actions/apis/store';
import { getCustomerReadyNotifications } from 'selectors/orders';

export function* ordersPolling(action) {
  while (true) {
    const token = yield select(getToken);
    const isPaused = yield select(getCurbPollingPaused);

    if (!token) {
      return;
    }
    if (!isPaused) {
      yield call(fetchOrdersSaga);
    }
    yield delay(DEFAULT_ORDER_POLL_TIME);
  }
}

export function* fetchOrdersSaga() {
  const clientId = yield select(getClientId);
  const storeId = yield select(getStoreId);
  const { isFirstRequest, ...filters } = yield select(getRequestFilters);
  const pathParams = { clientId, storeId };

  let response = {
    status: 200,
    data: {
      items: [],
    },
  };

  if (!isFirstRequest) {
    response = yield fetchOrders({
      ...pathParams,
      // Query Params
      ...filters,
    });
  } else {
    const [fullTableOrdersResponse, onlyMidnightOrdersResponse] = yield all([
      // Load all orders on the states below (first request only)
      call(fetchOrders, {
        ...pathParams,
        statesFilter: [
          ORDER_STATE_OUT_FOR_CURBSIDE,
          ORDER_STATE_CUSTOMER_READY,
          ORDER_STATE_PENDING_PICKUP,
          ORDER_STATE_UNDER_PICKUP,
        ].join(','),
      }),
      // Load all orders for the current day for 'COMPLETED' state
      call(fetchOrders, {
        ...pathParams,
        statesFilter: ORDER_STATE_DELIVERED,
        timeRangeInMinutes: minutesFromMidnight(),
      }),
    ]);

    if (fullTableOrdersResponse && fullTableOrdersResponse.status === 200) {
      response.data.items = [
        ...response.data.items,
        ...fullTableOrdersResponse.data.items,
      ];
    }

    if (
      onlyMidnightOrdersResponse &&
      onlyMidnightOrdersResponse.status === 200
    ) {
      response.data.items = [
        ...response.data.items,
        ...onlyMidnightOrdersResponse.data.items,
      ];
    }
  }
  if (response && response.status === 200) {
    const allOrders = response.data.items || [];

    // Current Orders in the state (before update)
    const currentOrders = yield select(getOrders);
    const newOrders = allOrders.reduce((result, order) => {
      result[order.id] = order;
      return result;
    }, {});

    // Check for new pop ups based on old orders and new orders
    const movedToCustomerReady = getOrdersMovedToReady(
      currentOrders,
      newOrders
    );

    // Merge orders in the state
    yield put({
      type: FETCH_ORDERS_RECEIVED,
      payload: { orders: { ...currentOrders, ...newOrders } },
    });

    // Retrieve updated list of orders
    const updatedOrders = yield select(getOrders);
    const currentPopUps = yield select(getCustomerReadyNotifications);

    // Get the orders that were moved to the next state
    // and hide their notifications
    const withoutMovedOrders = currentPopUps.filter(
      (orderId) =>
        updatedOrders[orderId] &&
        updatedOrders[orderId].state.stateName === ORDER_STATE_CUSTOMER_READY
    );

    // Update pop ups
    yield put({
      type: SET_CUSTOMER_READY_POP_UPS,
      payload: {
        customerReadyPopUps: [...withoutMovedOrders, ...movedToCustomerReady],
      },
    });
  }

  yield put({
    type: SET_ORDER_FILTER,
    payload: {
      filters: {
        ...filters,
        timeRangeInMinutes: 10,
        isFirstRequest: false,
      },
    },
  });
}

export function* fetchOrderDetailsSaga(action) {
  const clientId = yield select(getClientId);
  const storeId = yield select(getStoreId);
  const { orderId } = action.payload || {};

  return yield call(fetchOrderDetails, { clientId, storeId, orderId });
}

export function* addOrderSaga(action) {
  try {
    const clientId = yield select(getClientId);
    const storeId = yield select(getStoreId);
    const payload = { clientId, storeId, ...action.payload };

    const response = yield call(addOrder, payload);

    if (response && response.status === 200) {
      yield put({
        type: FETCH_ORDERS_POLLING,
        payload: {
          timeRangeInMinutes: 10, //recent 10 minutes data
        },
      });

      action.meta.resolve(response);
    } else {
      action.meta.reject(response);
    }
  } catch (error) {
    action.meta.reject(error);
  }
}

export function* updateOrderSaga(action) {
  const clientId = yield select(getClientId);
  const storeId = yield select(getStoreId);

  try {
    // "action.payload" should contain at least the orderId
    // since it's required to send the event request
    const payload = { clientId, storeId, ...action.payload };

    // call api for login
    const response = yield call(updateOrder, payload);

    // In case of success, call the fetch API again
    if (response && response.status === 200) {
      // Async fetch of the update list of orders
      yield put({
        type: FETCH_ORDERS_POLLING,
        payload: {
          timeRangeInMinutes: 10, //recent 10 minutes data
        },
      });

      action.meta.resolve(response);
    } else {
      action.meta.reject(response);
    }
  } catch (error) {
    action.meta.reject(error);
  }
}

export function* fetchCustomerOrder(action) {
  const { payload } = action;
  const store = yield select(getCurrentStore);

  const response = yield call(getCustomerOrder, { ...payload });

  if (response && response.status === 200) {
    // Since we need more store information than
    // Order Details API sends, we have to get store details
    if (!store) {
      const storeResponse = yield call(getStoreDetailsForCustomer, {
        ...payload,
      });

      if (storeResponse && storeResponse.status === 200) {
        yield put({
          type: SET_SESSION,
          payload: {
            // Add the shortLiveToken to the state along
            // with the current store
            shortLiveToken: payload.token,
            currentStore: storeResponse.data,
          },
        });
      }
    }

    // Flatten order info to the root object
    const order = { ...response.data };

    // Add storeId to the root object
    if (!order.storeId && order.storeInfo && order.storeInfo.storeId) {
      order.storeId = order.storeInfo.storeId;
    }

    // Add clientId to the root object
    if (!order.clientId && order.clientInfo && order.clientInfo.clientId) {
      order.clientId = order.clientInfo.clientId;
    }

    // Manually sort the events based on the createdOn date
    order.events = (order.events || []).sort((evt1, evt2) => {
      return evt1.createdOn > evt2.createdOn ? 1 : -1;
    });

    yield put({ type: SET_CUSTOMER_ORDER, payload: order });
  }

  return response;
}

export function* submitCustomerEvent(action) {
  const { clientId, storeId, id: orderId } = yield select(readCustomerOrder);
  const token = yield select(getShortLiveToken);
  // Payload should have the event type and props when applicable
  const data = { body: action.payload, clientId, storeId, orderId, token };
  const response = yield call(sendCustomerEvent, data);

  if (response && response.status === 200) {
    // This will fetch the details again and update
    // the order state. We don't need to do it twice
    yield call(fetchCustomerOrder, {
      payload: {
        clientId,
        storeId,
        orderId,
        token,
      },
    });
  }

  const order = yield select(readCustomerOrder);

  return { ...response, data: order };
}

/**
 * Customer's manual aknowledgement - still needs
 * Associate/Dispatcher confirmation - manual state change since no API is called
 */
export function* customerOrderReceived() {
  const order = yield select(getCustomerOrder);

  yield put({
    type: SET_CUSTOMER_ORDER,
    payload: { ...order, state: ORDER_STATE_DELIVERED },
  });
}

function getOrdersMovedToReady(currentOrders, newOrders) {
  // When the order was "PENDING" and now is "READY",
  // we should present the alert
  return Object.keys(currentOrders)
    .filter(
      (id) => currentOrders[id].state.stateName === ORDER_STATE_PENDING_PICKUP
    )
    .filter(
      (id) =>
        newOrders[id] &&
        newOrders[id].state.stateName === ORDER_STATE_CUSTOMER_READY
    );
}
export function* updateOrderDetailsSaga(action) {
  const clientId = yield select(getClientId);
  const storeId = yield select(getStoreId);
  const { body, orderId } = yield action.payload;
  const response = yield call(updateOrderDetails, {
    clientId,
    storeId,
    orderId,
    body,
  });
  if (response.status === 200) {
    yield put({
      type: FETCH_ORDERS_POLLING,
      payload: {
        timeRangeInMinutes: 10, //recent 10 minutes data
      },
    });
  }
  return response;
}

export function* fetchFilteredOrders(action) {
  const currentFilters = yield select(getRequestFilters);
  const { pauseCurbPolling, ...filterData } = action.payload;

  yield put({
    type: SET_CUSTOMER_READY_POP_UPS,
    payload: {
      customerReadyPopUps: [],
    },
  });

  yield put({
    type: FETCH_ORDERS_RECEIVED,
    payload: { orders: {} },
  });

  yield put({
    type: SET_ORDER_STATE,
    payload: {
      pauseCurbPolling,
    },
  });

  yield put({
    type: SET_ORDER_FILTER,
    payload: {
      filters: {
        ...currentFilters,
        ...filterData.filters,
      },
    },
  });

  yield call(fetchOrdersSaga);
}
