import { request } from 'utils/restClient';
import {
  REFRESH_TOKEN_API,
  DISPATCHER_GET_STORE_DETAILS,
} from 'constants/endpoints';

export function refreshToken() {
  return request(REFRESH_TOKEN_API, {});
}

export const getStoreDetails = ({ clientId, storeId }) => {
  return request(DISPATCHER_GET_STORE_DETAILS, {
    pathParams: { clientId, storeId },
  });
};
