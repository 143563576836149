import React from 'react';
import { IonText, IonButton, IonRow, IonCol, IonIcon } from '@ionic/react';
import Anchor from 'components/DesignSystem/Anchor';
import { checkmark, close } from 'ionicons/icons';
import { SUPPORTED_LANGUAGES, DEFAULT_APP_LANGUAGE } from 'constants/defaults';

interface StoreInfoCardProps {
  store: any;
  editStoreDetails: Function;
  updateClientStore: Function;
  isActivatable: boolean;
}
interface SupportedLanguages {
  [key: string]: any;
}

const StoreInfoCard: React.FC<StoreInfoCardProps> = ({
  store,
  editStoreDetails,
  updateClientStore,
  isActivatable,
}) => {
  const {
    id,
    address,
    noOfDaysToHoldOrder,
    phone,
    zipCode,
    active,
    deliveryMethods = [],
    phoneForAlerts,
    defaultLanguage,
  } = store;
  const supportedLanguages:SupportedLanguages = SUPPORTED_LANGUAGES;

  return (
    <IonRow
      className={`store-card ${!active ? 'inactive-store' : ''}`}
      key={id}
    >
      <IonCol className="ion-no-padding" sizeXs="12">
        <IonText className="label-text">Store Id</IonText>
        <div>
          <IonText className="store-id-value-text">{id} </IonText>
        </div>
      </IonCol>
      <IonCol className="ion-no-padding" sizeXs="12">
        <IonText className="label-text">Address</IonText>
        <div>
          <IonText className="value-text">{address} </IonText>
        </div>
      </IonCol>
      <IonCol className="ion-no-padding" sizeXs="5">
        <IonText className="label-text">Zip code</IonText>
        <div>
          <IonText className="value-text">{zipCode} </IonText>
        </div>
      </IonCol>
      <IonCol className="ion-no-padding" sizeXs="7">
        <IonText className="label-text">Phone</IonText>
        <div>
          <IonText className="value-text">{phone}</IonText>
        </div>
      </IonCol>
      <IonCol className="ion-no-padding" sizeXs="12">
        <IonText className="label-text">hold order for</IonText>
        <div className="display-inline">
          <IonText className="value-text">{noOfDaysToHoldOrder} days</IonText>
        </div>
      </IonCol>
      <IonCol className="ion-no-padding" sizeXs="12">
        <IonText className="label-text">Default Language</IonText>
        <div className="display-inline">
          <IonText className="value-text">
            {supportedLanguages[defaultLanguage || DEFAULT_APP_LANGUAGE].placeholder}
          </IonText>
        </div>
      </IonCol>
      <IonCol className="ion-no-padding" sizeXs="12">
        <IonText className="label-text">Phone Number for Alerts</IonText>
        <div>
          <IonText className="value-text">{phoneForAlerts}</IonText>
        </div>
      </IonCol>
      {deliveryMethods.length ? (
        <IonCol className="ion-no-padding" sizeXs="12">
          <IonText className="label-text">Enablement</IonText>
          <div className="available-methods-container d-flex">
            {deliveryMethods.map((item: any) => (
              <React.Fragment key={item.deliveryMethod.id}>
                {item.deliveryMethod.active ? (
                  <IonIcon
                    icon={checkmark}
                    color="success"
                    className="delivery-method-icons"
                  ></IonIcon>
                ) : (
                  <IonIcon
                    icon={close}
                    color="danger"
                    className="delivery-method-icons"
                  ></IonIcon>
                )}
                <IonText className="value-text delivery-method-text">
                  {item.deliveryMethod.id}
                </IonText>
              </React.Fragment>
            ))}
          </div>
        </IonCol>
      ) : null}
      <IonCol sizeXs="12" className="ion-no-padding button-align">
        {active ? (
          <IonButton
            color="dark"
            mode="md"
            onClick={() => editStoreDetails(store)}
            className="edit-buttons"
            disabled={!active}
          >
            Edit Store
          </IonButton>
        ) : (
          <IonButton
            color="dark"
            mode="md"
            onClick={() => updateClientStore(id, 'activate')}
            className="edit-buttons"
            disabled={isActivatable}
          >
            Reactivate
          </IonButton>
        )}
        {active && (
          <Anchor
            href="#"
            className="deactivate-link"
            onClick={(event: Event) => {
              event.preventDefault();
              updateClientStore(id, 'inactivate');
            }}
          >
            Deactivate
          </Anchor>
        )}
      </IonCol>
    </IonRow>
  );
};

export default StoreInfoCard;
