import { SET_SESSION, WIPE_SESSION } from 'actions/actionTypes';
import { unknownReducer } from './utils';
import { CURBY_LS_AUTH_TOKEN } from '../constants/defaults';

import type { Action } from 'redux';

export type SessionState = {
  appToken?: string;
  token: string;
  storeId?: string;
  clientId: string;
  currentStore?: any;
  shortLiveToken?: string; // Short-live token for customers
  currentLanguage?: any;
};

const updateLanguageReducer = (state: any, action: any) => {
  const newState = { ...state, ...action.payload };
  localStorage.setItem(CURBY_LS_AUTH_TOKEN, JSON.stringify(newState));
  return newState;
}

const wipeSession = () => {
  localStorage.setItem(CURBY_LS_AUTH_TOKEN, '');
  return {};
};

const reducerMap: any = {
  [SET_SESSION]: updateLanguageReducer,
  [WIPE_SESSION]: wipeSession,
};

export default (state = {}, action: Action) => {
  const reducer = reducerMap[action.type] || unknownReducer;

  return reducer(state, action);
};
