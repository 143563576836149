import { call, put, select } from 'redux-saga/effects';
import { SET_HISTORY, SET_SESSION, WIPE_SESSION } from 'actions/actionTypes';
import { getRole, getClientId, getStoreId } from 'selectors/session';
import { CURBY_LS_AUTH_TOKEN, LOCAL_STORE_CURRENT_LAGUAGE } from 'constants/defaults';
import { getHistory } from 'selectors/navigation';
import {
  refreshToken as refreshTokenApi,
  getStoreDetails,
} from 'actions/apis/session';

export function* initSaga() {
  let storageSession = null;
  let payload = {};

  try {
    storageSession = JSON.parse(localStorage.getItem(CURBY_LS_AUTH_TOKEN));
  } catch (e) {}

  // TODO: decode auth token to read JWT info
  if (storageSession && storageSession.token) {
    payload = { ...storageSession };
  }

  yield put({ type: SET_SESSION, payload });
}

export function* injectHistory(action) {
  const { payload } = action;

  // Add history to the state
  if (payload.history) {
    yield put({ type: SET_HISTORY, payload });
  }
}

export function* refreshToken(action) {
  let response = { status: 401 };

  try {
    response = yield call(refreshTokenApi);

    if (response && response.status === 200) {
      // Read info from payload
      const { clientId, storeId, role } = action.payload || {};

      // Update local storage
      const state = { clientId, storeId, role, ...response.data };

      localStorage.setItem(CURBY_LS_AUTH_TOKEN, JSON.stringify(state));

      yield put({
        type: SET_SESSION,
        payload: state,
      });
    }
  } catch (e) {
    console.log({ error: e });
  }

  return response;
}

export function* logoutSaga(action) {
  const history = yield select(getHistory);
  const storedRole = yield select(getRole);

  const { role = storedRole } = action.payload || {};

  // Just wipeout the token
  localStorage.removeItem(CURBY_LS_AUTH_TOKEN);
  localStorage.removeItem('currentLanguage');
  localStorage.removeItem(LOCAL_STORE_CURRENT_LAGUAGE);

  // Update the state
  yield put({ type: WIPE_SESSION });

  // Send to login page related to the customer role
  // only if the page is not the actual login page
  if (history.location.pathname !== '/login') {
    history.push('/login');
  }

  return { role };
}

export function* getStoreDetailsSaga(action) {
  const clientId = yield select(getClientId);
  const storeId = yield select(getStoreId);
  const response = yield call(getStoreDetails, { clientId, storeId });
  if (response.status === 200) {
    yield put({
      type: SET_SESSION,
      payload: { currentStore: response.data },
    });
  }
}
