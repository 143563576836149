import React from 'react';
import { FormattedMessage } from 'react-intl';
import { alertCircle } from 'ionicons/icons';
import { useSelector } from 'react-redux';

import Anchor from 'components/DesignSystem/Anchor';
import { getCurrentStore } from 'selectors/session';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsInfo from './OrderDetailsInfo';
import OrderDetailsPickupLocation from './OrderDetailsPickupLocation';
import OrderDetailsContactStore from './OrderDetailsContactStore';
import OrderDetailsUpdateDetails from './OrderDetailsUpdateDetails';

interface ComponentProps {
  order: any;
}

interface AdditionalDescriptionProps {
  phoneNumber: any;
}

const AdditionalDescription: React.FC<AdditionalDescriptionProps> = ({
  phoneNumber,
}) => {
  return (
    <>
      <FormattedMessage
        id="order.s4.additional_desc"
        defaultMessage={'If you have any trouble, please contact our store at' }
      />
      {' '}
      <Anchor href={`tel:${phoneNumber}`} className="default-link d-inline-block">
        {phoneNumber}
      </Anchor>
      .
    </>
  );
}

const OrderDetailsS4: React.FC<ComponentProps> = ({ order }) => {
  const storeInfo = useSelector(getCurrentStore);
  return (
    <>
      <OrderDetailsHeader order={order} />
      <div className="default-wrapper">
        <OrderDetailsInfo
          icon={alertCircle}
          iconColor="warning"
          title={
            <FormattedMessage
              id="order.s4.title"
              defaultMessage="Sorry we couldn't find you!"
            />
          }
          subtitle={
            <FormattedMessage
              id="order.s4.subtitle"
              defaultMessage="Update your location details. Please be specific so we can find you easily."
            />
          }
          additionalDesc={
            <AdditionalDescription phoneNumber={storeInfo.phone} />
          }
        />
      </div>
      <div className="default-wrapper">
        <OrderDetailsUpdateDetails order={order} />
        <OrderDetailsContactStore order={order} />
      </div>
      <OrderDetailsPickupLocation order={order} />
    </>
  );
};

export default OrderDetailsS4;
