import React from 'react';
import { withSize } from 'react-sizeme';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import { ViewPortContext } from 'contexts';
import CPClientAdminProfile from 'components/CPClientAdminProfile';
import CPFooter from 'components/CPFooter';

import CPClientLogo from 'components/CPClientLogo';

interface ClientAdminProfileprops {
  size: object;
}
const ClientAdminProfile: React.FC<ClientAdminProfileprops> = ({ size }) => {
  return (
    <ViewPortContext.Provider value={size}>
      <IonPage className="client-admin-page">
        <IonContent>
          <IonGrid>
            <IonRow className="oe-container">
              <IonCol size="12">
                <CPClientLogo />
                <CPClientAdminProfile />
                <CPFooter />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </ViewPortContext.Provider>
  );
};

export default withSize()(ClientAdminProfile);
