import React, { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { IntlProvider } from 'react-intl';
import Home from './pages/Home';
import CustomerOrderDetails from './pages/CustomerOrderDetails';
import ClientAdminHome from './pages/ClientAdminHome';
import ClientAdminProfile from './pages/ClientAdminProfile';
import Notification from 'components/Notification';
import ClientAdminStore from './pages/ClientAdminStore';
import {
  INIT_SAGA,
  NOTIFY,
  INJECT_HISTORY,
  FETCH_CLIENT,
} from 'actions/actionTypes';
import { getClientCodeFromUrl } from 'utils/urlUtils';
import getTranslations from 'translations';

import { Provider, useSelector } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { getStoreDefaultLanguage, getSessionLanguage } from 'selectors/session';
import { getCustomerOrder } from 'selectors/orders';

import { StoreContext, StoreContextI } from 'contexts/index';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/app.scss';

import reducer from 'reducers';
import rootSaga from 'actions/actions';
import { logger, promisfiedAction } from 'middlewares';
import Customer404Page from 'pages/Customer404Page';
import UnifiedLogin from 'pages/UnifiedLogin';
import ClientResetPassword from 'pages/ClientResetPassword';
import CustomerEntryPoint from 'pages/CustomerEntryPoint';
import { DEFAULT_APP_LANGUAGE } from 'constants/defaults';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducer,
  applyMiddleware(promisfiedAction, logger, sagaMiddleware)
);
sagaMiddleware.run(rootSaga);

// Read initial state from LS
store.dispatch({ type: INIT_SAGA });

/**
 * Includes Promisified action
 */
const action = (type: string, payload: any, ...meta: any) =>
  store.dispatch({ type, payload, ...meta });

const notify = (config: any) => {
  store.dispatch({ type: NOTIFY, payload: config });
};
const contextValue: StoreContextI = { action, notify };

const RouterWrapper = () => {
  const history = useHistory();

  useEffect(() => {
    //fetch client code from location or use default
    const clientCode = getClientCodeFromUrl();

    // Initial state read and including history in the state
    store.dispatch({ type: INJECT_HISTORY, payload: { history } });
    store.dispatch({ type: FETCH_CLIENT, payload: { clientCode } });
  }, []);

  return (
    <IonRouterOutlet>
      <Route
        path="/clients/:clientId/stores/:storeId/orders/:orderId"
        component={CustomerOrderDetails}
        exact={true}
      />
      <Route
        path="/orders/:urlToken"
        component={CustomerEntryPoint}
        exact={true}
      />
      <Route path="/login" component={UnifiedLogin} exact={true} />
      <Route
        path="/reset-password"
        component={ClientResetPassword}
        exact={true}
      />
      <Route
        exact
        path="/admin/login"
        render={() => <Redirect to="/login" />}
      />
      <Route
        exact
        path="/manager/login"
        render={() => <Redirect to="/login" />}
      />
      <Route path="/admin/home" component={ClientAdminHome} exact={true} />
      <Route path="/admin/stores" component={ClientAdminStore} exact={true} />
      <Route
        path="/admin/account"
        component={ClientAdminProfile}
        exact={true}
      />
      <Route
        exact
        path="/admin"
        render={() => <Redirect to="/admin/stores" />}
      />
      <Route path="/manager/home" component={Home} exact={true} />
      <Route exact path="/" render={() => <Redirect to="/manager/login" />} />
      <Route path="/404" component={Customer404Page} exact={true} />
      <Redirect to="/404" />
    </IonRouterOutlet>
  );
};

const IntlWrapper = ({ children }: any) => {
  const currentOrder = useSelector(getCustomerOrder);
  const orderLanguage = currentOrder ? currentOrder.orderLanguage : null;
  const sessionLanguage = useSelector(getSessionLanguage);
  const storeDefaultLanguage = useSelector(getStoreDefaultLanguage);

  const language = sessionLanguage || orderLanguage || storeDefaultLanguage;

  return (
    <IntlProvider
      messages={getTranslations(language)}
      locale={language}
      defaultLocale={DEFAULT_APP_LANGUAGE}
    >
      {children}
    </IntlProvider>
  );
};

const App: React.FC = () => {
  return (
    <StoreContext.Provider value={contextValue}>
      <Provider store={store}>
        <IntlWrapper>
          <IonApp>
            <IonReactRouter>
              <RouterWrapper />
            </IonReactRouter>
            <Notification></Notification>
          </IonApp>
        </IntlWrapper>
      </Provider>
    </StoreContext.Provider>
  );
};

export default App;
