import { request } from 'utils/restClient';

import {
  CLIENT_ADMIN_IMPORT_CUSTOMERS,
  CLIENT_ADMIN_IMPORT_STORES,
  CLIENT_ADMIN_IMPORT_OPTED_OUT_PHONES,
} from 'constants/endpoints';

const sendUploadRequest = ({ endpoint, file, clientId, storeId }) => {
  const formData = new FormData();
  formData.append('csvFile', file);

  return request(endpoint, {
    pathParams: { clientId, storeId },
    headers: { 'Content-Type': 'multipart/form-data' },
    body: formData,
  });
};

export function uploadCustomersFile({ clientId, file }) {
  return sendUploadRequest({
    endpoint: CLIENT_ADMIN_IMPORT_CUSTOMERS,
    clientId,
    file,
  });
}

export function uploadStoresFile({ clientId, file }) {
  return sendUploadRequest({
    endpoint: CLIENT_ADMIN_IMPORT_STORES,
    clientId,
    file,
  });
}

export function uploadOptedOutPhonesFile({ clientId, file }) {
  return sendUploadRequest({
    endpoint: CLIENT_ADMIN_IMPORT_OPTED_OUT_PHONES,
    clientId,
    file,
  });
}
