import React from 'react';
import { IonImg } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import './CPFooter.scss';
import Anchor from 'components/DesignSystem/Anchor';

interface ContainerProps {
  align?: string;
}

type Item = {
  src: string;
  text: string;
};
const image: Item = {
  src:
    'https://media.glassdoor.com/sqll/260007/object-edge-squarelogo-1574712600216.png',
  text: 'Objectedge Inc',
};

const CPFooter: React.FC<ContainerProps> = ({ align = 'center' }) => {
  return (
    <div className={`cp-footer-wrapper ${align}`}>
      <p className="copyright-text">
        <FormattedMessage
          id="footer.powered_by"
          defaultMessage="Powered By"
        />
      </p>
      <IonImg src={image.src} />
      <p className="copyright-text">
        Copyright ©2020 Object Edge Inc.
        <br />
        <FormattedMessage
          id="footer.all_rights_reserved"
          defaultMessage="All Rights Reserved."
        />
      </p>
      <Anchor
        href="https://www.getcurbspot.com/policies/terms-of-service"
        className="p-link term-text"
        target="_blank"
      >
        <FormattedMessage
          id="footer.terms_and_conditions"
          defaultMessage="Terms & Conditions"
        />
      </Anchor>
      <span className="p-link term-text link-divisor">/</span>
      <Anchor
        href="https://www.getcurbspot.com/policies/privacy-policy"
        className="p-link term-text"
        target="_blank"
      >
        <FormattedMessage
          id="footer.privacy_policy"
          defaultMessage="Privacy Policy"
        />
      </Anchor>
    </div>
  );
};
export default CPFooter;
