export function timeSince(date) {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);

  let interval = Math.floor(seconds / 31536000);
  const color = seconds > 600 ? 'danger' : seconds > 300 ? 'warning' : 'success'
  if (interval >= 1) {
    return {interval, unit: ' years', color};
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return {interval, unit: ' months', color};
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return {interval, unit: ' days', color};
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return {interval, unit: ' hours', color};
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return {interval, unit: ' minutes', color};
  }
  return {interval: Math.floor(seconds), unit: ' seconds', color};
}

export function minutesFromMidnight() {
  const date = new Date();
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const result = 60 * hours + minutes;
  return result;
}
export function unixToLocalTime(unixTime){
  const dateObj = new Date(unixTime);
  const hours = dateObj.getHours();
  return `${hours > 12 ? hours - 12 : hours}:${dateObj.getMinutes()} ${hours > 12 ? 'PM':'AM'}`
}
