/**
 * This is a reducer that will provide default state update
 *
 * @param {Object} state
 * @param {Object} action
 */
export const defaultReducer = (state: any = {}, action: any = {}) => ({
  ...state,
  ...action.payload,
});

/**
 * This is a reducer that will be used when the repository reducers
 * don't have a configured method to manage the action type
 *
 * @param {Object} state
 */
export const unknownReducer = (state: any) => state;
