import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  IonRow,
  IonCol,
  IonText,
  IonLabel,
  IonImg,
  IonButton,
  IonIcon,
  IonModal,
  IonContent,
} from '@ionic/react';
import { getClient } from 'selectors/client';
import './CPClientAdminProfile.scss';
import ClientUpdateForm from './subcomponents/ClientUpdateForm';
const CPClientAdminProfile: React.FC = () => {
  const [isUpdateClient, setIsUpdateClient] = useState<boolean>(false);
  const client = useSelector(getClient);

  return (
    <div className="client-admin-profile-wrapper">
      <IonModal
        cssClass="update-client-modal-container"
        isOpen={isUpdateClient}
        onDidDismiss={() => setIsUpdateClient(false)}
        backdropDismiss={true}
        keyboardClose={false}
      >
        <IonContent>
          <div className="update-client-modal">
            <IonIcon
              src="/assets/icon/close-outline.svg"
              onClick={() => setIsUpdateClient(false)}
              className="modal-close-icon"
              name="close"
              icon="close"
            ></IonIcon>
            <ClientUpdateForm
              client={client}
              closeModal={() => setIsUpdateClient(false)}
            />
          </div>
        </IonContent>
      </IonModal>
      <IonRow className="client-admin-profile">
        <IonCol sizeXs="12" sizeLg="12">
          <div className="d-flex ion-justify-content-between">
            <IonLabel className="description-label">Company name</IonLabel>
            {!isUpdateClient && (
              <IonButton
                color="dark"
                mode="md"
                onClick={(event: any) => {
                  setIsUpdateClient(true);
                }}
              >
                Edit
              </IonButton>
            )}
          </div>
          <IonText className="description-text">{client.companyName}</IonText>
        </IonCol>
        <IonCol sizeXs="12" sizeLg="12">
          <IonLabel className="description-label">Admin Password</IonLabel>
          <IonText className="description-text">*********</IonText>
        </IonCol>
        <IonCol sizeXs="12" sizeLg="12">
          <IonLabel className="description-label">Company Logo</IonLabel>
          <IonImg src={client.logo} className="client-logo w-25" />
        </IonCol>
      </IonRow>
    </div>
  );
};
export default CPClientAdminProfile;
