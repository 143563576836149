import React from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import './PhoneAreaCodeDropdown.scss';
interface ContainerProps {
  selectedCountry: string;
  setSelectedCountry: Function;
  supportedCountries: any;
  showCountryOnly?: boolean;
}

const PhoneAreaCodeDropdown: React.FC<ContainerProps> = ({
  selectedCountry,
  setSelectedCountry,
  supportedCountries,
  showCountryOnly,
}) => {
  return (
    <IonSelect
      className={
        showCountryOnly
          ? 'phone-countries-dropdown'
          : 'phone-countries-dropdown ion-margin-end'
      }
      value={selectedCountry}
      onIonChange={(e: any) => {
        setSelectedCountry(e.detail.value);
      }}
    >
      {supportedCountries.map((country: any) => (
        <IonSelectOption value={country.countryCode} key={country.countryCode}>
          {showCountryOnly
            ? country.name
            : `${country.countryCode} (+${country.phoneAreaCode})`}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};
export default PhoneAreaCodeDropdown;
