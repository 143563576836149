import React, { useContext, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IonButton } from '@ionic/react';

import './OrderDetailsReadyForPickup.scss';
import OrderDetailsFormWrapper from '../OrderDetailsFormWrapper';
import { StoreContext } from 'contexts';
import { CREATE_CUSTOMER_EVENT, NOTIFY } from 'actions/actionTypes';
import { EVENT_CUSTOMER_READY } from 'constants/orderStates';
import Loader from 'components/DesignSystem/Loader';

const OrderDetailsReadyForPickup: React.FC = () => {
  const { action } = useContext(StoreContext);
  const [location, setLocation] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();

  const sendError = useCallback(
    (message: string) => {
      action(NOTIFY, {
        color: 'danger',
        message,
      });
    },
    [action]
  );

  const sendErrorTranslation = intl.formatMessage({
    id: 'order.s1.pickup_form.send_error_message',
    defaultMessage: 'Please provide details about your location to help us to find you.'
  });

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (location) {
        setLoading(true);
        action(CREATE_CUSTOMER_EVENT, {
          type: EVENT_CUSTOMER_READY,
          locationDescription: location,
        }).then((res: any) => {
          if (res && res.status !== 200) {
            setLoading(false);
            sendError(sendErrorTranslation);
          }
        });
      } else {
        sendError(sendErrorTranslation);
      }
    },
    [location, action]
  );

  return (
    <>
      <div className="location-form-wrapper">
        <OrderDetailsFormWrapper>
          <form onSubmit={(e) => onSubmit(e)} className="customer-order-form">
            <label htmlFor="location" className="customer-order-form-label">
              <FormattedMessage
                id="order.s1.pickup_form.label"
                defaultMessage="Location Details"
              />
            </label>
            <textarea
              className="customer-order-form-textarea"
              rows={4}
              value={location}
              placeholder={
                intl.formatMessage({
                  id: 'order.s1.pickup_form.placeholder',
                  defaultMessage: 'Tell us about your location, vehicle make and model, or any other helpful details.'
                })
              }
              name="location"
              onChange={(e) => setLocation(e.currentTarget.value)}
            ></textarea>
            <IonButton
              className="customer-order-form-submit"
              color="dark"
              disabled={loading}
              shape={undefined}
              type="submit"
            >
              {loading
                ? <Loader withMinHeight={false} />
                : intl.formatMessage({
                  id: 'order.s1.pickup_form.button',
                  defaultMessage: `I'm here`
                })}
            </IonButton>
          </form>
        </OrderDetailsFormWrapper>
      </div>
    </>
  );
};

export default OrderDetailsReadyForPickup;
