import React, { useContext } from 'react';

import { useSelector } from 'react-redux';
import { getLogo, getName } from 'selectors/client';

import { IonThumbnail, IonImg, IonTab } from '@ionic/react';

import { getToken } from 'selectors/session';
import CPLogoutCTA from 'components/CPLogoutCTA';
import './CPClientLogo.scss';
import CPHeaderTabs from '../CPHeaderTabs';
import { ViewPortContext } from '../../contexts';
import { getViewPort } from '../../utils/getViewPort';

const CPClientLogo: React.FC<any> = ({ suppressLogout = false }) => {
  const logo = useSelector(getLogo);
  const name = useSelector(getName);
  const token = useSelector(getToken);
  const { width } = useContext(ViewPortContext);
  const { isMobile } = getViewPort(width);

  return (
    <>
      <div className="logo-wrapper d-flex">
        <IonThumbnail className="logo-thumbnail">
          <IonImg src={logo} alt={name} className="client-logo" />
        </IonThumbnail>
        {!isMobile ? (
          !suppressLogout &&
          token && (
            <div className="d-flex ion-justify-content-end tab-and-logout-wrapper">
              <CPHeaderTabs />
              <CPLogoutCTA />
            </div>
          )
        ) : (
          <CPLogoutCTA />
        )}
      </div>
      {isMobile && <CPHeaderTabs />}
    </>
  );
};
export default CPClientLogo;
