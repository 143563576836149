import React from 'react';
import CPFooter from 'components/CPFooter';
import CPUnifiedLogin from 'components/CPUnifiedLogin';
import CPClientLogo from 'components/CPClientLogo';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import './UnifiedLogin.scss';

const UnifiedLogin: React.FC = () => {
  return (
    <IonPage className="login-page">
      <IonContent>
        <IonGrid>
          <IonRow className="oe-container">
            <IonCol size="12">
              <CPClientLogo suppressLogout={true} />
              <CPUnifiedLogin />
              <CPFooter />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default UnifiedLogin;
