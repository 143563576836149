import React from 'react';
import { withSize } from 'react-sizeme';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import {ViewPortContext } from 'contexts'
import CPClientAdminBoard from 'components/CPClientAdminBoard';
import CPFooter from 'components/CPFooter';

import './ClientAdminHome.scss';
import CPClientLogo from 'components/CPClientLogo';

interface ClientAdminHomeprops {
  size:object
}
const ClientAdminHome: React.FC<ClientAdminHomeprops> = ({size}) => {
  return (
    <ViewPortContext.Provider value={size}>
      <IonPage className="client-admin-page">
        <IonContent>
          <IonGrid>
            <IonRow className="oe-container">
              <IonCol size="12">
                <CPClientLogo />
                <CPClientAdminBoard />
                <CPFooter />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </ViewPortContext.Provider>
  );
};

export default withSize()(ClientAdminHome);
