import en from  './en';
import fr from  './fr';

type Languages = 'en' | 'fr';

const translations = {
  en,
  fr,
};

const getTranslations = (language: Languages) => {
  return translations[language] || translations['en'];
}

export default getTranslations;
