import React, { useState, useContext, useCallback, useMemo } from 'react';
import './OrderCard.scss';
import { arrowForward, mapOutline, pencil, mailOutline, callOutline } from 'ionicons/icons';
import {
  IonCard,
  IonButton,
  IonModal,
  IonContent,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonIcon,
  IonLabel,
  IonBadge,
  IonAlert,
  IonText,
} from '@ionic/react';
import OrderDetails from '../OrderDetails';
import { timeSince } from 'utils/timeUtils';
import { StoreContext } from 'contexts';
import { UPDATE_ORDER, FETCH_ORDER_DETAILS } from 'actions/actionTypes';
import {
  ORDER_STATE_DELIVERED,
  ORDER_STATE_CUSTOMER_READY,
  EVENT_OUT_FOR_CURBSIDE,
  EVENT_CANCELLED,
  EVENT_DELIVERED,
  ORDER_STATE_UNDER_PICKUP,
  ORDER_STATE_PENDING_PICKUP,
  EVENT_ORDER_READY,
  EVENT_CUSTOMER_NOT_FOUND,
} from 'constants/orderStates';
import { useSelector } from 'react-redux';
import NotificationDetails from './subcomponent/NotificationDetails';
import { getRole, getCurrentStore, getStoreDefaultLanguage } from 'selectors/session';
import { DISPATCHER_ROLE, BOPIS_DELIVERY } from 'constants/defaults';
import UnifiedOrderForm from 'components/UnifiedOrderForm';
import { useIntl } from 'react-intl';

interface ContainerProps {
  order: any;
}

const OrderCard: React.FC<ContainerProps> = ({ order }) => {
  const storeDefaultLanguage = useSelector(getStoreDefaultLanguage);
  const { action, notify } = useContext(StoreContext);
  const { deliveryMethods = [] } = useSelector(getCurrentStore);
  const role = useSelector(getRole);
  const [currentOrder, setCurrentOrder] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [updateOrderModal, setUpdateOrderModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const {
    id,
    externalId,
    customerInfo: { customerEmail, customerPhone },
    lastEventDate,
    state: { stateName },
    notifications,
    orderLanguage,
    binLocation,
  } = order;
  const intl = useIntl();
  const reverseNotifications = notifications.reverse();

  const lastSMSNotification = useMemo(() => {
    return reverseNotifications.find(
      (item: any) => item.address === customerPhone
    );
  }, [reverseNotifications]);

  const lastEmailNotification = useMemo(() => {
    return reverseNotifications.find(
      (item: any) => item.address === customerEmail
    );
  }, [reverseNotifications]);

  const lastBinLocationNotification = useMemo(() => {
    return reverseNotifications.find(
      (item: any) => item.binLocation === binLocation
    );
  }, [reverseNotifications]);

  // Check if the store permits BOPIS delivery
  const hasBopisEnabled = deliveryMethods.some(
    (method: any) => method.deliveryMethod.id === BOPIS_DELIVERY
  );

  const openConfirmationAlert = () => {
    setShowAlert(true);
  };

  const confirmOrderReady = () => {
    action(UPDATE_ORDER, {
      type: EVENT_ORDER_READY,
      orderId: id,
    })
      .then((response: any) => {
        if (response && response.status === 200) {
          notify({
            message: intl.formatMessage({ id: 'notifications.orderReady', defaultMessage: 'The order is Ready for Pickup.' }),
            color: 'success',
          });
        } else {
          notify({
            message: intl.formatMessage({ id: 'notifications.unableToMoveOrderToReady', defaultMessage: 'Unable to move the order to Ready for Pickup.' }),
            color: 'danger',
          });
        }
      })
      .catch((e: any) => {
        notify({
          message: intl.formatMessage({ id: 'notifications.unableToMoveOrderToReady', defaultMessage: 'Unable to move the order to Ready for Pickup.' }),
          color: 'danger',
        });
      });
  };

  const confirmOufForCurbside = () => {
    action(UPDATE_ORDER, {
      type: EVENT_OUT_FOR_CURBSIDE,
      orderId: id,
    })
      .then((response: any) => {
        if (response && response.status === 200) {
          notify({
            message: intl.formatMessage({ id: 'notifications.readyForCurbside', defaultMessage: 'The order is Out to Curbside.' }),
            color: 'success',
          });
        } else {
          notify({
            message: intl.formatMessage({ id: 'notifications.unableToMoveReadyForCurbside', defaultMessage: 'Unable to move the order to Out to Curbside.' }),
            color: 'danger',
          });
        }
      })
      .catch((e: any) => {
        notify({
          message: intl.formatMessage({ id: 'notifications.unableToMoveReadyForCurbside', defaultMessage: 'Unable to move the order to Out to Curbside.' }),
          color: 'danger',
        });
      });
  };
  const confirmDeleteOrder = () => {
    action(UPDATE_ORDER, {
      type: EVENT_CANCELLED,
      orderId: id,
    })
      .then((response: any) => {
        if (response && response.status === 200) {
          notify({
            message: intl.formatMessage({ id: 'notifications.orderRemoved', defaultMessage: 'The order is removed.' }),
            color: 'success',
          });
        } else {
          notify({
            message: intl.formatMessage({ id: 'notifications.unableToRemove', defaultMessage: 'Unable to remove the order.' }),
            color: 'danger',
          });
        }
      })
      .catch((e: any) => {
        notify({
          message: intl.formatMessage({ id: 'notifications.unableToRemove', defaultMessage: 'Unable to remove the order.' }),
          color: 'danger',
        });
      });
  };

  const updateOrder = (type: string) => {
    action(UPDATE_ORDER, {
      type,
      orderId: id,
    })
      .then((data: any) => {
        notify({
          message: intl.formatMessage({ id: 'notifications.updated', defaultMessage: 'Updated successfully!' }),
          color: 'success',
        });
      })
      .catch((e: any) => {
        notify({
          message: intl.formatMessage({ id: 'notifications.failed', defaultMessage: 'Failed to update!' }),
          color: 'danger',
        });
      });
  };

  const loadOrderDetails = useCallback((orderId: string) => {
    action(FETCH_ORDER_DETAILS, { orderId }).then((res: any) => {
      if (res && res.status === 200) {
        // Sort the order events based on occurrence date
        const order = res.data;

        order.events = (order.events || []).sort((evt1: any, evt2: any) => {
          return evt1.createdOn > evt2.createdOn ? 1 : -1;
        });

        setCurrentOrder(order);
        setShowDetailsModal(true);
        return;
      }
    });
  }, []);

  const isUpdatable = useCallback((orderId: string) => {
    action(FETCH_ORDER_DETAILS, { orderId }).then((res: any) => {
      if (res && res.status === 200) {
        const alreadyOrderReadyEvent = res.data.events.find(
          (event: any) => event.type === EVENT_CUSTOMER_NOT_FOUND
        );
        if (alreadyOrderReadyEvent) {
          notify({
            message: intl.formatMessage({ id: 'notifications.cannotUpdate', defaultMessage: 'Order cannot update.' }),
            color: 'warning',
          });
          return;
        }
        setUpdateOrderModal(true);
        return;
      }
    });
  }, []);

  const time = timeSince(lastEventDate);

  return (
    <>
      {showDetailsModal && (
        <IonModal
          cssClass="order-detail-modal-container"
          isOpen={showDetailsModal}
          onDidDismiss={() => setShowDetailsModal(false)}
          backdropDismiss={true}
          keyboardClose={false}
        >
          <IonContent>
            <div className="order-details-modal">
              <IonIcon
                src="/assets/icon/close-outline.svg"
                onClick={() => setShowDetailsModal(false)}
                className="modal-close-icon"
              ></IonIcon>
              {currentOrder && (
                <OrderDetails
                  currentOrder={currentOrder}
                  role={role}
                  closeModal={() => setShowDetailsModal(false)}
                ></OrderDetails>
              )}
            </div>
          </IonContent>
        </IonModal>
      )}
      <IonModal
        cssClass="order-detail-modal-container edit-modal"
        isOpen={updateOrderModal}
        onDidDismiss={() => setUpdateOrderModal(false)}
        backdropDismiss={true}
        keyboardClose={false}
      >
        <IonContent>
          <div className="order-details-modal">
            <IonIcon
              src="/assets/icon/close-outline.svg"
              onClick={() => setUpdateOrderModal(false)}
              className="modal-close-icon"
            ></IonIcon>
            <UnifiedOrderForm
              isOrderUpdateForm={true}
              order={order}
              closeModal={() => setUpdateOrderModal(false)}
            />
          </div>
        </IonContent>
      </IonModal>
      <IonCard className="order-card" id={`card-${order.id}`}>
        <IonCardHeader color="light">
          <div className="d-flex ion-justify-content-between">
            <IonCardSubtitle>{intl.formatMessage({
              id: 'orderCard.orderNumber',
              defaultMessage: 'Order Number'
            })}</IonCardSubtitle>
            <div>
              {(stateName === ORDER_STATE_UNDER_PICKUP ||
                stateName === ORDER_STATE_PENDING_PICKUP) &&
                role === DISPATCHER_ROLE && (
                  <IonIcon
                    icon={pencil}
                    className="edit-order-icon"
                    onClick={() => isUpdatable(id)}
                  ></IonIcon>
                )}
              {stateName !== ORDER_STATE_DELIVERED && (
                <IonIcon
                  src="/assets/icon/details.svg"
                  onClick={() => loadOrderDetails(id)}
                  className="details-icon"
                ></IonIcon>
              )}
              {stateName !== ORDER_STATE_DELIVERED &&
                role === DISPATCHER_ROLE && (
                  <IonIcon
                    src="/assets/icon/delete.svg"
                    onClick={() => setShowConfirmDelete(true)}
                    className="delete-icon"
                  ></IonIcon>
                )}
              <IonAlert
                isOpen={showConfirmDelete}
                onDidDismiss={() => setShowConfirmDelete(false)}
                header={intl.formatMessage({ id: 'orderCard.confirmDelete', defaultMessage: 'Confirm Delete!' })}
                message={intl.formatMessage({ id: 'orderCard.confirmEnsure', defaultMessage: 'Are you sure?' })}
                buttons={[
                  {
                    text: intl.formatMessage({ id: 'notifications.cancel', defaultMessage: 'Cancel' }),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                      setShowConfirmDelete(false);
                    },
                  },
                  {
                    text: intl.formatMessage({ id: 'notifications.ok', defaultMessage: 'Ok' }),
                    handler: () => {
                      confirmDeleteOrder();
                    },
                  },
                ]}
              />
            </div>
          </div>
          <IonCardTitle className="order-number">{externalId}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent className="cp-card-content">
          <IonLabel>
            {stateName === ORDER_STATE_CUSTOMER_READY ? (
              <label className="status-label">{intl.formatMessage({
                id: 'orderCard.wait',
                defaultMessage: intl.formatMessage({ id: 'notifications.wait', defaultMessage: 'Wait' })
              })}: </label>
            ) : (
              <label className="status-label">{intl.formatMessage({
                id: 'orderCard.added',
                defaultMessage: intl.formatMessage({ id: 'notifications.added', defaultMessage: 'Added' })
              })}: </label>
            )}
            {stateName === ORDER_STATE_CUSTOMER_READY ? (
              <IonBadge color={time.color}>
                {time.interval + time.unit}
              </IonBadge>
            ) : (
              <label className="status-value">
                {time.interval + time.unit}
              </label>
            )}
          </IonLabel>
          <IonLabel>
            <label>{intl.formatMessage({
              id: 'orderCard.orderLanguage',
              defaultMessage: intl.formatMessage({ id: 'notifications.orderLanguage', defaultMessage: 'Order Language' })
            })}: </label>
            <IonText className="order-language-text">{orderLanguage || storeDefaultLanguage}</IonText>
          </IonLabel>
          <IonLabel className="notification-details">
            <IonText className="notification-information">
              <IonIcon icon={mailOutline} className="notification-logo" />
              {customerEmail}
            </IonText>
            {lastEmailNotification && (
              <NotificationDetails
                notificationType="Email"
                stateName={stateName}
                lastNotification={lastEmailNotification}
              />
            )}
          </IonLabel>
          <IonLabel className="notification-details">
            <IonText className="notification-information">
              <IonIcon icon={callOutline} className="notification-logo" />
              {customerPhone}
            </IonText>
            {lastSMSNotification && (
              <NotificationDetails
                notificationType="SMS"
                stateName={stateName}
                lastNotification={lastSMSNotification}
              />
            )}
          </IonLabel>
          {binLocation ?
            <IonLabel className="notification-details">
              <IonText className="notification-information">
                <IonIcon icon={mapOutline} className="notification-logo" />
                {binLocation}
              </IonText>
              {lastBinLocationNotification && (
                <NotificationDetails
                  notificationType="BIN Location"
                  stateName={stateName}
                  lastNotification={lastBinLocationNotification}
                />
              )}
            </IonLabel>
            : null}
          {stateName === ORDER_STATE_UNDER_PICKUP && role === DISPATCHER_ROLE && (
            <>
              <IonButton
                color="dark"
                size="large"
                mode="md"
                className="out-for-curbside-button"
                onClick={openConfirmationAlert}
              >
                {intl.formatMessage({
                  id: 'orderCard.readyForPickup',
                  defaultMessage: intl.formatMessage({ id: 'notifications.readyForPickupI', defaultMessage: 'Ready for pickup' })
                })}
                <IonIcon slot="end" icon={arrowForward} />
              </IonButton>
              <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={intl.formatMessage({ id: 'orderCard.confirmReadyForPickup', defaultMessage: 'Confirm Ready For Pickup!' })}
                message={intl.formatMessage({ id: 'orderCard.confirmEnsure', defaultMessage: 'Are you sure?' })}
                buttons={[
                  {
                    text: intl.formatMessage({ id: 'orderCard.cancel', defaultMessage: 'Cancel' }),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {},
                  },
                  {
                    text: intl.formatMessage({ id: 'orderCard.ok', defaultMessage: 'OK' }),
                    handler: () => {
                      confirmOrderReady();
                    },
                  },
                ]}
              />
            </>
          )}
          {stateName === ORDER_STATE_CUSTOMER_READY && (
            <>
              {role === DISPATCHER_ROLE && (
                <IonButton
                  color="dark"
                  size="large"
                  mode="md"
                  className="out-for-curbside-button"
                  onClick={openConfirmationAlert}
                >
                  {intl.formatMessage({
                    id: 'orderCard.outForCurbside',
                    defaultMessage: 'Out for curbside'
                  })}
                  <IonIcon slot="end" icon={arrowForward} />
                </IonButton>
              )}
              <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={intl.formatMessage({ id: 'notifications.confirmOutForCurbside', defaultMessage: 'Confirm Out For Curbside!' })}
                message={intl.formatMessage({ id: 'notifications.areYousure', defaultMessage: 'Are you sure?' })}
                buttons={[
                  {
                    text: intl.formatMessage({ id: 'notifications.cancel', defaultMessage: 'Cancel' }),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {},
                  },
                  {
                    text: intl.formatMessage({ id: 'notifications.ok', defaultMessage: 'Ok' }),
                    handler: () => {
                      confirmOufForCurbside();
                    },
                  },
                ]}
              />
            </>
          )}
          {stateName === ORDER_STATE_PENDING_PICKUP && (
            <>
              {hasBopisEnabled && role === DISPATCHER_ROLE && (
                <IonButton
                  color="light"
                  size="large"
                  mode="md"
                  className="out-for-curbside-button button-secondary"
                  onClick={openConfirmationAlert}
                >{intl.formatMessage({
                  id: 'orderCard.orderDelivered',
                  defaultMessage: 'Order delivered'
                })}<IonIcon slot="end" icon={arrowForward} />
                </IonButton>
              )}
              <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={'Confirm Delivered!'}
                message={intl.formatMessage({ id: 'notifications.areYousure', defaultMessage: 'Are you sure?' })}
                buttons={[
                  {
                    text: intl.formatMessage({ id: 'notifications.cancel', defaultMessage: 'Cancel' }),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {},
                  },
                  {
                    text: intl.formatMessage({ id: 'notifications.ok', defaultMessage: 'Ok' }),
                    handler: () => {
                      updateOrder(EVENT_DELIVERED);
                    },
                  },
                ]}
              />
            </>
          )}
        </IonCardContent>
      </IonCard>
    </>
  );
};
export default OrderCard;
