import { request } from 'utils/restClient';
import {
  CUSTOMER_GET_ORDER_DETAILS,
  CUSTOMER_CREATE_EVENT,
  DISPATCHER_GET_ORDERS,
  DISPATCHER_CREATE_EVENT,
  DISPATCHER_CREATE_ORDER,
  DISPATCHER_GET_ORDER_DETAILS,
  DISPATCHER_UPDATE_ORDER_DETAILS,
} from 'constants/endpoints';

export const fetchOrders = (data) => {
  const { clientId, storeId, ...restData } = data;

  return request(DISPATCHER_GET_ORDERS, {
    pathParams: { clientId, storeId },
    queryParams: { ...restData },
  });
};

export const fetchOrderDetails = ({ clientId, storeId, orderId }) => {
  return request(DISPATCHER_GET_ORDER_DETAILS, {
    pathParams: { clientId, storeId, orderId },
  });
};

export const addOrder = (data) => {
  const { clientId, storeId, ...body } = data;

  return request(DISPATCHER_CREATE_ORDER, {
    pathParams: { clientId, storeId },
    body,
  });
};

export const updateOrder = (data) => {
  const { clientId, storeId, orderId, ...body } = data;

  return request(DISPATCHER_CREATE_EVENT, {
    pathParams: { clientId, storeId, orderId },
    body,
  });
};

export const getCustomerOrder = ({ clientId, storeId, orderId, token }) => {
  return request(CUSTOMER_GET_ORDER_DETAILS, {
    pathParams: { clientId, storeId, orderId },
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const sendCustomerEvent = ({
  clientId,
  storeId,
  orderId,
  token,
  body,
}) => {
  return request(CUSTOMER_CREATE_EVENT, {
    pathParams: { clientId, storeId, orderId },
    headers: {
      Authorization: `${token}`,
    },
    body,
  });
};

export const updateOrderDetails = ({ clientId, storeId, orderId, body }) => {
  return request(DISPATCHER_UPDATE_ORDER_DETAILS, {
    pathParams: { clientId, storeId, orderId },
    body,
  });
};
