import {
  FETCH_CLIENT_RECEIVED,
  SET_SUPPORTED_COUNTRIES,
  WIPE_SESSION,
} from 'actions/actionTypes';
import { defaultReducer, unknownReducer } from './utils';
import type { Action } from 'redux';
import { DEFAULT_LOGO_URL, DEFAULT_NAME } from 'constants/defaults';

const ClientInitiaState = {
  id: '',
  name: DEFAULT_NAME,
  url: '',
  logo: DEFAULT_LOGO_URL,
  clientLoaded: false,
  clientStores: [],
  supportedCountries: [],
  clientAllStoresIds: [],
};

const reducerMap: any = {
  [SET_SUPPORTED_COUNTRIES]: defaultReducer,
  [FETCH_CLIENT_RECEIVED]: defaultReducer,
  [WIPE_SESSION]: (state: any) => {
    return {
      ...state, // keeps current state
      supportedCountries: [], // Reset supported countries only
    };
  },
};

export default (state = ClientInitiaState, action: Action) => {
  const reducer = reducerMap[action.type] || unknownReducer;

  return reducer(state, action);
};
