interface KeyValueStringPair {
  [key: string]: string;
}

export function normalizePath(url: string, params: KeyValueStringPair = {}) {
  return Object.keys(params).reduce((endpoint, param) => {
    const regex = new RegExp(`{${param}}`, 'g');

    return endpoint.replace(regex, params[param]);
  }, url);
}

export function normalizeQuery(query: KeyValueStringPair, encode = false) {
  return Object.keys(query).reduce((parsedQuery: KeyValueStringPair, key) => {
    parsedQuery[key] = encode ? encodeURIComponent(query[key]) : query[key];

    return parsedQuery;
  }, {});
}
