import React, { useContext } from 'react';
import './CPHeaderTabs.scss';
import {
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { getHistory } from 'selectors/navigation';
import { ViewPortContext } from 'contexts';
import { getViewPort } from 'utils/getViewPort';
interface ICPHeaderProps {}
const CPHeaderTabs: React.FC<ICPHeaderProps> = () => {
  const history = useSelector(getHistory);
  const { width } = useContext(ViewPortContext);
  const { isMobile } = getViewPort(width);

  return (
    <div className="tabs-wrapper">
      {!isMobile ? (
        <IonTabs>
          <IonRouterOutlet></IonRouterOutlet>
          <IonTabBar slot="top">
            <IonTabButton className="tab-button" tab="admin" href="/admin/home">
              <IonLabel className="tab-names">Batch Import</IonLabel>
            </IonTabButton>
            <IonTabButton
              className="tab-button"
              tab="stores"
              href="/admin/stores"
            >
              <IonLabel className="tab-names">Store Setup</IonLabel>
            </IonTabButton>
            <IonTabButton
              className="tab-button"
              tab="account"
              href="/admin/account"
            >
              <IonLabel className="tab-names">Account</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      ) : (
        <>
          <h3 className="navigation-title">Navigation</h3>
          <IonSelect
            className="dropdown-mobile"
            id="page"
            name="page"
            value={history.location.pathname}
            interface="popover"
            interfaceOptions={{
              lines: 'none',
              cssClass: 'dropdown-mobile-content',
            }}
            onIonChange={(e) => {
              // For some reason, onIonChange was getting called twice,
              // pushing two page changes in sequence and causing
              // mobile animation to happen twice.
              // Just adding a validation to avoid duplicated pushes
              if (e.detail.value !== history.location.pathname) {
                history.push(e.detail.value);
              }
            }}
          >
            <IonSelectOption value="/admin/home">Batch Import</IonSelectOption>
            <IonSelectOption value="/admin/stores">Store Setup</IonSelectOption>
            <IonSelectOption value="/admin/account">Account</IonSelectOption>
          </IonSelect>
        </>
      )}
    </div>
  );
};

export default CPHeaderTabs;
