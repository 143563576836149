import { SET_HISTORY } from 'actions/actionTypes';
import { defaultReducer, unknownReducer } from './utils';

import type { Action } from 'redux';

const reducerMap: any = {
  [SET_HISTORY]: defaultReducer,
};

export default (state = {}, action: Action) => {
  const reducer = reducerMap[action.type] || unknownReducer;

  return reducer(state, action);
};
